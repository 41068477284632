import { useEffect, useMemo, useState } from "react";
import {
  getRecordFromFireStore,
  getRecordsFromFireStore,
} from "src/firebaseAuth";
import { useOrganizationState } from "src/state/OrganizationState";
import { COLLECTION_DATA } from "src/utils/enums";
import dayjs from "dayjs";
import {
  descOrderTranscripts,
  notDeletedConversations,
} from "src/utils/variables";
import { transcriptConverter } from "src/utils/converter";
import { getUsersEmailDictionary } from "src/utils/utils";
import { Card, Col, Row, Spin, Typography, Button } from "antd";
import {
  EvaluationSummaryCard,
  TopMetricsComponent,
  WeeklyOverViewGraphs,
  getWeeklyGraphData,
} from "../BusinessInsights/BusinessInsights.utils";
import { elementSize } from "src/styles/variables";
import { InvitedUser, UserType } from "src/utils/types";
import { useNavigate } from "react-router-dom";
import { useModal } from "src/state/UseModal";
import { useAgentDateSelection } from "src/state/UseAgentDateSelection";
import { ConversationTable } from "../Dashboard/ConversationTable";

export const UserModalContent = () => {
  const {
    organization,
    evaluationForms,
    collaborators,
    pendingCollaborators,
    organizationSettings,
  } = useOrganizationState();
  const navigate = useNavigate();
  const { modalState } = useModal();
  const [selectedUser, setSelectedUser] = useState(null);

  const showRisk = organizationSettings?.showRisk;
  const [userInsights, setUserInsights] = useState(
    null as { allInsightsData: any; selectedDateInsights: any },
  );

  const [loading, setLoading] = useState(false);
  const scoreType = showRisk ? "Risk" : "Score";
  const { date: agentDateFilter } = useAgentDateSelection();

  const diffWeeks = dayjs()
    .startOf("week")
    .startOf("day")
    .diff(agentDateFilter, "week");
  const [userTranscript, setUserTranscripts] = useState(null);

  const [transcriptState, setTranscriptState] = useState({
    lastDoc: null,
    hasMore: true,
  });

  const datesArray = Array.from({ length: 5 }, (_, i) =>
    agentDateFilter
      .add(diffWeeks >= 2 ? i - 2 : diffWeeks === 1 ? i - 3 : i - 4, "week")
      .format("YYYY-MM-DD"),
  );

  const allUsers = useMemo(
    () => [...(collaborators ?? []), ...(pendingCollaborators ?? [])],
    [collaborators, pendingCollaborators],
  );

  const getUserInfo = async () => {
    const user = allUsers.find((data: InvitedUser | UserType) => {
      const agentId = data.email;
      return agentId === modalState.id;
    });

    setSelectedUser(user);
  };

  useEffect(() => {
    if (modalState.id) {
      getUserInfo();
    }
  }, [modalState.id, allUsers]);

  useEffect(() => {
    if (selectedUser && organization && !userInsights) {
      const fetchData = async () => {
        try {
          const agentQueryConditions = {
            comparisonField: "metadata.agent",
            comparisonOperator: "==",
            value: selectedUser?.email,
          };
          const insightsPromises = datesArray.map((date) =>
            getRecordFromFireStore(
              `organization/${organization}/${COLLECTION_DATA.INSIGHTS}/${date}/userInsights/${selectedUser?.email}`,
            ),
          );
          const transcriptsPromise = await getRecordsFromFireStore(
            `organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}`,
            [
              notDeletedConversations,
              agentQueryConditions,
              descOrderTranscripts,
            ],
            transcriptConverter(
              evaluationForms,
              getUsersEmailDictionary(collaborators, pendingCollaborators),
              selectedUser,
            ),
            undefined,
            5,
          );

          const allResults = await Promise.all([
            ...insightsPromises,
            transcriptsPromise,
          ]);
          const insightsResults = allResults.slice(0, datesArray?.length);
          const transcriptsResult = allResults[datesArray.length]?.data;
          let specificInsight = null;
          const insights = insightsResults?.map((doc, index) => {
            const currentDate = datesArray[index];
            if (currentDate === agentDateFilter.format("YYYY-MM-DD")) {
              specificInsight = doc && { ...doc, id: currentDate };
            }
            return { ...doc, id: currentDate };
          });

          setUserInsights({
            allInsightsData: insights,
            selectedDateInsights: specificInsight,
          });
          setUserTranscripts(transcriptsResult);
          setTranscriptState((prevState) => ({
            ...prevState,
            hasMore: transcriptsResult.length >= 5,
            lastDoc: allResults[datesArray.length].lastDoc,
          }));
        } catch (error) {
          console.error("Error fetching documents: ", error);
        }
      };

      fetchData();
    }
  }, [selectedUser, organization]);

  const loadMore = async () => {
    if (!transcriptState.lastDoc) return;
    setLoading(true);
    try {
      const agentQueryConditions = {
        comparisonField: "metadata.agent",
        comparisonOperator: "==",
        value: selectedUser?.email,
      };
      const moreTranscriptsResult = await getRecordsFromFireStore(
        `organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}`,
        [notDeletedConversations, agentQueryConditions, descOrderTranscripts],
        transcriptConverter(
          evaluationForms,
          getUsersEmailDictionary(collaborators, pendingCollaborators),
          selectedUser,
        ),
        undefined,
        5,
        transcriptState.lastDoc, // Use lastDoc as the starting point for the next set of documents
      );
      const moreTranscripts = moreTranscriptsResult.data;
      setUserTranscripts((prev) => [...prev, ...moreTranscripts]);
      setTranscriptState((prevState) => ({
        ...prevState,
        hasMore: moreTranscripts.length === 5, // Only set hasMore to false if less than 5 transcripts are returned
        lastDoc: moreTranscriptsResult.lastDoc,
      }));
      setTimeout(() => {
        const lastAddedTranscript = document.getElementById(
          `transcript-${moreTranscripts[moreTranscripts.length - 1]?.id}`,
        );
        if (lastAddedTranscript) {
          lastAddedTranscript.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    } catch (error) {
      console.error("Error fetching more transcripts: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTranscriptClick = (transcript) => {
    navigate(`?conversation=${encodeURIComponent(transcript.id)}`);
  };

  const { overallScoreData } = getWeeklyGraphData(userInsights, showRisk);

  return (
    <>
      {selectedUser && (
        <Spin spinning={!(userTranscript && userInsights)}>
          <Row gutter={[16, 32]} style={{ paddingTop: elementSize.md }}>
            {userInsights?.selectedDateInsights ? (
              userInsights?.selectedDateInsights?.totalTranscripts > 0 ? (
                <>
                  <TopMetricsComponent
                    insights={userInsights?.selectedDateInsights}
                    showRisk={showRisk}
                  />

                  <EvaluationSummaryCard
                    currentInsightData={userInsights?.selectedDateInsights}
                    showRisk={showRisk}
                    spanWidth={12}
                    height={500}
                  />
                  <WeeklyOverViewGraphs
                    overallScoreData={overallScoreData}
                    scoreType={scoreType}
                    spanWidth={12}
                  />
                  <Col xl={{ span: 24 }} xs={24}>
                    <Card
                      title={`Last ${userTranscript?.length} Conversations`}
                    >
                      <div
                        style={{
                          maxHeight: "400px",
                          overflowY: "auto",
                        }}
                      >
                        {userTranscript && userTranscript.length > 0 && (
                          <ConversationTable
                            data={userTranscript}
                            showRisk={true}
                            handleRowClick={handleTranscriptClick}
                            enablePagination={false}
                          />
                        )}
                      </div>
                      {transcriptState.hasMore && (
                        <div style={{ textAlign: "center", marginTop: 16 }}>
                          <Button onClick={loadMore} loading={loading}>
                            Load More
                          </Button>
                        </div>
                      )}
                    </Card>
                  </Col>
                </>
              ) : (
                <Typography.Paragraph style={{ paddingLeft: elementSize.sm }}>
                  No Insights available for {selectedUser.name} during{" "}
                  {agentDateFilter.format("YYYY-MM-DD")}
                </Typography.Paragraph>
              )
            ) : null}
          </Row>
        </Spin>
      )}
    </>
  );
};
