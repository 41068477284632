import React, { useState } from "react";
import { Card, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { DashboardActions } from "./DashboardActions/DashboardActions";
import { ConversationTable } from "./ConversationTable";

const { Text } = Typography;

export const DashboardTable = ({
  data,
  download = false,
  showRisk = true,
  sx = {},
}) => {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState(data);

  const handleRowClick = (transcript) => {
    navigate(`?conversation=${encodeURIComponent(transcript?.id)}`);
  };
  const handleFilterChange = (filteredData) => {
    setFilteredData(filteredData);
  };

  if (!filteredData || filteredData?.length === 0) {
    return (
      <Card>
        <DashboardActions
          transcripts={data}
          filteredData={filteredData}
          handleFilterChange={handleFilterChange}
        />
        <Text type="danger">
          Whoops! No record found for this - try fixing filters.
        </Text>
      </Card>
    );
  }
  return (
    <Card>
      <DashboardActions
        transcripts={data}
        filteredData={filteredData}
        handleFilterChange={handleFilterChange}
      />
      <ConversationTable
        data={filteredData}
        showRisk={showRisk}
        sx={sx}
        handleRowClick={handleRowClick}
      />
    </Card>
  );
};
