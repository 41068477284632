import { Card } from "antd";
import { deviceSize } from "src/styles/variables";
import styled from "styled-components";

export const CardWrapper = styled(Card)`
  width: 100%;
  @media (min-width: ${deviceSize.large_phone}) {
    width: 40%;
  }
`;
