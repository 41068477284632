import { Typography } from "antd";
import { NavLink } from "react-router-dom";

import { color } from "src/styles/variables";

export const ErrorBack = ({
  title,
  routingName,
  routingUrl = null,
  onClick = null,
}) => {
  return (
    <div
      style={{
        marginTop: "0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography.Title level={2}>
        {`This ${title} doesn't exist :(`}
      </Typography.Title>
      <Typography.Title level={4}>
        <NavLink
          style={{ color: color.orange }}
          to={routingUrl}
          onClick={onClick}
        >
          {`← Go back to ${routingName}`}
        </NavLink>
      </Typography.Title>
    </div>
  );
};
