import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  List,
  DatePicker,
  DatePickerProps,
  Tooltip,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CollaboratorAvatar, Loading } from "src/components";
import { ACCESS_TYPE, COLLECTION_DATA } from "src/utils/enums";
import { useOrganizationState } from "src/state/OrganizationState";
import { AgentListItem } from "./Agents.styles";
import { elementSize, fontSize } from "src/styles/variables";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { getRecordFromFireStore } from "src/firebaseAuth";
import { mapAndSortUsersMetric } from "src/utils/converter";
import { getUsersEmailDictionary } from "src/utils/utils";
import { Position } from "../BusinessInsights/LeaderBoard.styles";
import { useAgentDateSelection } from "src/state/UseAgentDateSelection";

export const AgentsDashboard = () => {
  const {
    collaborators,
    pendingCollaborators,
    organizationSettings,
    organization,
  } = useOrganizationState();
  const navigate = useNavigate();
  const [searchAgent, setSearchAgent] = useState("");
  const [combinedAgents, setCombinedAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const { date: agentDateFilter, setDate: setAgentDateFilter } =
    useAgentDateSelection();

  const onDateChange: DatePickerProps["onChange"] = (date: Dayjs | null) => {
    if (!date) {
      setOpen(true);
    } else {
      setAgentDateFilter(dayjs(date).startOf("week").startOf("day"));
    }
  };
  const handleOpenChange = (openStatus: boolean) => {
    setOpen(openStatus);
  };

  const scoreType = organizationSettings?.showRisk ? "Risk" : "Score";

  const allAgents = useMemo(
    () =>
      [...(collaborators ?? []), ...(pendingCollaborators ?? [])].filter(
        (user) => user.accessType === ACCESS_TYPE.agent,
      ),
    [collaborators, pendingCollaborators],
  );
  const filteredAgents = useMemo(() => {
    if (!searchAgent.trim()) return combinedAgents;
    const searchTermLower = searchAgent.toLowerCase().trim();
    return combinedAgents.filter((agent: any) =>
      agent?.name.toLowerCase().includes(searchTermLower),
    );
  }, [searchAgent, combinedAgents]);

  useEffect(() => {
    // Fetch insights for the current week
    const fetchInsights = async () => {
      setLoading(true);
      const doc = await getRecordFromFireStore(
        `organization/${organization}/${
          COLLECTION_DATA.INSIGHTS
        }/${agentDateFilter.format("YYYY-MM-DD")}`,
      );
      const docsAgentMetrics = doc?.agentMetrics || [];
      const agentMetrics = mapAndSortUsersMetric(
        docsAgentMetrics,
        getUsersEmailDictionary(allAgents, []),
        organizationSettings?.showRisk,
      );

      const allAgentsMap = new Map();
      allAgents.forEach((agent) => {
        allAgentsMap.set(agent.email, agent);
      });

      const combined = agentMetrics.map((leaderBoardAgent, index) => {
        const agent = allAgentsMap.get(leaderBoardAgent.User.email) || {
          email: leaderBoardAgent.User.email,
          name: leaderBoardAgent.User.name,
        };
        return {
          ...agent,
          ...leaderBoardAgent,
          rank: index + 1,
        };
      });

      allAgents.forEach((agent) => {
        const existsInLeaderBoard = agentMetrics.some(
          (leaderBoardAgent) => leaderBoardAgent.User.email === agent.email,
        );
        if (!existsInLeaderBoard) {
          combined.push({
            ...agent,
            "Total Transcripts": 0,
            "Total Duration": "-",
            "Average Duration": "-",
            Score: "",
            rank: combined.length + 1,
          });
        }
      });

      setCombinedAgents(combined);
      setLoading(false);
    };
    allAgents.length > 0 && fetchInsights();
  }, [agentDateFilter, organization, organizationSettings, allAgents]);

  const handleAgentClick = (agent) => {
    navigate(`?user=${encodeURIComponent(agent.email)}`);
  };

  const customWeekStartEndFormat: DatePickerProps["format"] = (value) =>
    `${dayjs(value).startOf("week").format("MM/DD")} ~ ${dayjs(value)
      .endOf("week")
      .format("MM/DD")}`;

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Card style={{ padding: `0px ${elementSize.sm}` }}>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 16 }}
          >
            <Col>
              <Input
                placeholder="Find an agent by name..."
                prefix={<SearchOutlined />}
                value={searchAgent}
                onChange={(e) => setSearchAgent(e.target.value)}
                style={{ width: 300, marginBottom: 8 }}
              />
              <div>{filteredAgents.length} agents found</div>
            </Col>
            <DatePicker
              onChange={onDateChange}
              open={open}
              picker="week"
              value={agentDateFilter}
              // defaultValue={agentDateFilter}
              format={customWeekStartEndFormat}
              onOpenChange={handleOpenChange}
            />
          </Row>
          <Row gutter={[16, 32]}>
            <List
              itemLayout="horizontal"
              dataSource={filteredAgents}
              style={{
                width: "100%",
                maxHeight: "60vh",
                overflowY: "auto",
              }}
              renderItem={(agent: any, index) => (
                <AgentListItem onClick={() => handleAgentClick(agent)}>
                  <List.Item.Meta
                    avatar={
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Position style={{ marginRight: elementSize.xxs }}>
                          {agent.rank}
                        </Position>
                        <CollaboratorAvatar collaborator={agent} />
                      </div>
                    }
                    title={
                      <Tooltip title={agent.name}>
                        <span
                          style={{
                            display: "block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {agent.name}
                        </span>
                      </Tooltip>
                    }
                    description={
                      <Tooltip title={agent.email}>
                        <span
                          style={{
                            display: "block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {agent.email}
                        </span>
                      </Tooltip>
                    }
                  />
                  <div style={{ marginRight: elementSize.xs }}>
                    <span style={{ fontSize: fontSize.body, opacity: 0.8 }}>
                      {scoreType}: {agent.Score}%
                    </span>
                    <span
                      style={{
                        fontSize: fontSize.body,
                        opacity: 0.8,
                        marginLeft: elementSize.xs,
                      }}
                    >
                      Transcripts: {agent["Total Transcripts"]}
                    </span>
                  </div>
                </AgentListItem>
              )}
            />
          </Row>
        </Card>
      )}
    </>
  );
};
