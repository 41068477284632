import React, { useState } from "react";
import { Input, Button, Upload, Spin, Modal, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";

const MAX_FILE_SIZE = 1048487; // 1MB in bytes

export const NewPlaylistForm = ({ onFormSubmit, setAddModalVisible }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useUserState();
  const { organization, setPlaylists } = useOrganizationState();

  const handleImageUpload = ({ file }) => {
    if (file.size > MAX_FILE_SIZE) {
      message.error("The file size exceeds the limit of 1MB.");
      return false; // Prevent the file from being uploaded
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(file);

    return false; // Prevent the default upload behavior
  };
  const resetModel = () => {
    setTitle("");
    setDescription("");
    setImage(null);
    setLoading(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    onFormSubmit(
      title,
      image,
      organization,
      user.email,
      setPlaylists,
      description,
    )
      .then(() => {
        resetModel();
        setAddModalVisible(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined style={{ fontSize: 24 }} />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Spin spinning={loading}>
      <div style={{ textAlign: "center", marginBottom: "16px" }}>
        <Upload
          showUploadList={false}
          beforeUpload={(file) => {
            handleImageUpload({ file });
            return false;
          }}
          listType={image ? null : "picture-circle"}
        >
          {image ? (
            <img
              src={image}
              alt="playlist"
              style={{ width: 100, height: 100, borderRadius: "50%" }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
      <Input
        placeholder="Playlist Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        style={{ marginBottom: "16px" }}
      />
      <Input
        placeholder="Playlist Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        style={{ marginBottom: "16px" }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={() => {
            setAddModalVisible(false);
            resetModel();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          style={{ marginLeft: "8px" }}
          type="primary"
          disabled={!title}
        >
          Create
        </Button>
      </div>
    </Spin>
  );
};

export const NewPlaylistModal = ({ visible, onCreate, setAddModalVisible }) => {
  return (
    <Modal
      open={visible}
      title="New Playlist"
      onCancel={() => setAddModalVisible(false)}
      footer={null}
    >
      <NewPlaylistForm
        onFormSubmit={onCreate}
        setAddModalVisible={setAddModalVisible}
      />
    </Modal>
  );
};
