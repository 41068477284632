import { QueryConditions } from "src/utils/types";
import dayjs from "dayjs";
import { ACCESS_TYPE } from "./enums";

export const thisWeeksQueryConditions: QueryConditions = {
  comparisonField: "transcript_data.metadata.transcriber.created",
  comparisonOperator: ">=",
  value: dayjs().startOf("week").startOf("day").toISOString(),
  order: "desc",
};

export const notDeletedConversations: QueryConditions = {
  comparisonField: "transcript_data.metadata.deleted",
  comparisonOperator: "==",
  value: false,
};

export const notDeletedEvaluationForms: QueryConditions = {
  comparisonField: "deleted",
  comparisonOperator: "==",
  value: false,
};

export const descOrderTranscripts: QueryConditions = {
  comparisonField: "transcript_data.metadata.transcriber.created",
  order: "desc",
};

export const ascOrderEvaluationForms: QueryConditions = {
  comparisonField: "title",
  order: "asc",
};

export const latestFeedbackConditions: QueryConditions = {
  comparisonField: "evaluation.lastFeedbackTime",
  order: "desc",
};
export const pendingInvitationsCondition: QueryConditions = {
  comparisonField: "pendingInvitation",
  comparisonOperator: "==",
  value: true,
};

export const TRUCO_CAL_LINK = "https://cal.com/trucoai/30min";
export const SANDBOX_TRIALS = 10;

export const RoleDescriptions = {
  [ACCESS_TYPE.owner]:
    "Can view/edit transcripts, settings, evaluation forms & members",
  [ACCESS_TYPE.reviewer]:
    "Responsible for assessing quality. Can view all transcripts, settings, evaluations & members.",
  [ACCESS_TYPE.lead]:
    "Responsible for leading a team of agents. Can view all transcripts, settings, evaluations & members.",
  [ACCESS_TYPE.agent]: "Can view their own transcripts",
};
