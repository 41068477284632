import {
  transformAnalysisStructures,
  evaluationFormsTransform,
  settingsConverter,
} from "src/utils/converter";
import { useCollection } from "src/hooks/useCollection";
import {
  ascOrderEvaluationForms,
  pendingInvitationsCondition,
} from "src/utils/variables";
import { QueryConditions } from "src/utils/types";
import {
  getOrganizationCondition,
  getUsersEmailDictionary,
} from "src/utils/utils";
import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import { useFiltersState } from "src/state/FiltersState";
import { notDeletedConversations } from "src/utils/variables";
import { transcriptConverter } from "src/utils/converter";
import { ACCESS_TYPE, COLLECTION_DATA } from "src/utils/enums";

export function CollectOrganizationData() {
  const {
    organization,
    setTranscripts,
    setAnalysisStructures,
    evaluationForms,
    setEvaluationForms,
    collaborators,
    setCollaborators,
    pendingCollaborators,
    setPendingCollaborators,
  } = useOrganizationState();
  const { user } = useUserState();
  const { setLoading, transcriptsConditions } = useFiltersState();

  useCollection(
    organization
      ? `/organization/${organization}/${COLLECTION_DATA.ANALYSIS}`
      : undefined,
    setAnalysisStructures,
    undefined,
    undefined,
    undefined,
    transformAnalysisStructures,
  );

  const organizationCollaborators = getOrganizationCondition(organization);
  useCollection(
    organization ? `/${COLLECTION_DATA.INVITATIONS}` : undefined,
    setPendingCollaborators,
    undefined,
    [organizationCollaborators, pendingInvitationsCondition],
  );

  useCollection(
    organization ? `/${COLLECTION_DATA.USERS}` : undefined,
    setCollaborators,
    undefined,
    [organizationCollaborators],
  );

  useCollection(
    organization
      ? `/organization/${organization}/${COLLECTION_DATA.EVALUATION_FORMS}`
      : undefined,
    setEvaluationForms,
    undefined,
    [ascOrderEvaluationForms],
    undefined,
    evaluationFormsTransform,
  );

  let agentQueryConditions: QueryConditions = null;
  if (user?.accessType === ACCESS_TYPE.agent) {
    agentQueryConditions = {
      comparisonField: "metadata.agent",
      comparisonOperator: "==",
      value: user?.email,
    };
  }
  const queryConditions = [
    ...transcriptsConditions,
    notDeletedConversations,
    ...(agentQueryConditions ? [agentQueryConditions] : []),
  ];
  useCollection(
    organization && evaluationForms
      ? `/organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}`
      : undefined,
    setTranscripts,
    transcriptConverter(
      evaluationForms,
      getUsersEmailDictionary(collaborators, pendingCollaborators),
      user,
    ),
    queryConditions,
    setLoading,
  );
}

export function CollectAllExistingOrganizations() {
  const { setAllExistingOrganizations } = useOrganizationState();

  useCollection(
    `/organization`,
    setAllExistingOrganizations,
    settingsConverter(),
  );
}
