import { message } from "antd";
import { SampleEvaluationFile } from "../TrucoSandbox/TrucoSandbox.utils";
import { borderRadius, color } from "src/styles/variables";
import { v4 as uuidv4 } from "uuid";

export const contentStyle: React.CSSProperties = {
  textAlign: "center",
  borderRadius: borderRadius.xs,
  border: `1px dashed ${color.gray}`,
  marginTop: 16,
};

export const steps = [
  {
    title: "Organization Name",
  },
  {
    title: "Evaluation Forms",
  },
];

export const validateOrganizationName = (organizationName) => {
  if (!organizationName.trim()) {
    message.error("Organization name cannot be empty or just spaces.");
    return false;
  }
  return true;
};

export const sampleDefaultEvaluations = [
  { ...SampleEvaluationFile, id: uuidv4() },
];

export const handleCheckboxChange = (
  evaluation,
  selectedEvaluations,
  setSelectedEvaluations,
) => {
  const isSelected = selectedEvaluations.some(
    (e) => e.title === evaluation.title,
  );
  if (isSelected) {
    setSelectedEvaluations(
      selectedEvaluations.filter((e) => e.title !== evaluation.title),
    );
  } else {
    setSelectedEvaluations([...selectedEvaluations, evaluation]);
  }
};

export const handleEyeClick = (evaluation, setOpenCurrentEvaluation) => {
  setOpenCurrentEvaluation(evaluation);
};
