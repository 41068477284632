import React, { ReactNode, useEffect, useState } from "react";
import { Layout, Drawer } from "antd";
import {
  DashboardOutlined,
  UploadOutlined,
  MessageOutlined,
  UsergroupDeleteOutlined,
  LineChartOutlined,
  PhoneOutlined,
  RocketOutlined,
  SearchOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import { PlaylistPlay } from "@mui/icons-material";
import { useUserState } from "src/state/UserState";
import { ACCESS_TYPE } from "src/utils/enums";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { color, deviceSize } from "src/styles/variables";
import {
  CustomMenu,
  CustomSider,
  DrawerContainer,
} from "./TabbedContainer.styles";

const { Content } = Layout;

enum ANALYSIS_TABS {
  DASHBOARD = "Dashboard",
  CONVERSATIONS = "Conversations",
  AGENTS = "Agents",
  CUSTOMERS = "Customers",
  INSIGHTS = "Insights",
  UPLOAD = "Upload",
  CHAT = "Chat",
  EVALUATION = "Evaluation",
  SEARCH = "Search",
  PLAYLIST = "Playlist",
  MANAGEORGS = "ManageOrgs",
}

const icons = {
  [ANALYSIS_TABS.DASHBOARD]: <DashboardOutlined />,
  [ANALYSIS_TABS.CONVERSATIONS]: <PhoneOutlined />,
  [ANALYSIS_TABS.INSIGHTS]: <LineChartOutlined />,
  [ANALYSIS_TABS.CHAT]: <MessageOutlined />,
  [ANALYSIS_TABS.UPLOAD]: <UploadOutlined />,
  [ANALYSIS_TABS.AGENTS]: <UsergroupDeleteOutlined />,
  [ANALYSIS_TABS.CUSTOMERS]: <RocketOutlined />,
  [ANALYSIS_TABS.SEARCH]: <SearchOutlined />,
  [ANALYSIS_TABS.PLAYLIST]: <PlaylistPlay />,
  [ANALYSIS_TABS.MANAGEORGS]: <ApartmentOutlined />,
};

type PageEntry = {
  content?: JSX.Element;
  icon: ReactNode;
  children?: { [key: string]: PageEntry };
  onTitleClick?: () => void;
  url?: string;
  label?: string;
};

type PagesType = {
  [key: string]: PageEntry;
};

const TabbedContainer = () => {
  const { user } = useUserState();

  const navigate = useNavigate();
  const location = useLocation();
  const { encryptedOrganizationId, encryptedPlaylistId } = useParams();
  const initialKey = () => {
    const pathParts = location.pathname.split("/");
    const mainKey = pathParts[1] ? pathParts[1].toUpperCase() : null;
    if (mainKey && ANALYSIS_TABS[mainKey as keyof typeof ANALYSIS_TABS]) {
      return ANALYSIS_TABS[mainKey];
    }
    return `${ANALYSIS_TABS.DASHBOARD}-${ANALYSIS_TABS.CONVERSATIONS}`;
  };

  const [selectedKey, setSelectedKey] = useState(initialKey());
  const [collapsed, setCollapsed] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const isBreakpoint = useMediaQuery({
    maxWidth: `${deviceSize.small_phone_size}`,
  });

  const pages: PagesType = {
    [ANALYSIS_TABS.DASHBOARD]: {
      icon: icons[ANALYSIS_TABS.DASHBOARD],
      children: {
        [ANALYSIS_TABS.CONVERSATIONS]: {
          icon: icons[ANALYSIS_TABS.CONVERSATIONS],
          url: "/conversations",
        },
        ...(user.accessType !== ACCESS_TYPE.agent && {
          [ANALYSIS_TABS.AGENTS]: {
            icon: icons[ANALYSIS_TABS.AGENTS],
            url: "/agents",
          },
        }),
        [ANALYSIS_TABS.CUSTOMERS]: {
          icon: icons[ANALYSIS_TABS.CUSTOMERS],
          url: "/customers",
        },
      },
    },
    [ANALYSIS_TABS.INSIGHTS]: {
      icon: icons[ANALYSIS_TABS.INSIGHTS],
      url: "/insights",
    },
    [ANALYSIS_TABS.PLAYLIST]: {
      icon: icons[ANALYSIS_TABS.PLAYLIST],
      url:
        encryptedOrganizationId && encryptedPlaylistId
          ? `/playlist/${encryptedOrganizationId}/${encryptedPlaylistId}`
          : "/playlist",
    },
    [ANALYSIS_TABS.CHAT]: {
      icon: icons[ANALYSIS_TABS.CHAT],
      url: "/chat",
    },
    [ANALYSIS_TABS.SEARCH]: {
      url: "/search",
      icon: icons[ANALYSIS_TABS.SEARCH],
    },
    ...(user.accessType !== ACCESS_TYPE.agent && {
      [ANALYSIS_TABS.UPLOAD]: {
        icon: icons[ANALYSIS_TABS.UPLOAD],
        url: "/upload",
      },
    }),
    ...(user.accessType === ACCESS_TYPE.trucoAdmin && {
      [ANALYSIS_TABS.MANAGEORGS]: {
        icon: icons[ANALYSIS_TABS.MANAGEORGS],
        url: "/manageOrgs",
        label: "Manage Org",
      },
    }),
  };

  const menuItems = Object.keys(pages).map((key) => ({
    key,
    icon: pages[key].icon,
    label: pages[key].label ?? key,
    onTitleClick: () => {
      if (
        !Object.keys(pages[key].children || {}).includes(
          selectedKey.split("-")[1],
        )
      ) {
        setSelectedKey(`${key}-${Object.keys(pages[key].children || {})[0]}`);
      }
    },
    children: pages[key].children
      ? Object.keys(pages[key].children).map((childKey) => {
          const child = pages[key].children[childKey];
          return {
            key: `${key}-${childKey}`,
            icon: child.icon,
            label: childKey,
          };
        })
      : undefined,
  }));

  useEffect(() => {
    const keys = selectedKey.split("-");
    const mainKey = keys[0];
    const subKey = keys[1];

    if (
      mainKey === ANALYSIS_TABS.PLAYLIST &&
      encryptedOrganizationId &&
      encryptedPlaylistId
    ) {
      return;
    }
    if (subKey && pages[mainKey] && pages[mainKey]?.children) {
      const targetPage = pages[mainKey]?.children[subKey];

      if (targetPage?.url) {
        navigate(targetPage.url + location.search, {
          replace: true,
        });
        return;
      }
    }
    if (pages[mainKey]?.url) {
      navigate(pages[mainKey].url + location.search, { replace: true });
    }
  }, [selectedKey, navigate, location.search]);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <Layout>
      {isBreakpoint ? (
        <Drawer
          placement="left"
          closable={false}
          onClose={toggleDrawer}
          open={drawerVisible}
          styles={{
            body: { padding: "10px" },
            wrapper: {
              maxWidth: "90vw",
            },
          }}
        >
          <CustomMenu
            mode="inline"
            selectedKeys={[selectedKey]}
            items={menuItems}
            onClick={({ key }) => {
              if ([ANALYSIS_TABS.PLAYLIST].includes(key)) {
                navigate(`/${key.toLowerCase()}`);
              }
              setSelectedKey(key);
              setDrawerVisible(false);
            }}
          />
        </Drawer>
      ) : (
        <CustomSider
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
        >
          <CustomMenu
            mode="inline"
            selectedKeys={[selectedKey]}
            items={menuItems}
            onClick={({ key }) => {
              if ([ANALYSIS_TABS.PLAYLIST].includes(key)) {
                navigate(`/${key.toLowerCase()}`);
              }
              setSelectedKey(key);
            }}
          />
        </CustomSider>
      )}
      <Layout
        style={{
          backgroundColor: `${color.white}`,
          minHeight: `calc(100vh - 84px)`,
        }}
      >
        <Content style={{ padding: "24px", minHeight: "280px" }}>
          <Outlet />
        </Content>
      </Layout>

      {isBreakpoint && (
        <DrawerContainer onClick={toggleDrawer}>
          {drawerVisible ? (
            <MenuFoldOutlined
              style={{
                fontSize: "25px",
                color: `${color.white}`,
              }}
            />
          ) : (
            <MenuUnfoldOutlined
              style={{
                fontSize: "25px",
                color: `${color.white}`,
              }}
            />
          )}
        </DrawerContainer>
      )}
    </Layout>
  );
};

export default TabbedContainer;
