import { Space, Typography } from "antd";
import { Loading } from "src/components";
import { color, spacing } from "src/styles/variables";
import { LockOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
export const INTERNAL_TEAM_MESSAGE = ` Internal Team only`;

interface NoPermissionsProps {
  description?: string;
}

export const NoPermissions: React.FC<NoPermissionsProps> = ({
  description = "It seems like you don't have the necessary permissions to access this content. If you think this is a mistake, please contact support@truco.ai.",
}) => {
  return (
    <Space
      direction="vertical"
      size="small"
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        margin: `auto ${spacing.xxl}`,
      }}
    >
      <Loading text={""} />
      <Title
        level={2}
        style={{ margin: `0 ${spacing.md}`, color: color.olive }}
      >
        Whoops, this is embarassing!
      </Title>
      <Text style={{ color: color.olive }}>{description}</Text>
    </Space>
  );
};

export const InternalTeamErrorMessage = ({ message }) => {
  return (
    <Text
      style={{
        color: color.red,
        fontWeight: "bold",
        whiteSpace: "pre-line",
      }}
    >
      <LockOutlined />
      {message}
    </Text>
  );
};
