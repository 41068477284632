import React from "react";
import { Collapse } from "antd"; // Assuming you're using Ant Design for Collapse component
import { INTEGRATION_ICONS } from "src/components/Profile";
import {
  capitalizeFirstLetter,
  formatDateToHumanReadable,
} from "src/utils/utils";
import { elementSize } from "src/styles/variables";

export const IntegrationDataCard = ({ modalData }) => {
  const icon = INTEGRATION_ICONS?.[modalData.file_source];
  const data = modalData.integration_data || {};

  const collapseItems = [
    {
      key: "1",
      label: (
        <div
          style={{ display: "flex", alignItems: "center", gap: elementSize.sm }}
        >
          {icon}
          {capitalizeFirstLetter(modalData.file_source)} Data
        </div>
      ),
      children: (
        <div>
          {Object.entries(data).map(([key, value]) => {
            if (value === null || value === undefined) return null;
            let displayValue: any = value;
            if (typeof value === "string" && !isNaN(Date.parse(value))) {
              displayValue = formatDateToHumanReadable(value);
            } else if (typeof value === "object") {
              displayValue = JSON.stringify(value, null, 2);
            }

            return (
              <div key={key} style={{ marginBottom: "8px" }}>
                <strong>
                  {capitalizeFirstLetter(key.replace(/_/g, " "))}:
                </strong>{" "}
                {displayValue}
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  return (
    <Collapse
      items={collapseItems}
      defaultActiveKey={collapseItems.map((item) => item.key)}
    />
  );
};
