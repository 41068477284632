import { useState, useContext, createContext } from "react";
import { QueryConditions, dateRange } from "src/utils/types";
import dayjs from "dayjs";
import { thisWeeksQueryConditions } from "src/utils/variables";

const FiltersContext = createContext({
  filterDates: null as dateRange,
  setFilterDates: null,
  loading: false,
  setLoading: null,
  transcriptsConditions: [] as QueryConditions[],
  setTranscriptsConditions: null,
});

export const FiltersProvider = ({ children }) => {
  const [filterDates, setFilterDates] = useState({
    startDate: dayjs().startOf("week").startOf("day"),
    endDate: undefined,
  });

  const [loading, setLoading] = useState(false);

  const [transcriptsConditions, setTranscriptsConditions] = useState([
    thisWeeksQueryConditions,
  ]);

  return (
    <FiltersContext.Provider
      value={{
        filterDates,
        setFilterDates,
        loading,
        setLoading,
        transcriptsConditions,
        setTranscriptsConditions,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersState = () => useContext(FiltersContext);
