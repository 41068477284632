import React, { useMemo } from "react";
import dayjs from "dayjs";
import { Verified } from "@mui/icons-material";
import { Progress, Space } from "antd";

import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import { useFiltersState } from "src/state/FiltersState";

import { ACCESS_TYPE } from "src/utils/enums";
import { formatDateRange, isReviewerInCalibration } from "src/utils/utils";
import { color, elementSize, fontSize, fontWeight } from "src/styles/variables";
import {
  AssignmentCard,
  OrgContainer,
  OrgContainerHeader,
} from "./Dashboard.styles";
import { OrganizationSelect } from "../Filters/OrganizationSelect";

const Actions = () => {
  const { filterDates } = useFiltersState();
  const { transcripts } = useOrganizationState();
  const { user } = useUserState();

  const getColor = (percent: number) => {
    if (percent === 100) return color.lightGreen;
    if (percent >= 75) return color.orange;
    return color.red;
  };

  const filteredUserTranscripts = useMemo(() => {
    if (!transcripts) return [];
    return transcripts.filter(
      (transcript) =>
        transcript.reviewer?.email === user.email ||
        transcript.agent?.email === user.email ||
        transcript.lead?.email === user.email,
    );
  }, [transcripts, user.email]);

  const verifiedCount = filteredUserTranscripts.filter((transcript) => {
    const isVerified = transcript.evaluation?.verifications?.includes(
      user.email,
    );
    const isReviewerInCalib = isReviewerInCalibration(
      transcript?.calibration,
      user,
    );
    const isCalibVerified = transcript.calibration?.evaluation?.verified;
    const isRegularReviewerVerified =
      transcript.reviewer?.email === user.email &&
      transcript.evaluation?.verified;

    return (
      isVerified ||
      (isReviewerInCalib && isCalibVerified) ||
      isRegularReviewerVerified
    );
  }).length;

  const progress = Math.round(
    (verifiedCount / filteredUserTranscripts.length) * 100,
  );
  return (
    <OrgContainer>
      {user?.accessType === ACCESS_TYPE.trucoAdmin && (
        <OrgContainerHeader>
          <OrganizationSelect />
        </OrgContainerHeader>
      )}

      <AssignmentCard>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: elementSize.sm,
          }}
        >
          <Verified style={{ color: color.lightOrange }} />
          <div>
            <div
              style={{
                fontSize: fontSize.displayXL,
                fontWeight: fontWeight.semiBold,
                marginBottom: elementSize.xs,
              }}
            >
              My Assignments
            </div>
            <div style={{ color: color.grayMedium }}>
              {formatDateRange(
                dayjs(filterDates.startDate),
                filterDates.endDate
                  ? dayjs(filterDates.endDate)
                  : dayjs(filterDates.startDate).add(6, "day"),
              )}
            </div>
          </div>
        </div>
        <Space align="center">
          {filteredUserTranscripts.length > 0 ? (
            <>
              <Progress
                style={{ width: 180 }}
                percent={progress}
                type="line"
                strokeColor={getColor(progress)}
                size="small"
                percentPosition={{ align: "start", type: "outer" }}
              />
              <div style={{ fontSize: "12px" }}>
                {`${verifiedCount}/${filteredUserTranscripts.length}`}
              </div>
            </>
          ) : (
            <p style={{ fontWeight: fontWeight.semiBold }}>
              No Assignments assigned yet!
            </p>
          )}
        </Space>
      </AssignmentCard>
    </OrgContainer>
  );
};

export default Actions;
