import { Button } from "antd";
import { color } from "src/styles/variables";
import styled from "styled-components";

interface PillButtonProps {
  active?: boolean;
}

export const PillButton = styled(Button)<PillButtonProps>`
  border-radius: 50px;
  transition:
    background-color 0.3s,
    color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.grayLight};
  border-color: ${(props) => (props.active ? color.orange : color.grayLight)};

  ${(props) =>
    props.active &&
    `
      &:after {
        content: "";
        position: absolute;
        top: -2px;
        right: -2px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${color.orange};
      }
    `};
`;
export const TagContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 300px;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;
export const MenuContainer = styled.div`
  border: 1px solid ${color.grayLight};
  border-radius: 8px;
  z-index: 100;
  background-color: ${color.white};
  max-width: 300px;
`;
