import TrucoLogo from "src/components/TrucoLogo/TrucoLogo";
import { Space, Typography } from "antd";
import { color, spacing } from "src/styles/variables";

const { Title, Text, Link } = Typography;

interface PublicFooterProps {
  type?: string;
}

export const PoweredByPublicFooter: React.FC<PublicFooterProps> = ({
  type = "page",
}) => {
  return (
    <Space
      direction="vertical"
      size="small"
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        paddingBottom: spacing.md,
        ...(type === "page" && {
          position: "absolute",
          bottom: 0,
        }),
      }}
    >
      <Space
        direction="horizontal"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Title level={4} style={{ margin: 0, marginRight: spacing.xxs }}>
          Powered by
        </Title>
        <TrucoLogo size="small" />
      </Space>
      <Text>
        Get your own{" "}
        <Link href={"/"} style={{ color: color.orange }}>
          customer intelligence
        </Link>
      </Text>
    </Space>
  );
};
