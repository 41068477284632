import { Col, Flex, Row } from "antd";
import { useEffect, useState } from "react";
import { Filters, DownloadCSV } from "src/components";
import { useUserState } from "src/state/UserState";
import { FilterContainer } from "../Dashboard.styles";
import { DateFilter } from "src/components/Filters/DateFilter";
import { useOrganizationState } from "src/state/OrganizationState";
import { QA_TYPES } from "src/utils/enums";

interface DashboardActionsProps {
  transcripts: any[];
  filteredData?: any[];
  handleFilterChange: (filter: any) => void;
  showFiltersTranscripts?: boolean;
  onFilterShowClick?: () => void;
  type?: string;
}

export const DashboardActions: React.FC<DashboardActionsProps> = ({
  transcripts,
  filteredData = null,
  handleFilterChange,
  showFiltersTranscripts = true,
  onFilterShowClick,
  type = "conversations",
}) => {
  const [showFilter, setShowFilter] = useState(true);
  const { user } = useUserState();
  const [filtersApplied, setFiltersApplied] = useState(false);
  const { evaluationForms } = useOrganizationState();
  const [firstClick, setFirstClick] = useState(true);
  const [selectedValues, setSelectedValues] = useState({
    evaluation_title: [],
    user: type === "insights" ? [] : [user?.email],
    team: [],
    qaType: undefined,
    analysisKeys: [],
  });

  useEffect(() => {
    const filtersApplied =
      selectedValues.evaluation_title.length > 0 ||
      selectedValues.user.length > 0 ||
      selectedValues.team.length > 0 ||
      selectedValues.qaType !== undefined;
    setFiltersApplied(filtersApplied);
  }, [selectedValues]);
  useEffect(() => {
    const applyFilters = (updatedValues) => {
      let newData = transcripts || null;
      newData = newData?.filter(
        (transcript) =>
          (updatedValues?.evaluation_title?.length === 0 ||
            updatedValues.evaluation_title.includes(
              transcript?.evaluation_form?.title,
            )) &&
          (updatedValues?.user?.length === 0 ||
            updatedValues.user.includes(transcript?.reviewer?.email) ||
            updatedValues.user.includes(transcript?.agent?.email) ||
            updatedValues.user.includes(transcript?.lead?.email)) &&
          (updatedValues?.team?.length === 0 ||
            updatedValues?.team?.filter((t) =>
              evaluationForms?.[
                transcript?.evaluation_form?.id
              ]?.teams?.includes(t),
            )?.length > 0) &&
          ([null, QA_TYPES.ALL, undefined].includes(updatedValues?.qaType) ||
            (updatedValues?.qaType === QA_TYPES.MANUAL_QA &&
              transcript?.evaluation?.verified) ||
            (updatedValues?.qaType === QA_TYPES.AUTO_QA &&
              !transcript?.evaluation?.verified)),
      );

      handleFilterChange(newData);
    };
    applyFilters(selectedValues);
  }, [selectedValues, transcripts]);

  return (
    <FilterContainer>
      <Row justify={"space-between"}>
        <Col span={12}>
          {showFilter && showFiltersTranscripts && (
            <Filters
              transcripts={transcripts}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              setShowFilter={setShowFilter}
            />
          )}
        </Col>
        <Col span={12}>
          <Flex justify="flex-end" align="center" gap={"15px"}>
            <DateFilter />
            {filteredData && (
              <DownloadCSV data={filteredData} fileName={"data_truco"} />
            )}
          </Flex>
        </Col>
      </Row>
    </FilterContainer>
  );
};
