import { StandardPage } from "../Home";

import { useEffect, useState } from "react";
import { useOrganizationState } from "src/state/OrganizationState";

import { useParams } from "react-router-dom";

import useFetchSignals from "src/hooks/useFetchSignals";
import { CommonForm } from "./CommonForm";
import { FORM_TYPE } from "../Profile/Profile.enums";
import { SignalType } from "src/utils/types";
import { createNewSignal } from "./Evaluation.utils";
import cloneDeep from "lodash/cloneDeep";

export const validateSignalFields = (currentSelectedItem, setErrors?) => {
  let newErrors = {};
  let isValid = true;

  if (!currentSelectedItem.title || !currentSelectedItem.title.trim()) {
    isValid = false;
    newErrors["signal-title"] = "Signal title is required";
  }

  setErrors && setErrors(newErrors);
  return isValid;
};

export const Signals = () => {
  const { organization } = useOrganizationState();
  const { id } = useParams();
  const { signals, fetchSignals } = useFetchSignals();
  const isEditMode = id != null;

  const [selectedSignal, setSelectedSignal] = useState<SignalType>(() => {
    return isEditMode && signals && signals?.[id]
      ? signals[id]
      : createNewSignal();
  });
  const [currentSelectedSignal, setCurrentSelectedSignal] =
    useState<SignalType>(() => cloneDeep(selectedSignal));

  useEffect(() => {
    if (!signals && id) {
      fetchSignals();
    }
  }, [organization, id]);

  const signalContent = (
    <CommonForm
      existingData={signals}
      type={FORM_TYPE.SIGNALS}
      selectedItem={selectedSignal}
      setSelectedItem={setSelectedSignal}
      currentSelectedItem={currentSelectedSignal}
      setCurrentSelectedItem={setCurrentSelectedSignal}
    />
  );

  return <StandardPage content={signalContent} />;
};
