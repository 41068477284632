import React from "react";
import { FadeContainer, Fade } from "../LandingPage.styles";
import {
  color,
  elementSize,
  fontSize,
  fontWeight,
  hexToHex,
} from "src/styles/variables";
import { Typography } from "antd";
import { ArrowIcon, WaveSvgIcon } from "../../Loading/icons";
import { DeskTopOnly } from "src/styles/stylingComponents";
import { RatingComponent } from "src/components/Dashboard";
import {
  AnimatedTextCard,
  ArrowDiv,
  Container,
  EllipseLarge,
  EllipseSmall,
  FirstProductCard,
  GetDemoButton,
  HeadingContainer,
  OuterContainer,
  SeocndProductCard,
  WaveWrapper,
} from "./ProductDemo.styles";
import { AnalysisTableComponent } from "src/components/Dashboard/Dashboard.styles";

export const ProductDemo = () => {
  const sampleAnalysis = [
    {
      rating: "66%",
      label: "Overall",
      isGroupHeader: true,
    },
    {
      rating: null,
      label: "Issue",
      value: "Search Malfunction",
      isGroupHeader: false,
    },
    {
      rating: 0,
      label: "Hold Time",
      value: "123s",
      isGroupHeader: false,
    },
    {
      rating: null,
      label: "Sentiment",
      value: "Neutral",
      isGroupHeader: false,
    },
    {
      rating: "100%",
      label: "Opening",
      value: "",
      isGroupHeader: true,
    },
    {
      rating: 1,
      label: "Account Confirmation",
      value: "4323453982",
      isGroupHeader: false,
    },
    {
      rating: 1,
      label: "Greeting",
      value: "Anna from Conceirge",
      isGroupHeader: false,
    },
  ];
  const columns = [
    {
      title: "Label",
      width: "10%",
      dataIndex: "label",
      render: (text, record) => {
        if (record.isGroupHeader) {
          return <b>{text}</b>;
        }
        return text;
      },
    },
    {
      title: "Value",
      width: "10%",
      dataIndex: "value",
    },
    {
      title: "Rating",
      width: "10%",
      dataIndex: "rating",
      render: (text, record) => {
        return <RatingComponent rating={text} />;
      },
    },
  ];
  return (
    <OuterContainer>
      <Container>
        <DeskTopOnly style={{ position: "absolute" }}>
          <EllipseLarge />
          <EllipseSmall />
        </DeskTopOnly>

        <HeadingContainer>
          <Typography.Title
            level={1}
            style={{
              fontSize: fontSize.displayXXXXL,
              marginTop: 0,
              color: color.white,
            }}
          >
            Instant quality insights to drive performance excellence
          </Typography.Title>
          <Typography.Paragraph
            style={{
              fontSize: fontSize.displayXLL,
              color: color.white,
            }}
          >
            Capture every conversation, evaluate as tailored to your business,
            and enhance both team and product performance. Truco AI is the
            premier AI native customer intelligence platform for capturing
            support opportunities.
          </Typography.Paragraph>
          <GetDemoButton
            onClick={() =>
              (window.location.href =
                process.env.REACT_APP_TRUCO_DOCS + "docs/intro")
            }
          >
            Try Now{" "}
          </GetDemoButton>
          <Typography.Paragraph
            style={{
              paddingTop: elementSize.xs,
              color: color.white,
              fontWeight: fontWeight.semiBold,
            }}
          >
            Try free for 30 days
          </Typography.Paragraph>
        </HeadingContainer>
        <FirstProductCard>
          {/*           <DeskTopOnly style={{ cursor: "pointer" }}> */}
          {/*             <div */}
          {/*               style={{ */}
          {/*                 zIndex: "3", */}
          {/*                 bottom: "-10%", */}
          {/*                 left: "-15%", */}
          {/*                 position: "absolute", */}
          {/*               }} */}
          {/*             > */}
          {/*                 <PlayIcon /> */}
          {/*             </div> */}
          {/*           </DeskTopOnly> */}
          <AnimatedTextCard style={{ width: "289px", height: "328px" }}>
            <FadeContainer>
              <Fade>
                Hi! I'm Anna from the concierge department - how can I assist
                you today?
                <u style={{ color: color.black }}>
                  {" "}
                  <br />
                  <br /> I can't find any cars to rent, something's wrong here.
                </u>
                <br />
                <br />
                Sorry for the inconvenience, i'd love to help you out here. Can
                you provide me the phone number associated with the account?
                <u style={{ color: color.black }}>
                  {" "}
                  <br />
                  <br /> yeah sure it's four three two three four five three
                  nine eight two.
                </u>
                <br />
                <br />
              </Fade>
            </FadeContainer>{" "}
          </AnimatedTextCard>
          <ArrowDiv>
            <ArrowIcon />
          </ArrowDiv>
        </FirstProductCard>

        <SeocndProductCard>
          {/*           <MobileOnly> */}
          {/*             <div */}
          {/*               style={{ */}
          {/*                 zIndex: "3", */}
          {/*                 bottom: "-10%", */}
          {/*                 left: "-15%", */}
          {/*                 position: "absolute", */}
          {/*               }} */}
          {/*             > */}
          {/*               <PlayIcon /> */}
          {/*             </div> */}
          {/*           </MobileOnly> */}
          <AnimatedTextCard>
            <AnalysisTableComponent
              dataSource={sampleAnalysis}
              columns={columns}
              pagination={false}
              size="small"
              showHeader={false}
              rowClassName={(record) =>
                record.isGroupHeader ? "groupHeaderRow" : "normalRow"
              }
            />
          </AnimatedTextCard>
        </SeocndProductCard>
        <WaveWrapper>
          <WaveSvgIcon
            viewPort="0 70 252 263"
            fillColor={hexToHex(color.olive, 0.1)}
          />
        </WaveWrapper>
      </Container>
    </OuterContainer>
  );
};
