import dayjs from "dayjs";
import { Card, Col, Row } from "antd";
import { UserOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { color, fontSize } from "src/styles/variables";
import { useEffect, useMemo, useState } from "react";
import { useOrganizationState } from "src/state/OrganizationState";
import { getOrgStats } from "src/utils/apiCalls";
import { MiniSubscriptionDetailsCard } from "./MiniSubscriptionDetailsCard";
import { SubscriptionDetailsCard } from "./SubscriptionDetailsCard";

export const SubscriptionCard: React.FC = () => {
  const [orgStat, setOrgStatistics] = useState<number | null>(null);
  const { collaborators, pendingCollaborators, organization } =
    useOrganizationState();
  const allUsers = useMemo(
    () => [...(collaborators ?? []), ...(pendingCollaborators ?? [])],
    [collaborators, pendingCollaborators],
  );

  useEffect(() => {
    if (organization) {
      getOrgStats(organization, setOrgStatistics, [
        {
          comparisonField: "transcript_data.metadata.transcriber.created",
          comparisonOperator: ">=",
          value: dayjs().startOf("month").toISOString(),
        },
      ]);
    }
  }, [organization]);

  return (
    <Card
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Row gutter={[16, 16]} justify="center" align="middle">
        <Col>
          <MiniSubscriptionDetailsCard
            icon={
              <UserOutlined
                style={{
                  fontSize: fontSize.displayXXXXLL,
                  color: color.orange,
                }}
              />
            }
            label="Number of Seats"
            value={allUsers.length}
          />
        </Col>
        <Col>
          <SubscriptionDetailsCard seats={allUsers.length} />
        </Col>
        <Col>
          <MiniSubscriptionDetailsCard
            icon={
              <ClockCircleOutlined
                style={{
                  fontSize: fontSize.displayXXXXLL,
                  color: color.orange,
                }}
              />
            }
            label="Usage This Month"
            value={orgStat}
          />
        </Col>
      </Row>
    </Card>
  );
};
