import React from "react";
import { useUserState } from "src/state/UserState";
import { CollaboratorAvatar, UserOption } from "src/components";
import { StandardPage } from "../Home";
import {
  addDocumentWithId,
  getRecordsFromFireStore,
  updateDocInFireStore,
} from "src/firebaseAuth";
import { documentId } from "firebase/firestore";
import { SectionContainer } from "../LandingPage/LandingPage.styles";
import {
  Form,
  Select,
  Button,
  Radio,
  Input,
  InputNumber,
  notification,
  Typography,
  Switch,
  Steps,
  Spin,
  Space,
  List,
  Card,
  Divider,
} from "antd";

import {
  color,
  elementSize,
  fontWeight,
  hexToRgba,
  spacing,
} from "src/styles/variables";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOptions } from "src/utils/utils";
import { SpaceBetweenDiv } from "../Profile/Profile.styles";
import { useOrganizationState } from "src/state/OrganizationState";
import { useNavigate } from "react-router-dom";
import { EditFilled } from "@ant-design/icons";
import {
  CardLayoutContainer,
  ContentSection,
  TitleUnderline,
} from "../Evaluation/Evaluation.styles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { ACCESS_TYPE, COLLECTION_DATA } from "src/utils/enums";
import { assigmentsTrackingConverter } from "src/utils/converter";
import useFetchAssignments from "src/hooks/useFetchAssignments";
const { Option } = Select;

const ALL_REVIEWERS = { name: "All Reviewers", email: "reviewers" };
const ALL_AGENTS = { name: "All Agents", email: "agents" };

interface RevieweeAssignments {
  [email: string]: {
    count: number;
  };
}

interface ReviewerAssignments {
  [email: string]: {
    reviewees: {
      [reviewee: string]: number;
    };
    count: number;
  };
}

interface TrackingItem {
  title: string;
  revieweeAssignments: RevieweeAssignments;
  reviewerAssignments: ReviewerAssignments;
  id: string;
}

interface TrackingProps {
  trackings: TrackingItem[];
  activeTrackingIndex: number;
  setActiveTrackingIndex: any;
  allUsers: any[];
}

const Tracking: React.FC<TrackingProps> = ({
  trackings,
  activeTrackingIndex,
  setActiveTrackingIndex,
  allUsers,
}) => {
  const handleDateChange = (direction) => {
    const newIndex =
      direction === "prev" ? activeTrackingIndex - 1 : activeTrackingIndex + 1;
    if (newIndex >= 0 && newIndex < trackings.length) {
      setActiveTrackingIndex(newIndex);
    }
  };

  const renderCards = () => {
    const activeData = trackings[activeTrackingIndex].reviewerAssignments;
    return Object.entries(activeData).map(([email, { count, reviewees }]) => {
      let user = allUsers.find((user) => user.email === email);
      user = user || { email: email, name: email };
      return (
        <Card
          key={email}
          style={{
            width: 300,
            marginBottom: 16,
            backgroundColor: hexToRgba(color.grayLight, 0.2),
          }}
        >
          <Card.Meta
            avatar={<CollaboratorAvatar collaborator={user} />}
            title={user.name}
            description={
              <>
                {Object.entries(reviewees).map(([reviewee, reviewCount]) => {
                  let revieweeUser = allUsers.find(
                    (user) => user.email === reviewee,
                  );
                  revieweeUser = revieweeUser || { email: email, name: email };
                  return (
                    <SpaceBetweenDiv
                      key={reviewee}
                      style={{
                        color: color.blackMedium,
                        fontWeight: fontWeight.semiBold,
                      }}
                    >
                      <div>
                        {revieweeUser.name.length > 20
                          ? `${revieweeUser.name.substring(0, 20)}...`
                          : revieweeUser.name}
                      </div>
                      <div>{reviewCount}</div>
                    </SpaceBetweenDiv>
                  );
                })}
                <Divider />
                <SpaceBetweenDiv>
                  <div
                    style={{
                      color: color.blackMedium,
                      fontWeight: fontWeight.semiBold,
                    }}
                  >
                    Total Reviews
                  </div>
                  <div
                    style={{
                      color: color.orange,
                      fontWeight: fontWeight.semiBold,
                      fontSize: "18px",
                    }}
                  >
                    {count}
                  </div>
                </SpaceBetweenDiv>
              </>
            }
          />
        </Card>
      );
    });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span
          onClick={() => setActiveTrackingIndex(-1)}
          style={{ cursor: "pointer" }}
        >
          <LeftOutlined style={{ marginRight: elementSize.sm }} />
          Back
        </span>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Button
            shape="circle"
            disabled={activeTrackingIndex === 0}
            onClick={() => handleDateChange("prev")}
          >
            <LeftOutlined />
          </Button>
          <span style={{ margin: "0 16px", fontWeight: fontWeight.semiBold }}>
            {trackings[activeTrackingIndex].title}
          </span>
          <Button
            shape="circle"
            disabled={activeTrackingIndex === trackings.length - 1}
            onClick={() => handleDateChange("next")}
          >
            <RightOutlined />
          </Button>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: elementSize.xl,
          gap: elementSize.sm,
        }}
      >
        {renderCards()}
      </div>
    </div>
  );
};

export const Assignments = () => {
  const { organization, evaluationForms, collaborators, pendingCollaborators } =
    useOrganizationState();
  const { user } = useUserState();
  const { assignments, fetchAssignments } = useFetchAssignments();

  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = id != null; // true if 'id' is present in the URL
  const [disableEditing, setDisableEditing] = useState(isEditMode);
  const hasAccess =
    user.accessType === ACCESS_TYPE.owner ||
    user.accessType === ACCESS_TYPE.trucoAdmin;
  const [disableInputs, setdisableInputs] = useState(
    !hasAccess || disableEditing,
  );
  const [loading, setLoading] = useState(true);
  const [trackings, setTrackings] = useState([]);
  const [activeTrackingIndex, setActiveTrackingIndex] = useState(-1); // State to track the active item

  const [currentSelectedAssignment, setCurrentSelectedAssignment] = useState(
    () => {
      return isEditMode && assignments && assignments[id]
        ? assignments[id]
        : null;
    },
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();

  const toggleEditMode = () => {
    const editValue = !disableEditing;
    setdisableInputs(!hasAccess || editValue);
    setDisableEditing(editValue);
  };

  const fetchTrackingDatesAndData = async (assignmentId) => {
    setLoading(true);
    const data = (
      await getRecordsFromFireStore(
        `/organization/${organization}/${COLLECTION_DATA.ASSIGNMENTS}/${assignmentId}/tracking/`,
        [
          {
            comparisonField: documentId(),
            order: "desc",
          },
        ],
        assigmentsTrackingConverter,
        undefined,
        10,
      )
    ).data;
    setTrackings(data); // no need for last visible now
  };

  useEffect(() => {
    if (!assignments) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (isEditMode) {
      if (!assignments) {
        setLoading(true);
      } else {
        if (assignments[id]) {
          fetchTrackingDatesAndData(id);
          setCurrentSelectedAssignment(assignments[id]);
        } else {
          setCurrentSelectedAssignment(null);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  }, [id, assignments, isEditMode]);

  useEffect(() => {
    fetchAssignments();
  }, [organization]);

  const next = () => {
    form
      .validateFields()
      .then(() => {
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => console.log(err));
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };
  const allUsers = [...(collaborators ?? []), ...(pendingCollaborators ?? [])];
  const onFinish = async (values) => {
    const assignmentForm = form.getFieldsValue(true);

    try {
      if (isEditMode) {
        await updateDocInFireStore(
          `/organization/${organization}/${COLLECTION_DATA.ASSIGNMENTS}/${id}`,
          assignmentForm,
        );
        api["success"]({
          message: "Assignment Updated Successfully",
        });
      } else {
        const newAssignmentId = await addDocumentWithId(
          `/organization/${organization}/${COLLECTION_DATA.ASSIGNMENTS}`,
          assignmentForm,
        );
        api["success"]({
          message: "Assignment Updated Successfully",
        });

        navigate(`/assignments/${newAssignmentId}`);
      }
      toggleEditMode();
    } catch (err) {
      console.log(err);
      api["error"]({
        message: "Assignment Failed to save",
        description: err.message,
      });
    }
  };

  const assignmentContent = (
    <>
      {contextHolder}
      <Spin spinning={loading}>
        {/* This will also close the sidebar when the overlay is clicked */}
        <SectionContainer
          style={{ justifyContent: "space-evenly", alignItems: "flex-start" }}
        >
          {trackings.length > 0 && (
            <List
              size="small"
              style={{
                border: `1px solid ${color.grayLight}`,
                width: "20%",
              }}
              dataSource={trackings}
              renderItem={(item, index) => (
                <List.Item
                  onClick={() => setActiveTrackingIndex(index)}
                  style={{
                    backgroundColor:
                      activeTrackingIndex === index && color.orange, // Change background color if active,
                    color: activeTrackingIndex === index && color.white,
                    cursor: "pointer", // Change cursor to pointer to indicate clickable
                  }}
                >
                  {item.title}
                </List.Item>
              )}
            />
          )}
          <CardLayoutContainer style={{ width: trackings.length > 0 && "70%" }}>
            <ContentSection
              style={{
                margin: spacing.lg,
              }}
            >
              {activeTrackingIndex > -1 ? (
                <Tracking
                  trackings={trackings}
                  activeTrackingIndex={activeTrackingIndex}
                  setActiveTrackingIndex={setActiveTrackingIndex}
                  allUsers={allUsers}
                />
              ) : (
                <>
                  <Typography.Title level={4}>
                    <SpaceBetweenDiv>
                      {isEditMode ? (disableInputs ? "" : "Edit") : "Add"}{" "}
                      Assignment Form
                      {disableEditing && (
                        <EditFilled
                          onClick={toggleEditMode}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </SpaceBetweenDiv>
                  </Typography.Title>

                  <Typography.Paragraph>
                    Allocate the right conversations to your team,
                    automatically.
                  </Typography.Paragraph>
                  <TitleUnderline />
                  <Steps
                    current={currentStep}
                    items={[{ title: "Settings" }, { title: "Participants" }]}
                    style={{ margin: `${spacing.lg} 0` }}
                  />
                  <Form
                    key={currentSelectedAssignment?.id}
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    disabled={disableInputs}
                    initialValues={currentSelectedAssignment}
                    onValuesChange={(changedValues, allValues) => {
                      // only one should be not null
                      if (changedValues?.assignmentLogic === "revieweeBased")
                        form.setFieldValue("reviewsPerReviewer", null);
                      else if (
                        changedValues?.assignmentLogic === "reviewerBased"
                      )
                        form.setFieldValue("reviewsPerReviewee", null);

                      setCurrentSelectedAssignment((prevAssignment) => ({
                        ...prevAssignment,
                        ...changedValues,
                      }));
                    }}
                  >
                    {currentStep === 0 && (
                      <div>
                        <Form.Item
                          name="title"
                          label="Title"
                          style={{ marginTop: spacing.lg, marginBottom: "0px" }}
                          rules={[
                            {
                              required: true,
                              message: "Please give this assignment a name!",
                            },
                          ]}
                        >
                          <Input
                            id="title"
                            placeholder="Give this assignment a name!"
                            style={{ marginBottom: spacing.md }}
                          />
                        </Form.Item>

                        <Form.Item
                          name="recurrence"
                          label="Recurrence"
                          rules={[
                            {
                              required: true,
                              message: "Please select the recurrence!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select recurrence"
                            options={getOptions(
                              ["Daily", "Weekly", "Monthly"],
                              null,
                              false,
                            )}
                          />
                        </Form.Item>

                        <Form.Item
                          name="evaluations"
                          label="Evaluations"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please select an evaluations you want to include in this assignment!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select evaluations"
                            mode="multiple"
                          >
                            {organization === "ergeon" && (
                              <Option
                                key={"Default"}
                                value={"Default"}
                                label={"Default"}
                              >
                                <div>{"Default"}</div>
                              </Option>
                            )}
                            {evaluationForms &&
                              Object.values(evaluationForms).length > 0 &&
                              Object.values(evaluationForms)
                                .filter((form) => !form.deleted)
                                .map((item, index) => (
                                  <Option
                                    key={index}
                                    value={item.id}
                                    label={item.title}
                                  >
                                    <div>{item.title}</div>
                                  </Option>
                                ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="assignmentLogic"
                          label="Assignment Logic"
                        >
                          <Radio.Group>
                            <Radio value="reviewerBased">
                              Based on reviewers
                            </Radio>
                            <Radio value="revieweeBased">
                              Based on reviewees
                            </Radio>
                          </Radio.Group>
                        </Form.Item>

                        {currentSelectedAssignment?.assignmentLogic ===
                        "revieweeBased" ? (
                          <Form.Item
                            name="reviewsPerReviewee"
                            label="Number of reviews each reviewee will get"
                            rules={[
                              {
                                required: true,
                                message: "Please select distribution!",
                              },
                            ]}
                          >
                            <InputNumber min={1} step={1} defaultValue={20} />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            name="reviewsPerReviewer"
                            label="Number of reviews each reviewer will do in total"
                            rules={[
                              {
                                required: true,
                                message: "Please select distribution!",
                              },
                            ]}
                          >
                            <InputNumber min={1} step={1} defaultValue={20} />
                          </Form.Item>
                        )}

                        <Form.Item
                          name="selfReviews"
                          label="Self Reviews allow users to review themselves"
                        >
                          <Switch
                            checkedChildren={"Enabled"}
                            unCheckedChildren={"Disabled"}
                          />
                        </Form.Item>
                      </div>
                    )}

                    {currentStep === 1 && (
                      <div>
                        <Form.Item
                          name="reviewers"
                          label="Select Reviewers"
                          rules={[
                            {
                              required: true,
                              message: "Please select reviewers!",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select reviewers or 'All Reviewers'"
                          >
                            {[ALL_REVIEWERS, ALL_AGENTS, ...allUsers].map(
                              (user, index) => UserOption(user),
                            )}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="excludeReviewers"
                          label="Exclude Reviewers"
                          rules={[{ required: false }]}
                        >
                          <Select
                            mode="multiple"
                            placeholder={
                              disableInputs ? "" : "Exclude Reviewers"
                            }
                          >
                            {[...allUsers].map((user, index) =>
                              UserOption(user),
                            )}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="reviewees"
                          label="Select Reviewees"
                          rules={[
                            {
                              required: true,
                              message: "Please select reviewees!",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select agents or 'All Agents'"
                          >
                            {[ALL_AGENTS, ...allUsers].map((user, index) =>
                              UserOption(user),
                            )}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="excludeReviewees"
                          label="Exclude Reviewees"
                          rules={[{ required: false }]}
                        >
                          <Select
                            mode="multiple"
                            placeholder={
                              disableInputs ? "" : "Exclude reviewees"
                            }
                          >
                            {[...allUsers].map((user, index) =>
                              UserOption(user),
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    )}

                    <Form.Item>
                      <Space>
                        <Button onClick={prev} disabled={currentStep === 0}>
                          Previous
                        </Button>
                        <Button
                          type="primary"
                          onClick={currentStep === 1 ? form.submit : next}
                          disabled={disableInputs && currentStep !== 0}
                        >
                          {currentStep === 1 ? "Confirm" : "Next"}
                        </Button>
                      </Space>
                    </Form.Item>
                  </Form>
                </>
              )}
            </ContentSection>
          </CardLayoutContainer>
        </SectionContainer>
      </Spin>
    </>
  );

  return <StandardPage content={assignmentContent} />;
};
