import { useState, useRef } from "react";
import { Row, Col, Card, Space, Tag, Tooltip, Typography } from "antd";

import { LoadingOutlined } from "@ant-design/icons";
import {
  checkOrganizationAndScope,
  generateUtteranceTopics,
  getConversationScopes,
  sentimentColor,
} from "../../Dashboard.utils";

import {
  color,
  elementSize,
  assignColorsToValues,
  hexToRgba,
  borderRadius,
} from "src/styles/variables";
import { humanize, formatTime, capitalizeFirstLetter } from "src/utils/utils";
import { useOrganizationState } from "src/state/OrganizationState";
import { BorderlessSelect } from "../../../Profile/Profile.styles";
import { Topic } from "src/utils/types";
import { SENTIMENT, TRANSCRIPT_LABELS } from "src/utils/enums";
import { UtterancesList } from "./UtterancesList";
import { HighlightsIcon } from "../../Dashboard.styles";
import ClipPlayer from "src/components/Playlist/ClipPlayer/ClipPlayer";
import { SHARE_SCOPE } from "../../Dashboard";
import {
  INTERNAL_TEAM_MESSAGE,
  InternalTeamErrorMessage,
} from "src/components/Global/NoPermissions";

export const VideoAudioTranscriptDisplay = ({
  url,
  mimeType,
  fileId,
  utterances,
  holds,
  handleFeedbackUpdate,
  conversationOrganization,
}) => {
  const { organizationSettings, organization } = useOrganizationState();
  const urlPlayerRef = useRef(null);
  const [selectedTranscriptDisplay, setSelectedTranscriptDisplay] = useState(
    TRANSCRIPT_LABELS.TOPICS,
  );
  const [showHighlights, setShowHighlights] = useState(false);
  const [currentUtteranceIndex, setCurrentUtteranceIndex] = useState(null);

  const [currentTopicUtterances, setCurrentTopicUtterances] = useState([]);
  const [currentTopicUtteranceIndex, setCurrentTopicUtteranceIndex] =
    useState(null);
  const conversationScopes = getConversationScopes().scopeDetails;

  const viewTopics = checkOrganizationAndScope(
    conversationOrganization,
    organization,
    conversationScopes,
    SHARE_SCOPE.SHOW_TOPICS,
  );
  const viewTranscripts = checkOrganizationAndScope(
    conversationOrganization,
    organization,
    conversationScopes,
    SHARE_SCOPE.SHOW_TRANSCRIPTS,
  );
  const viewSentiment = checkOrganizationAndScope(
    conversationOrganization,
    organization,
    conversationScopes,
    SHARE_SCOPE.SHOW_SENTIMENTS,
  );
  const viewHighlights = checkOrganizationAndScope(
    conversationOrganization,
    organization,
    conversationScopes,
    SHARE_SCOPE.SHOW_HIGHLIGHTS,
  );
  const [lastClickedTopic, setLastClickedTopic] = useState("");

  const isAudio = mimeType?.includes("audio");

  const handleJumpVideo = (start) => {
    if (!start) return;
    if (!urlPlayerRef?.current) return;
    urlPlayerRef.current.currentTime = start; // Set video/audio's currentTime when an utterance is clicked
  };

  const handleUtteranceClick = (index) => {
    handleJumpVideo(utterances[index]["start"]);
    if (index !== currentUtteranceIndex) {
      setCurrentUtteranceIndex(index);
    }
  };

  const handleTagClick = (topic) => {
    let currentTopicUtterance = null;

    if (topic !== lastClickedTopic) {
      const filteredUtteranceTopics = utteranceTopics.filter(
        (utterance) => utterance.topic === topic,
      );
      setLastClickedTopic(topic);
      setCurrentTopicUtterances(filteredUtteranceTopics);
      setCurrentTopicUtteranceIndex(0);
      currentTopicUtterance = filteredUtteranceTopics?.[0];
      handleUtteranceClick(currentTopicUtterance?.index);
    } else {
      setCurrentTopicUtteranceIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % currentTopicUtterances.length;
        currentTopicUtterance = currentTopicUtterances?.[newIndex];
        handleUtteranceClick(currentTopicUtterance?.index);
        return newIndex;
      });
    }
  };

  const findUtteranceIndexWithTime = (currentTime) => {
    return utterances.findIndex(
      (utterance) =>
        currentTime >= utterance.start && currentTime <= utterance.end,
    );
  };

  const onSetTimestamp = (event) => {
    if (urlPlayerRef.current?.paused) return;
    const currentTime = urlPlayerRef.current?.currentTime;
    const index = findUtteranceIndexWithTime(currentTime);

    if (index >= 0 && index !== currentUtteranceIndex) {
      setCurrentUtteranceIndex(index);

      let currentUtterance = utterances[index];
      // Reset topic clicks
      if (lastClickedTopic && currentTopicUtteranceIndex) {
        let currentTopicUtterance =
          currentTopicUtterances[currentTopicUtteranceIndex];
        if (currentUtterance?.start !== currentTopicUtterance?.start) {
          setCurrentTopicUtteranceIndex(null);
          setCurrentTopicUtterances([]);
          setLastClickedTopic(null);
        }
      }
    }
  };

  const utteranceTopics = generateUtteranceTopics(
    utterances,
    holds,
    findUtteranceIndexWithTime,
  );

  const uniqueTopics = [
    ...new Set(utteranceTopics?.map((topic) => topic?.topic)),
  ];
  const topicColors = assignColorsToValues(uniqueTopics);

  const videoLength = utterances?.[utterances.length - 1]?.end;
  // Function to calculate the width of each segment
  const calculateWidth = (start, end) => ((end - start) / videoLength) * 100;
  const calculateOffset = (start) => (start / videoLength) * 100;

  const getVideoTimeFromEvent = (e) => {
    const { width, left } = e.target.getBoundingClientRect();
    const clickPosition = e.clientX - left;
    const relativeWidth = clickPosition / width;
    return relativeWidth * videoLength;
  };

  const handleTopicDivClick = (e) => {
    handleJumpVideo(getVideoTimeFromEvent(e));
  };
  const [topicTooltip, setTopicTooltip] = useState("");
  const handleMouseMove = (e) => {
    const timestamp = getVideoTimeFromEvent(e);
    setTopicTooltip(timestamp >= 0 ? formatTime(timestamp) : "");
  };

  const chatTranscript = mimeType === "text/plain";

  return (
    <Card
      title={
        showHighlights
          ? "Highlights"
          : chatTranscript
            ? "Conversation"
            : "Recording"
      }
      extra={
        (organizationSettings?.showSentiment ?? true) && (
          <BorderlessSelect
            style={{
              width: 120,
              margin: "0 5px",
            }}
            showarrow={true}
            color={color.orange}
            defaultValue={selectedTranscriptDisplay}
            onChange={(value) => setSelectedTranscriptDisplay(value)}
            options={Object.keys(TRANSCRIPT_LABELS).map((key) => ({
              label: TRANSCRIPT_LABELS[key],
              value: TRANSCRIPT_LABELS[key],
            }))}
          ></BorderlessSelect>
        )
      }
    >
      {!url ? (
        <LoadingOutlined spin />
      ) : showHighlights ? (
        <ClipPlayer
          url={url}
          timeline={utteranceTopics}
          mimeType={mimeType}
          setShowHighlights={setShowHighlights}
          showHighlights={showHighlights}
        />
      ) : (
        <Row gutter={[16, 32]}>
          {!chatTranscript && (
            <Col xl={{ span: isAudio ? 24 : 16 }} xs={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "auto",
                }}
              >
                {url && (
                  <div style={{ position: "relative" }}>
                    {isAudio ? (
                      <audio
                        controls
                        ref={urlPlayerRef}
                        onTimeUpdate={onSetTimestamp}
                      >
                        <source src={url} type={mimeType} />
                      </audio>
                    ) : (
                      <video
                        style={{ maxHeight: "500px", maxWidth: "100%" }}
                        controls
                        ref={urlPlayerRef}
                        onTimeUpdate={onSetTimestamp}
                      >
                        <source src={url} type={mimeType} />
                      </video>
                    )}
                    {viewHighlights && utteranceTopics.length > 0 && (
                      <Tooltip placement="rightTop" title="View Highlights">
                        <HighlightsIcon
                          isAudio={isAudio}
                          onClick={() => setShowHighlights(true)}
                        />
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>

              {selectedTranscriptDisplay === TRANSCRIPT_LABELS.TOPICS &&
                viewTopics &&
                utteranceTopics?.length > 0 && (
                  <Space
                    direction="vertical"
                    style={{ width: "100%", marginTop: elementSize.sm }}
                  >
                    <Tooltip placement="bottomRight" title={topicTooltip}>
                      <div
                        style={{
                          display: "flex",
                          backgroundColor: color.grayLight,
                          height: elementSize.lg,
                        }}
                        onClick={handleTopicDivClick}
                        onMouseMove={handleMouseMove}
                      >
                        {utteranceTopics.map((topic: Topic, index) => (
                          <div
                            key={index}
                            style={{
                              width: `${calculateWidth(topic.start, topic.end)}%`,
                              left: `${calculateOffset(topic.start)}%`,
                              backgroundColor: topicColors[topic?.topic],
                              height: elementSize.lg,
                              position: "absolute",
                            }}
                          />
                        ))}
                        {currentUtteranceIndex !== null && (
                          <hr
                            key="pointer"
                            style={{
                              left: `${calculateOffset(utterances[currentUtteranceIndex]["start"])}%`,
                              height: elementSize.lg,
                              position: "absolute",
                              color: color.orange,
                              margin: "auto",
                              transition: "left 0.5s ease", // Smooth transition for the left property
                            }}
                          />
                        )}
                      </div>
                    </Tooltip>
                    <Space
                      size={[0, 8]}
                      wrap
                      style={{
                        width: "98%",
                      }}
                    >
                      {Object.keys(topicColors).map((topic, index) => (
                        <Tag
                          style={{
                            cursor: "pointer",
                          }}
                          key={`${topic}-${lastClickedTopic}-${currentUtteranceIndex}`}
                          color={topicColors[topic]}
                          onClick={() => {
                            handleTagClick(topic);
                          }}
                        >
                          {humanize(topic)}
                          {topic === lastClickedTopic &&
                            currentTopicUtterances.length > 0 && (
                              <>{` : ${currentTopicUtteranceIndex + 1}/${currentTopicUtterances.length}`}</>
                            )}
                        </Tag>
                      ))}
                    </Space>
                  </Space>
                )}
              {selectedTranscriptDisplay === TRANSCRIPT_LABELS.SENTIMENTS &&
                viewSentiment && (
                  <Space
                    direction="vertical"
                    style={{ width: "100%", marginTop: elementSize.sm }}
                  >
                    <Tooltip placement="bottomRight" title={topicTooltip}>
                      <div
                        style={{
                          display: "flex",
                          backgroundColor: color.grayLight,
                          height: elementSize.lg,
                        }}
                        onClick={handleTopicDivClick}
                        onMouseMove={handleMouseMove}
                      >
                        {utterances
                          .filter((utterance) => utterance.sentiment)
                          .map((utterance, index) => (
                            <div
                              key={index}
                              style={{
                                width: `${calculateWidth(utterance.start, utterance.end)}%`,
                                left: `${calculateOffset(utterance.start)}%`,
                                backgroundColor: sentimentColor(
                                  utterance.sentiment,
                                ),
                                height: elementSize.lg,
                                position: "absolute",
                              }}
                            />
                          ))}

                        {currentUtteranceIndex !== null && (
                          <hr
                            key="pointer"
                            style={{
                              left: `${calculateOffset(utterances[currentUtteranceIndex]["start"])}%`,
                              height: elementSize.lg,
                              position: "absolute",
                              color: color.orange,
                              margin: "auto",
                              transition: "left 0.5s ease", // Smooth transition for the left property
                            }}
                          />
                        )}
                      </div>
                    </Tooltip>
                    <Space size={[0, 8]} wrap>
                      {Object.keys(SENTIMENT).map(
                        (key) =>
                          SENTIMENT[key] !== SENTIMENT.NEUTRAL && (
                            <Tag
                              key={key}
                              color={sentimentColor(SENTIMENT[key])}
                            >
                              {capitalizeFirstLetter(SENTIMENT[key])}
                            </Tag>
                          ),
                      )}
                    </Space>
                  </Space>
                )}
              {!(viewTopics || viewSentiment) && !isAudio && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography.Title level={5}>
                    {selectedTranscriptDisplay}
                  </Typography.Title>
                  <InternalTeamErrorMessage message={INTERNAL_TEAM_MESSAGE} />
                </div>
              )}
            </Col>
          )}

          {viewTranscripts ? (
            <Col xl={{ span: isAudio || chatTranscript ? 24 : 8 }} xs={24}>
              <UtterancesList
                fileId={fileId}
                handleFeedbackUpdate={handleFeedbackUpdate}
                utterances={utterances}
                handleUtteranceClick={handleUtteranceClick}
                currentUtteranceIndex={currentUtteranceIndex}
              />
            </Col>
          ) : (
            <Col
              xl={{ span: isAudio || chatTranscript ? 24 : 8 }}
              xs={24}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                flexDirection: "column",
                backdropFilter: "blur(10px)", // Apply the blur effect
                background: hexToRgba(color.grayLight, 0.5), // Optional: semi-transparent background color
                borderRadius: borderRadius.xs, // Optional: add rounded corners
                paddingBottom: isAudio && elementSize.lg,
              }}
            >
              <Typography.Title level={5}>Transcripts</Typography.Title>
              <InternalTeamErrorMessage message={INTERNAL_TEAM_MESSAGE} />
            </Col>
          )}
        </Row>
      )}
    </Card>
  );
};
