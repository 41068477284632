import { color, elementSize, fontSize, spacing } from "src/styles/variables";
import {
  CenteredDiv,
  GradientOrangeSpan,
} from "../LandingPage/LandingPage.styles";
import { Button, Card, Spin, Typography, notification } from "antd";
import { useState } from "react";
import { MODAL_TYPES, NotificationType } from "src/utils/enums";
import {
  FlexStartCenteredDiv,
  ResponsiveSizedCard,
  StyledAudioCard,
} from "./TrucoSandbox.styles";
import { CaretRightFilled } from "@ant-design/icons";
import { SpaceBetweenDiv } from "../Profile/Profile.styles";
import { useUserState } from "src/state/UserState";
import SettingsSection from "../Evaluation/EvaluationSettings/EvaluationSettings";
import { EvaluationSubTitle } from "../Evaluation/Evaluation.styles";
import {
  handleChangeInEvaluation,
  validateEvaluationFields,
} from "../Evaluation/Evaluation.utils";
import { EvaluationFormData } from "src/utils/types";
import EvaluationCategories from "../Evaluation/EvaluationCategories/EvaluationCategories";
import { DivLink, ErrorMsg, ScrollableDiv } from "src/components";
import { processTrial } from "../DragDropFiles/DragDropFiles.utils";
import { transformToTranscript } from "src/utils/converter";
import { SANDBOX_TABS, SampleEvaluationFile } from "./TrucoSandbox.utils";
import { EvaluationPresetCategoriesModal } from "../Evaluation/EvaluationCategories/EvaluationPresetCategoriesModal";
import { useModal } from "src/state/UseModal";
import { UploadPlayground } from "./UploadPlayground";

export const TrucoSandBox = () => {
  const { openModal } = useModal();
  const [activeTab, setActiveTab] = useState(SANDBOX_TABS.SAMPLE_FILE);
  const [api, contextHolder] = notification.useNotification();
  const { user } = useUserState();
  const [uploadedFile, setUploadFile] = useState([]);
  const [transcript, setTranscript] = useState(null);
  const [loadingRun, setLoadingRun] = useState(false);
  const [TrucoSandBoxEvaluation, setTrucoSandBoxEvaluation] =
    useState<EvaluationFormData>(SampleEvaluationFile);
  const [errors, setErrors] = useState({});
  const [openEvaluationCatgoriesPreset, setOpenEvaluationCatgoriesPreset] =
    useState(false);

  let disableRun =
    (activeTab === SANDBOX_TABS.SAMPLE_FILE && uploadedFile.length === 0) ||
    (activeTab === SANDBOX_TABS.OWN_FILE &&
      (uploadedFile.length === 0 ||
        (uploadedFile.length > 0 && uploadedFile[0].status)));

  let analysisStructure = TrucoSandBoxEvaluation?.analysisStructures;
  const setAnalysisStructures = (newAnalysisStructure) => {
    setTrucoSandBoxEvaluation((prevEvaluation) => ({
      ...prevEvaluation, // Spread operator to copy properties of the object
      analysisStructures: newAnalysisStructure, // Update analysisStructure with the new value
    }));
  };

  const openNotificationWithIcon = (
    type: NotificationType,
    msgTitle: string,
    msgContent: string,
  ) => {
    api[type]({
      message: msgTitle,
      description: msgContent,
    });
  };

  const runProcessTrial = async () => {
    if (
      validateEvaluationFields(
        TrucoSandBoxEvaluation,
        analysisStructure,
        setErrors,
      ) &&
      uploadedFile.length > 0 &&
      user.trialsLeft > 0
    ) {
      setLoadingRun(true);
      try {
        const res: { transcript: any; trialsLeft: number } = await processTrial(
          uploadedFile[0],
          user?.email,
          TrucoSandBoxEvaluation,
        );
        setTranscript(transformToTranscript(res.transcript));
        user.trialsLeft = res.trialsLeft;

        setLoadingRun(false);
      } catch (err) {
        console.log(err.response.data);
        openNotificationWithIcon(
          "error",
          "Transcript failed to generate",
          "Please try again within a few minutes.",
        );
        setLoadingRun(false);
      }
    }
  };

  return (
    <>
      {contextHolder}
      <CenteredDiv style={{ padding: `0px ${spacing.xl}` }}>
        <Card style={{ width: "90%" }}>
          <Typography.Title
            level={1}
            style={{
              fontSize: fontSize.displayXXXLL,
              textAlign: "center",
            }}
          >
            <GradientOrangeSpan>Truco</GradientOrangeSpan> Playground
          </Typography.Title>
          <Typography.Paragraph
            style={{
              textAlign: "center",
              fontSize: fontSize.displayXL,
              width: "max(60%, 200px)",
              margin: "auto",
            }}
          >
            Get a quick overview of our AI-driven evaluations, designed
            specifically for analyzing customer interactions. Truco AI can be
            tailor made for your business. It follows detailed instructions to
            assess conversations and continuously evolves through feedback.{" "}
          </Typography.Paragraph>

          {user?.trialsLeft > 0 ? (
            <UploadPlayground
              uploadedFile={uploadedFile}
              setUploadFile={setUploadFile}
              loadingRun={loadingRun}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setTranscript={setTranscript}
            />
          ) : (
            <>
              <h3>You Ran Out of Free Trials</h3>
              <p>Please wait for your demo to be able to use Truco.</p>
            </>
          )}
        </Card>
      </CenteredDiv>

      <FlexStartCenteredDiv
        style={{
          padding: `${spacing.lg} ${spacing.xl} 0px ${spacing.xl} `,
          gap: elementSize.md,
        }}
      >
        {user?.trialsLeft > 0 && (
          <ResponsiveSizedCard
            title="Evaluation"
            width="65%"
            extra={
              <Button
                type="primary"
                style={{
                  boxShadow: "none",
                  padding: "4px 10px",
                  opacity: `${disableRun || loadingRun ? "0.3" : "1"}`,
                  cursor: `${
                    disableRun || loadingRun ? "not-allowed" : "pointer"
                  }`,
                }}
                loading={loadingRun}
                onClick={runProcessTrial}
              >
                Run <CaretRightFilled />
              </Button>
            }
          >
            <ScrollableDiv>
              <EvaluationSubTitle>Settings</EvaluationSubTitle>
              <SettingsSection
                currentSelectedEvaluation={TrucoSandBoxEvaluation}
                handleChange={handleChangeInEvaluation}
                setCurrentSelectedEvaluation={setTrucoSandBoxEvaluation}
                disabled={loadingRun}
              />
              <SpaceBetweenDiv>
                <EvaluationSubTitle>Categories</EvaluationSubTitle>
                <DivLink
                  style={{
                    color: color.orange,
                    textDecoration: "underline",
                    cursor: loadingRun && "not-allowed",
                  }}
                  onClick={() =>
                    !loadingRun && setOpenEvaluationCatgoriesPreset(true)
                  }
                >
                  View Category Presets
                </DivLink>
              </SpaceBetweenDiv>
              <EvaluationCategories
                analysisStructure={analysisStructure}
                setAnalysisStructures={setAnalysisStructures}
                setCurrentSelectedEvaluation={setTrucoSandBoxEvaluation}
                disableInputs={loadingRun}
                errors={errors}
                setErrors={setErrors}
              />

              {Object.keys(errors)?.length > 0 && (
                <ErrorMsg>* Required fields are missing</ErrorMsg>
              )}
            </ScrollableDiv>
          </ResponsiveSizedCard>
        )}

        {user?.trialsLeft === 0 && !transcript ? (
          <></>
        ) : (
          <>
            <ResponsiveSizedCard
              title="Transcript"
              width={user?.trialsLeft > 0 ? "24%" : "90%"}
            >
              <Spin spinning={loadingRun}>
                {transcript && uploadedFile.length > 0 && (
                  <StyledAudioCard
                    style={{
                      width: "100%",
                    }}
                    id={transcript.id}
                    onClick={() => {
                      openModal(
                        MODAL_TYPES.CONVERSATION,
                        transcript.id,
                        transcript,
                      );
                    }}
                  >
                    <p>{transcript.file_name}</p>
                  </StyledAudioCard>
                )}
              </Spin>
            </ResponsiveSizedCard>
            {openEvaluationCatgoriesPreset && (
              <EvaluationPresetCategoriesModal
                openModal={openEvaluationCatgoriesPreset}
                setOpenModal={setOpenEvaluationCatgoriesPreset}
                analysisStructure={analysisStructure}
                setAnalysisStructures={setAnalysisStructures}
              />
            )}
          </>
        )}
      </FlexStartCenteredDiv>
    </>
  );
};
