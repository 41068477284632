import React, { useEffect, useState } from "react";
import { useOrganizationState } from "src/state/OrganizationState";
import { Row, Col, Spin, Tooltip, Dropdown, Button, Tabs } from "antd";
import {
  PlaylistCardContainer,
  PlaylistDescription,
  PlaylistTitle,
} from "./Playlist.styles";
import { MoreOutlined } from "@ant-design/icons";
import { SpaceBetweenDiv } from "../Profile/Profile.styles";
import { grayBoxShadow } from "src/styles/variables";
import { DeskTopOnly } from "src/styles/stylingComponents";
import { NewPlaylistModal } from "./AddPlaylist";
import {
  generatePlaylistShareableLink,
  getUserPlaylistsFromFireStore,
  handleCreatePlaylist,
} from "./Playlist.utils";
import { formatDateToHumanReadable } from "src/utils/utils";
import { PlayList } from "src/utils/types";
import { useNavigate } from "react-router-dom";
import { useUserState } from "src/state/UserState";
import { PlaylistMenu } from "./PlaylistDetails/PlaylistMenu";
import PlaylistTypeFilter from "./PlaylistFilter";
import { SignalCard, PlaylistImageCard } from "./SignalCard";

interface PlaylistCardProps {
  playlist: PlayList;
  setLoading: (loading: boolean) => void;
}
const PlaylistCard: React.FC<PlaylistCardProps> = ({
  playlist,
  setLoading,
}) => {
  const { organization } = useOrganizationState();
  const navigate = useNavigate();

  return (
    <PlaylistCardContainer>
      <PlaylistImageCard playlist={playlist} />
      <SpaceBetweenDiv>
        <Tooltip title={playlist.title}>
          <PlaylistTitle
            onClick={() =>
              navigate(
                `${generatePlaylistShareableLink(organization, playlist.id)}`,
              )
            }
          >
            {playlist.title}
          </PlaylistTitle>
        </Tooltip>
        <Dropdown
          overlay={
            <PlaylistMenu
              playlistId={playlist.id}
              setLoading={setLoading}
              redirectToPlaylist={false}
              linkToCopy={`${window.location.origin}${generatePlaylistShareableLink(organization, playlist.id)}`}
            />
          }
          trigger={["click"]}
          overlayStyle={{ boxShadow: grayBoxShadow }}
        >
          <MoreOutlined style={{ cursor: "pointer", fontSize: "16px" }} />
        </Dropdown>
      </SpaceBetweenDiv>
      <PlaylistDescription
        onClick={() => {
          navigate(
            `${generatePlaylistShareableLink(organization, playlist.id)}`,
          );
        }}
      >
        Updated {formatDateToHumanReadable(playlist.updatedAt)}
      </PlaylistDescription>
    </PlaylistCardContainer>
  );
};

export const Playlist = () => {
  const { organization, playlists, setPlaylists } = useOrganizationState();
  const { user } = useUserState();
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [filteredPlaylists, setFilteredPlaylists] = useState<PlayList[]>(
    playlists?.data || [],
  );
  const loadMore = async () => {
    if (!playlists.lastDoc) return;
    setLoading(true);
    try {
      const morePlaylistsResult = await getUserPlaylistsFromFireStore(
        organization,
        user,
        30,
        playlists.lastDoc, // Use lastDoc as the starting point for the next set of documents
      );
      const morePlaylists = morePlaylistsResult.data || [];
      setPlaylists((prevState) => {
        const newPlaylistData =
          morePlaylists.length > 0
            ? [...(prevState.data || []), ...morePlaylists]
            : morePlaylists;

        return {
          ...prevState,
          data: newPlaylistData,
          hasMore: morePlaylists.length >= 30,
          lastDoc: morePlaylistsResult.lastDoc || null,
        };
      });
    } catch (error) {
      console.error("Error fetching more playlists:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchPlayLists = async () => {
      setLoading(true);
      const docs = await getUserPlaylistsFromFireStore(organization, user, 30);
      setPlaylists((prevState) => {
        return {
          ...prevState,
          data: docs?.data?.length > 0 ? docs.data : [],
          hasMore: docs?.data?.length >= 30,
          lastDoc: docs?.lastDoc || null,
        };
      });
      setLoading(false);
    };
    if (!playlists?.data) fetchPlayLists();
  }, [organization]);

  const tabItems = [
    {
      key: "1",
      label: "Signal Playlists",
      children: (
        <Row gutter={[16, 16]}>
          <SignalCard
            playlists={playlists?.data}
            setLoading={setLoading}
            spanValue={15}
          />
        </Row>
      ),
    },
    {
      key: "2",
      label: "All Playlists",
      children: (
        <Row gutter={[16, 16]}>
          {filteredPlaylists?.map((playlist) => (
            <Col key={playlist.id} xs={12} sm={12} md={8} lg={6} xl={4}>
              <PlaylistCard playlist={playlist} setLoading={setLoading} />
            </Col>
          ))}
          {playlists?.hasMore && (
            <div style={{ textAlign: "center", marginTop: "16px" }}>
              <Button onClick={loadMore} loading={loading}>
                Load More
              </Button>
            </div>
          )}
        </Row>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Tabs
        defaultActiveKey="1"
        items={tabItems}
        tabBarStyle={{ paddingLeft: "12px", paddingRight: "12px" }} // Add padding to the tab navigation
        tabBarExtraContent={{
          right: (
            <SpaceBetweenDiv style={{ padding: "0px 12px" }}>
              <div style={{ display: "flex", gap: "12px" }}>
                <PlaylistTypeFilter
                  playlists={playlists?.data || []}
                  onFilteredPlaylistsChange={setFilteredPlaylists}
                />
                <Button type="primary" onClick={() => setAddModalVisible(true)}>
                  + <DeskTopOnly>New Playlist</DeskTopOnly>
                </Button>
              </div>
            </SpaceBetweenDiv>
          ),
        }}
      />
      <NewPlaylistModal
        visible={addModalVisible}
        onCreate={handleCreatePlaylist}
        setAddModalVisible={setAddModalVisible}
      />
    </Spin>
  );
};
