import React from "react";
import { useUserState } from "src/state/UserState";
import { UserOption } from "src/components";
import { StandardPage } from "../Home";
import { SectionContainer } from "../LandingPage/LandingPage.styles";
import {
  Form,
  Select,
  Button,
  Radio,
  Input,
  InputNumber,
  notification,
  Typography,
  Steps,
  Spin,
  Space,
  Table,
  Tooltip,
  Tag,
  List,
} from "antd";

import { color, spacing } from "src/styles/variables";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SpaceBetweenDiv } from "../Profile/Profile.styles";
import { useOrganizationState } from "src/state/OrganizationState";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import {
  CardLayoutContainer,
  ContentSection,
  TitleUnderline,
} from "../Evaluation/Evaluation.styles";

import { ACCESS_TYPE } from "src/utils/enums";
import {
  getRandomConversations,
  handleCalibration,
  updateCalibrationToDb,
} from "./Calibrations.utils";
import { Calibration } from "src/utils/types";

import { CalibrationTracking } from "./CalibrationTracking";
import useFetchCalibrations from "src/hooks/useFetchCalibrations";

const ALL_REVIEWERS = { name: "All Reviewers", email: "reviewers" };

export const Calibrations = () => {
  const {
    organization,
    evaluationForms,
    collaborators,
    pendingCollaborators,
    transcripts,
  } = useOrganizationState();
  const { user } = useUserState();
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = id != null; // true if 'id' is present in the URL
  const [disableEditing, setDisableEditing] = useState(isEditMode);
  const hasAccess =
    user.accessType === ACCESS_TYPE.owner ||
    user.accessType === ACCESS_TYPE.trucoAdmin;
  const [disableInputs, setdisableInputs] = useState(
    !hasAccess || disableEditing,
  );

  const [loading, setLoading] = useState(false);
  const [activeConversation, setActiveConversation] = useState(null);

  const [selectedReviewers, setSelectedReviewers] = useState([]);
  const { calibrations, fetchCalibrations } = useFetchCalibrations();

  const handleReviewersChange = (value) => {
    setSelectedReviewers(value);
  };

  const [currentSelectedCalibration, setCurrentSelectedCalibration] =
    useState<Calibration>(() => {
      return isEditMode && calibrations && calibrations[id]
        ? calibrations[id]
        : { isDone: false };
    });
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const { Option } = Select;

  const toggleEditMode = () => {
    const editValue = !disableEditing;
    setdisableInputs(!hasAccess || editValue);
    setDisableEditing(editValue);
  };

  useEffect(() => {
    if (isEditMode) {
      if (!calibrations) {
        setLoading(true);
      } else {
        if (calibrations[id]) {
          setCurrentSelectedCalibration(calibrations[id]);
        } else {
          setCurrentSelectedCalibration(null);
        }
        setLoading(false);
      }
    }
  }, [id, calibrations, isEditMode]);

  useEffect(() => {
    fetchCalibrations();
  }, [organization, id]);

  const allUsers = [
    ...(collaborators ?? []),
    ...(pendingCollaborators ?? []),
  ].filter((user) =>
    [ACCESS_TYPE.reviewer, ACCESS_TYPE.owner, ACCESS_TYPE.trucoAdmin].includes(
      user?.accessType,
    ),
  );

  const validateExcludeReviewers = (_, value) => {
    if (!value || value.length === 0) {
      return Promise.resolve(); // Allow the field to be empty
    }
    const invalidUsers = value.filter((user) =>
      selectedReviewers.includes(user),
    );
    if (invalidUsers.length > 0) {
      return Promise.reject(
        new Error(
          "Some excluded reviewers are also selected as reviewers. Please change your selection.",
        ),
      );
    }
    return Promise.resolve();
  };
  const evaluationTranscripts =
    currentSelectedCalibration?.evaluations &&
    transcripts?.filter((transcript) => {
      const evaluationId = transcript?.evaluation_form?.id ?? "Default";
      return currentSelectedCalibration?.evaluations?.includes(evaluationId);
    });

  const availableTranscripts = evaluationTranscripts?.filter(
    (transcript) => !transcript.calibration && transcript.analysis,
  );
  const tableData = isEditMode
    ? currentSelectedCalibration?.conversations
    : evaluationTranscripts;

  const onFinish = async (values) => {
    setLoading(true);
    const calibrationForm = form.getFieldsValue(true);
    if (calibrationForm.assignmentLogic === "random") {
      calibrationForm.conversations = getRandomConversations(
        calibrationForm.numConversations,
        availableTranscripts,
      );
    }
    await handleCalibration(
      api,
      isEditMode,
      organization,
      id,
      calibrationForm,
      navigate,
      toggleEditMode,
    );
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  const next = () => {
    form
      .validateFields()
      .then(() => {
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => console.log(err));
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const columns = [
    {
      title: "ConversationID",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "Evaluation",
      dataIndex: "evaluation_form",
      key: "evaluation_form",
      render: (evaluation_form) => evaluation_form?.title,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const calibrationContent = (
    <>
      {contextHolder}
      <Spin spinning={loading}>
        {/* This will also close the sidebar when the overlay is clicked */}
        <SectionContainer
          style={{ justifyContent: "space-evenly", alignItems: "flex-start" }}
        >
          {isEditMode &&
            currentSelectedCalibration?.conversations?.length > 0 && (
              <List
                size="small"
                style={{
                  border: `1px solid ${color.grayLight}`,
                  width: "20%",
                }}
                dataSource={currentSelectedCalibration?.conversations}
                renderItem={(item, index) => (
                  <List.Item
                    onClick={() => {
                      setLoading(true);
                      setActiveConversation(item);
                      setTimeout(() => {
                        setLoading(false);
                      }, 1000);
                    }}
                    style={{
                      backgroundColor:
                        activeConversation?.id === item.id && color.orange, // Change background color if active,
                      color: activeConversation?.id === item.id && color.white,
                      cursor: "pointer", // Change cursor to pointer to indicate clickable
                    }}
                  >
                    {item.file_name}
                  </List.Item>
                )}
              />
            )}
          <CardLayoutContainer
            style={{
              width: isEditMode && "70%",
            }}
          >
            <ContentSection
              style={{
                margin: spacing.lg,
              }}
            >
              {activeConversation ? (
                <CalibrationTracking
                  activeConversation={activeConversation}
                  setActiveConversation={setActiveConversation}
                  calibration={currentSelectedCalibration}
                  allUsers={allUsers}
                />
              ) : (
                <>
                  <Typography.Title level={4}>
                    <SpaceBetweenDiv>
                      {isEditMode ? (disableInputs ? "" : "Edit") : "Add"}{" "}
                      Calibration Form
                      {/* {disableEditing && (
                    <EditFilled
                      onClick={toggleEditMode}
                      style={{ cursor: "pointer" }}
                    />
                  )} */}
                    </SpaceBetweenDiv>
                  </Typography.Title>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: spacing.s,
                    }}
                  >
                    {isEditMode && (
                      <Tag
                        color={
                          currentSelectedCalibration?.isDone
                            ? color.orange
                            : color.lightGreen
                        }
                        style={{ alignSelf: "center" }}
                      >
                        {currentSelectedCalibration?.isDone
                          ? "Closed"
                          : "Active"}
                      </Tag>
                    )}
                    <Typography.Paragraph
                      style={{ alignSelf: "center", margin: 0 }}
                    >
                      Align rating techniques across reviewers, to provide
                      consistent feedback to agents.
                    </Typography.Paragraph>
                  </div>
                  <TitleUnderline />
                  <Steps
                    current={currentStep}
                    items={[{ title: "Settings" }, { title: "Conversations" }]}
                    style={{ margin: `${spacing.lg} 0` }}
                  />
                  <Form
                    key={currentSelectedCalibration?.id}
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    disabled={disableInputs}
                    initialValues={currentSelectedCalibration}
                    onValuesChange={(changedValues, allValues) => {
                      setCurrentSelectedCalibration((prevCalibration) => ({
                        ...prevCalibration,
                        ...changedValues,
                      }));
                    }}
                  >
                    {currentStep === 0 && (
                      <div>
                        <Form.Item
                          name="title"
                          label="Title"
                          style={{ marginTop: spacing.lg, marginBottom: "0px" }}
                          rules={[
                            {
                              required: true,
                              message: "Please give this calibration a name!",
                            },
                          ]}
                        >
                          <Input
                            id="title"
                            placeholder="Give this calibration a name!"
                            style={{ marginBottom: spacing.md }}
                          />
                        </Form.Item>

                        <Form.Item
                          name="evaluations"
                          label="Evaluations"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please select an evaluations you want to include in this assignment!",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select evaluations"
                            mode="multiple"
                          >
                            {organization === "ergeon" && (
                              <Option
                                key={"Default"}
                                value={"Default"}
                                label={"Default"}
                              >
                                <div>{"Default"}</div>
                              </Option>
                            )}
                            {evaluationForms &&
                              Object.values(evaluationForms).length > 0 &&
                              Object.values(evaluationForms)
                                .filter((form) => !form.deleted)
                                .map((item, index) => (
                                  <Option
                                    key={index}
                                    value={item.id}
                                    label={item.title}
                                  >
                                    <div>{item.title}</div>
                                  </Option>
                                ))}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          name="reviewers"
                          label="Select Reviewers"
                          rules={[
                            {
                              required: true,
                              message: "Please select reviewers!",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select reviewers or 'All Reviewers'"
                            onChange={handleReviewersChange}
                          >
                            {[ALL_REVIEWERS, ...allUsers].map((user) =>
                              UserOption(user),
                            )}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="excludeReviewers"
                          label="Exclude Reviewers"
                          rules={[{ validator: validateExcludeReviewers }]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Exclude Reviewers"
                          >
                            {allUsers.map((user) => UserOption(user))}
                          </Select>
                        </Form.Item>
                      </div>
                    )}

                    {currentStep === 1 && (
                      <div>
                        <Tooltip
                          title={
                            availableTranscripts?.length < 1
                              ? "No transcripts available with this evaluation form."
                              : ""
                          }
                        >
                          <Form.Item
                            name="assignmentLogic"
                            label="Assignment Logic"
                            rules={[
                              {
                                required: true,
                                message: "Please select assignment method!",
                              },
                            ]}
                          >
                            <Radio.Group
                              disabled={
                                isEditMode || availableTranscripts.length < 1
                              }
                            >
                              <Radio value="manual">
                                Manually Select Conversations
                              </Radio>
                              <Radio value="random">
                                Randomly Select Conversations
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Tooltip>

                        {currentSelectedCalibration?.assignmentLogic ===
                          "manual" && (
                          <Form.Item
                            name="conversations"
                            label="Select conversations for calibration"
                            rules={[
                              {
                                required: true,
                                message: "Please select conversations!",
                              },
                            ]}
                          >
                            <Table
                              rowKey="id"
                              rowClassName={(record) =>
                                record.calibration ? "disabled-row" : ""
                              }
                              rowSelection={
                                isEditMode
                                  ? null // will display the records of the shown transcripts only
                                  : {
                                      type: "checkbox",
                                      selectedRowKeys: Array.isArray(
                                        currentSelectedCalibration?.conversations,
                                      )
                                        ? currentSelectedCalibration?.conversations.map(
                                            (conv) => conv?.id,
                                          )
                                        : [],

                                      onChange: (
                                        selectedKeys,
                                        selectedRows,
                                      ) => {
                                        const formattedRows = selectedRows.map(
                                          (row) => ({
                                            id: row.id,
                                            file_name: row.file_name,
                                            created_at: row.created_at,
                                            evaluation_form: {
                                              ...row.evaluation_form,
                                              id:
                                                row.evaluation_form?.id ?? null,
                                            },
                                          }),
                                        );
                                        form.setFieldValue(
                                          "conversations",
                                          formattedRows,
                                        );
                                        setCurrentSelectedCalibration(
                                          (prevCalibration) => ({
                                            ...prevCalibration,
                                            conversations: formattedRows,
                                          }),
                                        );
                                      },
                                      getCheckboxProps: (record) => ({
                                        disabled: !!record.calibration, // Disable checkbox if the record has .calibration
                                      }),
                                      renderCell: (
                                        checked,
                                        record,
                                        index,
                                        originNode,
                                      ) =>
                                        record.calibration ? (
                                          <Tooltip title="Disabled as it is assigned to a calibration">
                                            <span>{originNode}</span>
                                          </Tooltip>
                                        ) : (
                                          originNode
                                        ),
                                    }
                              }
                              columns={columns}
                              dataSource={tableData}
                              pagination={
                                tableData.length > 5
                                  ? {
                                      pageSize: 5,
                                      position: ["topRight"],
                                      showSizeChanger: false,
                                    }
                                  : false
                              }
                            />
                          </Form.Item>
                        )}
                        {currentSelectedCalibration?.assignmentLogic ===
                          "random" && (
                          <Form.Item
                            name="numConversations"
                            label="Great! How many conversations should we pick for you?"
                            rules={[
                              {
                                required: true,
                                message: "Please select distribution!",
                              },
                            ]}
                            initialValue={2} // Ensure this matches the initial value
                          >
                            <InputNumber
                              min={1}
                              step={1}
                              max={availableTranscripts.length}
                            />
                          </Form.Item>
                        )}
                      </div>
                    )}
                    <Form.Item>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Space>
                          <Button onClick={prev} disabled={currentStep === 0}>
                            Previous
                          </Button>
                          <Button
                            type="primary"
                            onClick={
                              currentStep === 1 && !isEditMode
                                ? form.submit
                                : next
                            }
                            disabled={isEditMode && currentStep !== 0}
                          >
                            {currentStep === 1 && !isEditMode
                              ? "Confirm"
                              : "Next"}
                          </Button>
                        </Space>

                        {isEditMode && !currentSelectedCalibration?.isDone && (
                          <Button
                            icon={
                              <CheckCircleOutlined
                                style={{ color: color.green }}
                              />
                            }
                            onClick={async () => {
                              setLoading(true);
                              await updateCalibrationToDb(
                                organization,
                                currentSelectedCalibration,
                                api,
                                {
                                  isDone: true,
                                },
                              );
                              setTimeout(() => {
                                setLoading(false);
                              }, 2000);
                            }}
                            style={{ marginLeft: "auto" }}
                            disabled={false}
                          >
                            Mark as Done
                          </Button>
                        )}
                      </div>
                    </Form.Item>
                  </Form>
                </>
              )}
            </ContentSection>
          </CardLayoutContainer>
        </SectionContainer>
      </Spin>
    </>
  );

  return <StandardPage content={calibrationContent} />;
};
