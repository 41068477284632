import React from "react";
import styled from "styled-components";
import {
  color,
  fontSize,
  spacing,
  maxWidth,
  hexToRgba,
} from "src/styles/variables";

const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px ${spacing.xl} ${spacing.sm};
  max-width: ${maxWidth};
  margin: auto;
  border-top: 1px solid ${hexToRgba(color.white, 0.2)};
  > div > p {
    color: ${color.white};
    size: ${fontSize.caption};
    margin: 0;
    text-align: center;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    > div {
      margin-top: ${spacing.lg};
    }
  }
`;

const FooterList = styled.ul`
  margin: 0 auto 0 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;

  @media (max-width: 900px) {
    margin: auto;
    padding: 0;
  }
`;

const FooterListItem = styled.li`
  margin: 0 15px;
  padding-top: ${spacing.xs};
  > a {
    text-decoration: none;
    color: ${color.white};
    transition: color 0.3s;
    font-size: ${fontSize.bodyLarge};

    &:hover {
      color: ${color.orange};
    }
  }
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterList>
        <FooterListItem>
          <a href="mailto:swetha@truco.ai">Contact Us</a>
        </FooterListItem>
        <FooterListItem>
          <a href="/privacy">Privacy Policy</a>
        </FooterListItem>
        <FooterListItem>
          <a href="/signin">Sign In</a>
        </FooterListItem>
      </FooterList>

      <div>
        <p> All rights reserved. </p>
        <p>© 2023 Truco Ventures, Inc.</p>
      </div>
    </FooterContainer>
  );
};
