import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";

import {
  buildGoogleCalendarOAuthUrl,
  buildSlackOAuthUrl,
  buildZoomOAuthUrl,
  buildOutlookCalendarOAuthUrl,
  buildHubspotUrl,
  ConnectOpenPhone,
  buildSalesforceUrl,
} from "./Integrations.utils";
import { spacing, color } from "src/styles/variables";
import { useUserState } from "src/state/UserState";
import { useOrganizationState } from "src/state/OrganizationState";
import axios from "axios";
import { IntgerationDescription } from "../../Profile.styles";
import { ProfileTabs, ProfileDocs } from "../../Profile.enums";
import { ACCESS_TYPE } from "src/utils/enums";
import {
  Integration,
  INTEGRATION_SCOPE,
  INTEGRATION_ICONS,
} from "./Integrations.variables";

export const IntegrationsCard = () => {
  const { organization, organizationSettings, setOrganizationSettings } =
    useOrganizationState();
  const { user, setUser } = useUserState();
  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [salesforceUrl, setSalesforceUrl] = useState("");

  useEffect(() => {
    async function fetchSalesforceUrl() {
      const url = await buildSalesforceUrl(user?.email, organization);
      setSalesforceUrl(url);
    }

    fetchSalesforceUrl();
  }, [user?.email, organization]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const integrations: Integration[] = [
    {
      icon: INTEGRATION_ICONS.slack,
      name: "Slack Alerts",
      description:
        "Create and receive feedback in Slack directly. Share weekly summaries with the team.",
      switchValue: !!organizationSettings?.slack,
      locationHref: buildSlackOAuthUrl(organization),
      revoke: {
        url: "/slack/oauth_revoke",
        successMsg: "Slack alerts successfully revoked.",
        errorMsg: "Error revoking Slack!",
      },
      fireBaseFieldId: "slack",
      // setter: setUser, now it should be per organization
      setter: setOrganizationSettings,
      scope: INTEGRATION_SCOPE.organizational,
    },
    {
      icon: INTEGRATION_ICONS.googleCalendar,
      name: "Google Calendar",
      description:
        "Automatically send Truco's Notetaker to meetings on your Google Calendar.",
      switchValue: !!user?.googleCalendar,
      locationHref: buildGoogleCalendarOAuthUrl(user?.email, organization),
      revoke: {
        url: "/google_calendar/oauth_revoke",
        successMsg: "Google Calendar successfully revoked.",
        errorMsg: "Error revoking Google Calendar!",
      },
      fireBaseFieldId: "googleCalendar",
      setter: setUser,
      scope: INTEGRATION_SCOPE.user,
    },
    {
      icon: INTEGRATION_ICONS.zoom,
      name: "Zoom ",
      description: "Ingest customer conversations from Zoom.",
      switchValue: !!user?.zoom,
      locationHref: buildZoomOAuthUrl(user?.email, organization),
      revoke: {
        url: "/zoom/oauth_revoke",
        successMsg: "Zoom successfully revoked.",
        errorMsg: "Error revoking Zoom!",
      },
      fireBaseFieldId: "zoom",
      setter: setUser,
      scope: INTEGRATION_SCOPE.user,
    },
    {
      icon: INTEGRATION_ICONS.outlookCalendar,
      name: "Outlook Calendar ",
      description:
        "Automatically send Truco's Notetaker to meetings on your Outlook Calendar.",
      switchValue: !!user?.outlookCalendar,
      locationHref: buildOutlookCalendarOAuthUrl(user?.email, organization),
      revoke: {
        url: "/outlook_calendar/oauth_revoke",
        successMsg: "Outlook Calendar successfully revoked.",
        errorMsg: "Error revoking Outlook Calendar!",
      },
      fireBaseFieldId: "outlookCalendar",
      setter: setUser,
      scope: INTEGRATION_SCOPE.user,
    },
    organization == "wbCTEqEqKpd5oiQLFn8H" && {
      icon: INTEGRATION_ICONS.salesForce,
      name: "Salesforce",
      description:
        "Sync data from your conversations directly to their relevant contact record within Salesforce.",
      switchValue: !!organizationSettings?.salesforce,
      locationHref: salesforceUrl,
      revoke: {
        url: "/salesforce/oauth_revoke",
        successMsg: "salesforce successfully revoked.",
        errorMsg: "Error salesForce!",
      },
      fireBaseFieldId: "salesforce",
      setter: setOrganizationSettings,
      scope: INTEGRATION_SCOPE.organizational,
    },
    {
      icon: INTEGRATION_ICONS.openphone,
      name: "OpenPhone ",
      description:
        "Automatically upload call recordings and contacts to Truco.",
      switchValue: !!organizationSettings?.openphone,
      connectComponent: (
        <ConnectOpenPhone
          visible={isModalVisible}
          onClose={handleClose}
          queryPath={`organization/${organization}`}
        />
      ),
      revoke: {
        url: "/openphone/oauth_revoke",
        successMsg: "OpenPhone successfully revoked.",
        errorMsg: "Error revoking OpenPhone!",
      },
      fireBaseFieldId: "openphone",
      setter: setOrganizationSettings,
      scope: INTEGRATION_SCOPE.organizational,
    },
    {
      icon: INTEGRATION_ICONS.hubspot,
      name: "HubSpot ",
      description:
        "Sync data from your conversations directly to their relevant contact record within HubSpot.",
      switchValue: !!organizationSettings?.hubspot,
      locationHref: buildHubspotUrl(user?.email, organization),
      revoke: {
        url: "/hubspot/oauth_revoke",
        successMsg: "HubSpot successfully revoked.",
        errorMsg: "Error revoking HubSpot!",
      },
      fireBaseFieldId: "hubspot",
      setter: setOrganizationSettings,
      scope: INTEGRATION_SCOPE.organizational,
    },
    // {
    //   icon: INTEGRATION_ICONS.talkdesk,
    //   name: "Talkdesk ",
    //   description: "Ingest customer conversations from Talkdesk.",
    //   switchValue: !!organizationSettings?.talkdesk,
    //   locationHref: "https://appconnect.talkdesk.com/apps/truco-dev",
    //   revoke: {
    //     errorMsg: "Talkdesk can only be revoked on the Talkdesk AppConnect!",
    //   },
    //   fireBaseFieldId: "talkdesk",
    //   setter: setOrganizationSettings,
    //    scope: INTEGRATION_SCOPE.organizational,
    // },
  ];

  const handleIntegrationClick = (integration) => {
    const newValue = integration?.switchValue;
    if (!newValue && integration.locationHref) {
      console.log(`Redirecting to ${integration.name} to authorize app!`);
      window.location.href = integration.locationHref;
    } else if (!newValue) {
      console.log("setting modal visible");
      showModal();
    } else {
      if (!integration.revoke?.url) {
        api.error({
          message: integration.revoke.errorMsg,
          duration: 2,
        });
      } else {
        const data =
          integration.scope === INTEGRATION_SCOPE.user
            ? { user: user?.email }
            : { organization: organization };

        axios
          .post(
            process.env.REACT_APP_TRUCO_BACKEND + integration.revoke.url,
            data,
          )
          .then((response) => {
            if (response.data.message) {
              api.success({
                message: "Revoked!",
                description: integration.revoke.successMsg,
                duration: 2,
              });
            } else if (response.data.error) {
              api.error({
                message: integration.revoke.errorMsg,
                description: response.data.error,
                duration: 2,
              });
            }
            integration.setter((prevValue) => {
              return {
                ...prevValue,
                [`${integration.fireBaseFieldId}`]: null,
              };
            });
          })
          .catch((error) => {
            api.error({
              message: integration.revoke.errorMsg,
              description: error.message,
              duration: 2,
            });
          });
      }
    }
  };

  const IntegrationCard = ({ integration }) => (
    <Col xs={24} sm={12} md={12} lg={8} key={integration.name}>
      <Card>
        <Space direction="vertical" size="large">
          <Space direction="horizontal">
            <Typography.Title
              level={4}
              className="integration-title"
              style={{ display: "flex", alignItems: "center" }}
            >
              {integration.icon}
              <span style={{ paddingLeft: spacing.xs }}>
                {integration.name}
              </span>
            </Typography.Title>
          </Space>

          <Tooltip title={integration.description}>
            <IntgerationDescription>
              {integration.description}
            </IntgerationDescription>
          </Tooltip>
          <Button
            onClick={() => handleIntegrationClick(integration)}
            type={integration.switchValue ? "primary" : "default"}
          >
            {integration.switchValue ? "✓ Enabled" : "Connect"}
          </Button>
        </Space>
      </Card>
    </Col>
  );

  const isAdmin =
    user.accessType === ACCESS_TYPE.owner ||
    user.accessType === ACCESS_TYPE.trucoAdmin;

  return (
    <>
      {contextHolder}
      <Card
        title={
          <Space>
            {`User ${ProfileTabs.INTEGRATIONS}`}
            <InfoCircleTwoTone
              twoToneColor={color.orange}
              onClick={() => window.open(ProfileDocs.INTEGRATIONS, "_blank")}
            />
          </Space>
        }
      >
        <Row gutter={[16, 16]}>
          {integrations
            .filter((integration) => integration.scope === "user")
            .map((integration) => (
              <>
                <IntegrationCard
                  integration={integration}
                  key={integration.name}
                />
                {integration?.connectComponent && integration?.connectComponent}
              </>
            ))}
        </Row>
      </Card>
      {isAdmin && (
        <Card
          title={
            <Space>
              {`Organizational ${ProfileTabs.INTEGRATIONS}`}
              <InfoCircleTwoTone
                twoToneColor={color.orange}
                onClick={() => window.open(ProfileDocs.INTEGRATIONS, "_blank")}
              />
            </Space>
          }
        >
          <Row gutter={[16, 16]}>
            {integrations
              .filter((integration) => integration.scope === "organizational")
              .map((integration) => (
                <>
                  <IntegrationCard
                    integration={integration}
                    key={integration.name}
                  />
                  {integration?.connectComponent &&
                    integration?.connectComponent}
                </>
              ))}
          </Row>
        </Card>
      )}
    </>
  );
};
