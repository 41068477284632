import React from "react";
import { Select } from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import UnsubscribeManager from "src/utils/UnsubscribeManager";
import { CollectAllExistingOrganizations } from "src/hooks/CollectOrganizationData";

export const OrganizationSelect = () => {
  const {
    organizationSettings,
    setOrganization,
    setCustomers,
    setTranscripts,
    setInsights,
    setCollaborators,
    setOrganizationSettings,
    setPendingCollaborators,
    setEvaluationForms,
    setPlaylists,
    allExistingOrganizations,
  } = useOrganizationState();

  if (!allExistingOrganizations) {
    CollectAllExistingOrganizations();
  }

  const options = allExistingOrganizations?.map((existingOrgs) => ({
    label: existingOrgs.name,
    value: existingOrgs.id,
  }));

  return (
    <Select
      placeholder={organizationSettings?.name}
      style={{ width: 150 }}
      onChange={(selectedOption) => {
        UnsubscribeManager.getInstance().clearUnsubs(); // Clear all subscriptions
        setTranscripts(null);
        setCustomers({ data: null, hasMore: false, lastDoc: null });
        setCollaborators(null);
        setPendingCollaborators(null);
        setEvaluationForms(null);
        setOrganizationSettings(null);
        setInsights(null);
        setPlaylists(null);
        setOrganization(selectedOption);
      }}
      options={options}
    />
  );
};
