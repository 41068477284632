import React from "react";
import { Modal, Breadcrumb } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useModal } from "src/state/UseModal";
import { ConversationModalContent } from "../Dashboard/ConversationModal/ConversationModalContent";
import { MODAL_TYPES } from "src/utils/enums";
import { HomeOutlined } from "@ant-design/icons";
import { CustomerModalContent } from "../CustomersDashboard/CustomerModalContent";
import { UserModalContent } from "../AgentsDashboard/UserModalContent";
import ClipDetailsModalContent from "../Playlist/PlaylistDetails/ClipDetailsModalContent";

const ModalComponents = {
  [MODAL_TYPES.CUSTOMER]: CustomerModalContent,
  [MODAL_TYPES.USER]: UserModalContent,
  [MODAL_TYPES.CONVERSATION]: ConversationModalContent,
  [MODAL_TYPES.CLIP]: ClipDetailsModalContent,
};

const ModalManager = () => {
  const {
    modalState,
    closeModal,
    breadcrumbs,
    customBreadcrumb,
    setCustomBreadcrumb,
  } = useModal();

  const navigate = useNavigate();

  if (!modalState.isOpen) return null;

  const ModalContent =
    ModalComponents[modalState.modalType] || (() => "No content available");

  return (
    <Modal
      title={
        <Breadcrumb style={{ cursor: "pointer" }}>
          <Breadcrumb.Item>
            <Link to="#" onClick={closeModal}>
              <HomeOutlined />
            </Link>
          </Breadcrumb.Item>
          {breadcrumbs.map((crumb, index) => (
            <Breadcrumb.Item
              key={index}
              onClick={() => {
                navigate(crumb.path);
                setCustomBreadcrumb(null);
              }}
            >
              <span> {crumb.label}</span>
            </Breadcrumb.Item>
          ))}
          {customBreadcrumb && (
            <Breadcrumb.Item>{customBreadcrumb.label}</Breadcrumb.Item>
          )}
        </Breadcrumb>
      }
      open={modalState.isOpen}
      onCancel={closeModal}
      closeIcon={null}
      width={"98vw"}
      footer={null}
    >
      <div
        style={{
          minHeight: "40vh",
        }}
      >
        <ModalContent />
      </div>
    </Modal>
  );
};

export default ModalManager;
