import React, { useState, useEffect } from "react";
import { Drawer, Space } from "antd";
import { SettingOutlined, FilterOutlined } from "@ant-design/icons";
import DropdownWithPillButton from "../Filters/DropdownWithPillButton";
import { PLAYLIST_TYPE } from "src/utils/enums"; // Adjust the path as needed
import { StyledFilterButton } from "../Dashboard/Dashboard.styles";

const PlaylistTypeFilter = ({ onFilteredPlaylistsChange, playlists }) => {
  const [selectedType, setSelectedType] = useState<string[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState(false);

  useEffect(() => {
    if (!playlists) return;
    let filtered = playlists;
    if (selectedType.length === 0 || selectedType.includes("All")) {
      filtered = playlists;
    } else {
      filtered = playlists.filter((playlist) =>
        selectedType.includes(playlist.type),
      );
    }
    setFiltersApplied(selectedType.length > 0);
    onFilteredPlaylistsChange(filtered);
  }, [selectedType, playlists]);

  const handleTypeChange = (selectedOption: string[]) => {
    setSelectedType(selectedOption);
  };

  const renderFilters = () => (
    <DropdownWithPillButton
      icon={<SettingOutlined />}
      text={"Playlist Type"}
      multiple
      selectedKeys={selectedType}
      options={[
        { label: "All", value: "All" },
        ...Object.values(PLAYLIST_TYPE).map((type) => ({
          label: type.charAt(0).toUpperCase() + type.slice(1),
          value: type,
        })),
      ]}
      onSelectionChange={handleTypeChange}
    />
  );

  return (
    <div>
      <StyledFilterButton
        filtersapplied={filtersApplied}
        icon={<FilterOutlined />}
        onClick={() => {
          setShowFilter(!showFilter);
        }}
      />
      {showFilter && (
        <>
          <Drawer
            title="Filters"
            placement="right"
            onClose={() => setShowFilter(false)}
            open={showFilter}
          >
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex" }}
            >
              {renderFilters()}
            </Space>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default PlaylistTypeFilter;
