import React, { useEffect, useState } from "react";
import { useUserState } from "src/state/UserState";
import { COLLECTION_DATA, CONVERSATION_ACCESS } from "src/utils/enums";
import AddToPlaylistContent from "./Playlist/AddToPlaylistContent";
import { useOrganizationState } from "src/state/OrganizationState";
import { transcriptConverter } from "src/utils/converter";
import { getUsersEmailDictionary } from "src/utils/utils";
import { getRecordFromFireStore } from "src/firebaseAuth";
import { useModal } from "src/state/UseModal";
import { ConversationModalBody } from "./ConversationModalBody/ConversationModalBody";
import ShareConversation from "./ShareConversation/ShareConversation";
import { getConversationScopes } from "../Dashboard.utils";
import { NoPermissions } from "src/components/Global";

export enum CONVERSATION_SUBMODAL {
  CONVERSATION = "CONVERSATION",
  ADD_TO_PLAYLIST = "ADD_TO_PLAYLIST",
  SHARE = "SHARE",
}

export const ConversationModalContent = () => {
  const { user } = useUserState();
  const {
    organization,
    transcripts,
    evaluationForms,
    collaborators,
    pendingCollaborators,
  } = useOrganizationState();
  const { modalState, closeModal, setCustomBreadcrumb, customBreadcrumb } =
    useModal();
  const [accessDenied, setAccessDenied] = useState(false);

  const [currentSubModal, setCurrentSubModal] = useState<CONVERSATION_SUBMODAL>(
    CONVERSATION_SUBMODAL.CONVERSATION,
  );

  const transcriptId = modalState.id;

  const [modalData, setModalData] = useState(modalState.customData || null);
  const conversationScopes = getConversationScopes();

  const modelClose = () => {
    setCurrentSubModal(CONVERSATION_SUBMODAL.CONVERSATION);
    closeModal();
  };
  const fetchTranscripts = async () => {
    let fetchedOrganization =
      conversationScopes?.decodedOrganization || organization;
    const fetchedTranscript = await getRecordFromFireStore(
      `organization/${fetchedOrganization}/${COLLECTION_DATA.CONVERSATIONS}/${transcriptId}`,
      transcriptConverter(
        user,
        evaluationForms,
        getUsersEmailDictionary(collaborators, pendingCollaborators),
      ),
    );
    setModalData({
      ...fetchedTranscript,
      id: transcriptId,
    });
    if (
      (fetchedTranscript &&
        fetchedTranscript.visibility !== CONVERSATION_ACCESS.PUBLIC &&
        fetchedTranscript.organizationId !== organization) ||
      !fetchedTranscript
    ) {
      setAccessDenied(true);
    }
  };

  useEffect(() => {
    if (transcriptId && !modalData) {
      const existingTranscript =
        modalState.id &&
        transcripts?.find((t) => t?.id === modalState.id.toString());
      if (existingTranscript) {
        setModalData(existingTranscript);
      } else {
        fetchTranscripts();
      }
    }
  }, [
    modalData,
    transcriptId,
    organization,
    evaluationForms,
    collaborators,
    pendingCollaborators,
  ]);

  useEffect(() => {
    switch (currentSubModal) {
      case CONVERSATION_SUBMODAL.ADD_TO_PLAYLIST:
        setCustomBreadcrumb({
          label: "Add to Playlist",
        });
        break;
      case CONVERSATION_SUBMODAL.SHARE:
        setCustomBreadcrumb({
          label: "Share",
        });
        break;

      case CONVERSATION_SUBMODAL.CONVERSATION:
      default:
        setCustomBreadcrumb(null);
        break;
    }
  }, [currentSubModal, transcriptId]);

  useEffect(() => {
    if (!customBreadcrumb) {
      setCurrentSubModal(CONVERSATION_SUBMODAL.CONVERSATION);
    }
  }, [customBreadcrumb]);

  if (accessDenied)
    return (
      <NoPermissions
        description={"You do not have access to view this conversation."}
      />
    );

  return (
    <>
      {modalData && (
        <>
          {currentSubModal === CONVERSATION_SUBMODAL.CONVERSATION && (
            <ConversationModalBody
              modalData={modalData}
              setModalData={setModalData}
              handleClose={modelClose}
              setCurrentSubModal={setCurrentSubModal}
            />
          )}
          {currentSubModal === CONVERSATION_SUBMODAL.ADD_TO_PLAYLIST && (
            <AddToPlaylistContent
              modalData={modalData}
              onClose={() =>
                setCurrentSubModal(CONVERSATION_SUBMODAL.CONVERSATION)
              }
            />
          )}
          {currentSubModal === CONVERSATION_SUBMODAL.SHARE && (
            <ShareConversation modalData={modalData} />
          )}
        </>
      )}
    </>
  );
};
