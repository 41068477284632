import React, { useEffect, useState } from "react";
import { Badge, Tooltip, Col, Typography, Card, Divider } from "antd";
import { Chart } from "react-google-charts";
import {
  EditIconContainer,
  PlaylistImage,
  PlaylistImageContainer,
  PlaylistVideoThumbnail,
  PlaylistCardContainer,
} from "./Playlist.styles";
import { EditOutlined, EyeOutlined, WifiOutlined } from "@ant-design/icons";
import { useOrganizationState } from "src/state/OrganizationState";
import { spacing, color } from "src/styles/variables";
import {
  generatePlaylistShareableLink,
  getThumbnailSource,
  hasEditAccess,
  hasPlaylistAccess,
} from "./Playlist.utils";
import { useNavigate } from "react-router-dom";
import { useUserState } from "src/state/UserState";
import { PLAYLIST_TYPE } from "src/utils/enums";

export const PlaylistImageCard = ({ playlist }) => {
  const { organization } = useOrganizationState();
  const { user } = useUserState();
  const navigate = useNavigate();
  const [loadingVideoThumbnail, setLoadingVideoThumbnail] = useState(true);
  const [thumbnail, setThumbnail] = useState<{ type: string; src: string }>({
    type: "image",
    src: "/playlist.jpeg",
  });
  const [thumbnailFetched, setThumbnailFetched] = useState(false);

  const hasAccess = hasPlaylistAccess(user, organization, playlist);
  const clipsWithAccess = playlist?.clips?.filter(
    (clip) =>
      (playlist.type === PLAYLIST_TYPE.SIGNAL &&
        clip.participants?.includes(user?.email)) ||
      hasAccess,
  );

  useEffect(() => {
    const fetchThumbnail = async () => {
      const thumbnailSource = await getThumbnailSource(playlist);
      setThumbnail(thumbnailSource);
      setThumbnailFetched(true);
    };
    fetchThumbnail();
  }, [playlist]);

  useEffect(() => {
    if (thumbnailFetched) {
      setLoadingVideoThumbnail(false);
    }
  }, [thumbnailFetched]);

  return (
    <Badge.Ribbon text={`${clipsWithAccess?.length} clips`}>
      <PlaylistImageContainer
        onClick={() =>
          navigate(
            `${generatePlaylistShareableLink(organization, playlist.id)}`,
          )
        }
      >
        {thumbnail.type.includes("video") ? (
          <PlaylistVideoThumbnail
            src={thumbnail.src}
            onLoadedData={() => setLoadingVideoThumbnail(false)}
            style={{ display: loadingVideoThumbnail ? "none" : "block" }}
          />
        ) : (
          <PlaylistImage alt="playlist" src={thumbnail.src} />
        )}

        <EditIconContainer>
          {playlist.type !== PLAYLIST_TYPE.SIGNAL && (
            <>
              {hasEditAccess(playlist, user) ? (
                <EditOutlined
                  style={{
                    color: color.white,
                  }}
                />
              ) : (
                <EyeOutlined
                  style={{
                    color: color.white,
                  }}
                />
              )}
            </>
          )}
          {playlist.type === PLAYLIST_TYPE.SIGNAL && (
            <Tooltip title="Signal Playlist">
              <WifiOutlined
                style={{
                  color: color.white,
                }}
              />
            </Tooltip>
          )}
        </EditIconContainer>
      </PlaylistImageContainer>
    </Badge.Ribbon>
  );
};

export const SignalCard = ({
  playlists,
  setLoading,
  spanValue = 24,
  height = 50,
}) => {
  const navigate = useNavigate();
  const { organization } = useOrganizationState();
  const currentMonth = new Date().getMonth();
  const lastMonth = currentMonth - 1;

  const signalPlaylists = playlists?.filter(
    (playlist) => playlist?.type === "signal",
  );

  return (
    <Col xl={{ span: spanValue }} md={24}>
      <Card key={"Signals"} title={""}>
        {signalPlaylists?.map((playlist, index) => {
          const currentMonthClips = playlist.clips.filter(
            (clip) => new Date(clip.updatedAt).getMonth() === currentMonth,
          ).length;
          const lastMonthClips = playlist.clips.filter(
            (clip) => new Date(clip.updatedAt).getMonth() === lastMonth,
          ).length;
          const percentageChange =
            lastMonthClips > 0
              ? Math.round(
                  ((currentMonthClips - lastMonthClips) / lastMonthClips) * 100,
                )
              : 0;

          const data = playlist.clips
            .map((clip) => [
              new Date(clip.updatedAt).toISOString().split("T")[0], // Extract the date part only (YYYY-MM-DD)
              1,
            ])
            .reduce((acc, [date, count]) => {
              acc[date] = (acc[date] || 0) + count; // Aggregate count by date
              return acc;
            }, {});

          const aggregatedData = Object.entries(data).map(([date, count]) => [
            date,
            count,
          ]);

          if (!aggregatedData?.length) return <></>;

          const chartData = [["Date", "Clips"], ...aggregatedData];

          const options = {
            legend: { position: "none" },
            colors: [color.orange],
            curveType: "function",
            hAxis: {
              title: "",
              textPosition: "none",
              gridlines: { color: "transparent" },
              baselineColor: "transparent",
            },
            vAxis: {
              title: "",
              textPosition: "none",
              gridlines: { color: "transparent" },
              baselineColor: "transparent",
            },
            chartArea: { width: "90%", height: "90%" },
            lineWidth: 2,
            tooltip: { trigger: "both" },
            crosshair: { trigger: "both", orientation: "vertical" },
            enableInteractivity: true,
          };

          return (
            <div key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: "1 1 40%" }}>
                  <PlaylistCardContainer
                    onClick={() =>
                      navigate(
                        `${generatePlaylistShareableLink(organization, playlist.id)}`,
                      )
                    }
                  >
                    <Typography.Title
                      level={5}
                      style={{ marginTop: spacing.xs }}
                    >
                      {playlist?.title?.toUpperCase()}
                    </Typography.Title>

                    <PlaylistImageCard playlist={playlist} />
                  </PlaylistCardContainer>
                </div>
                <div style={{ flex: "1 1 50%", marginLeft: "16px" }}>
                  <Chart
                    width="100%"
                    height={height}
                    chartType="LineChart"
                    loader={<div>Loading Chart...</div>}
                    data={chartData}
                    options={options}
                  />
                </div>
                <div
                  style={{
                    flex: "1 1 30%",
                    textAlign: "right",
                    paddingLeft: "16px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px", // Increase font size
                      fontWeight: "bold",
                    }}
                  >
                    <Typography.Text type={"secondary"}>
                      {currentMonthClips} {" Clips this month"} <br />
                    </Typography.Text>

                    <Typography.Text
                      type={
                        percentageChange > 0
                          ? "success"
                          : percentageChange < 0
                            ? "danger"
                            : undefined
                      }
                    >
                      {percentageChange > 0 ? "↑" : "↓"}{" "}
                      {Math.abs(percentageChange)}% since last month
                    </Typography.Text>
                  </div>
                </div>
              </div>
              {index < signalPlaylists.length - 1 && <Divider />}
            </div>
          );
        })}
      </Card>
    </Col>
  );
};
