import React, { useState } from "react";
import { Button, Select, Spin } from "antd";
import { color, elementSize } from "src/styles/variables";
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { InvitedUser } from "src/utils/types";
import { useOrganizationState } from "src/state/OrganizationState";
import { ACCESS_TYPE, COLLECTION_DATA } from "src/utils/enums";
import { humanize, isValidEmail } from "src/utils/utils";
import { addDocumentWithId } from "src/firebaseAuth";
import { checkUserAndInvitations } from "src/utils/apiCalls";

import { StyledInput } from "src/components/Profile/Profile.styles";
import { UserSelectComponent } from "./AnalysisContent.styles";
import { updateNumberOfSeats } from "src/utils/organization";
const { Option } = Select;

export function UserOption(user) {
  return (
    <Option
      value={user.email}
      label={user.name}
      key={user.email}
      data-access-type={user.accessType} // Pass accessType as data attribute
      data-photoURL={user.photoURL} // Pass accessType as data attribute
    >
      <div>{user.name}</div>
      <div style={{ fontSize: "smaller", color: "gray" }}>{user.email}</div>
    </Option>
  );
}

export const UserSelect = ({ defaultUser, handleUser, disabled = false }) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultUser?.name);
  const {
    organization,
    collaborators,
    pendingCollaborators,
    setOrganizationSettings,
  } = useOrganizationState();

  const [mergedUsers, setMergedUsers] = useState([]);
  const [isAddingUser, setIsAddingUser] = useState(false); // New state to toggle the form
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserAccessType, setNewUserAccessType] = useState(ACCESS_TYPE.agent);
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [loadingNewAgent, setLoadingNewAgent] = useState(false);

  const resetAddAgentValues = () => {
    setNewUserName("");
    setNewUserEmail("");
    setIsAddingUser(false);
    setEmailError("");
    setNameError("");
  };
  const handleAddNewUser = async () => {
    setEmailError("");
    setNameError("");
    let formIsValid = true;
    if (!isValidEmail(newUserEmail)) {
      setEmailError("Please enter a valid email address.");
      formIsValid = false;
    }
    if (!newUserName.trim()) {
      setNameError("Name is required.");
      formIsValid = false;
    }
    if (!formIsValid) {
      return;
    }
    const userExistError = await checkUserAndInvitations(
      newUserEmail,
      organization,
    );
    if (userExistError) {
      setEmailError(userExistError);
      // Handle errors (e.g., setting state to display errors in UI)
      return;
    }

    setLoadingNewAgent(true);
    const invitedUser: InvitedUser = {
      email: newUserEmail,
      accessType: newUserAccessType,
      organization: organization,
      pendingInvitation: true,
      name: newUserName,
      sendInvitation: false,
    };
    await addDocumentWithId(`/${COLLECTION_DATA.INVITATIONS}`, invitedUser);

    await updateNumberOfSeats(
      `/${COLLECTION_DATA.ORGANIZATIONS}/${organization}`,
      1,
      setOrganizationSettings,
    );

    setOpen(false);
    setLoadingNewAgent(false);
    handleUser(invitedUser, defaultUser);
    setSelectedValue(newUserName);
    resetAddAgentValues();
  };

  return disabled ? (
    selectedValue
  ) : (
    <UserSelectComponent
      // key={`user-select-${userType}`}
      showSearch
      style={{
        boxShadow: "none",
        backgroundColor: "transparent",
      }}
      placeholder="Select a user"
      optionFilterProp="children"
      filterOption={(input, option: { value: string; label: string }) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      optionLabelProp="label"
      dropdownStyle={{ overflow: "auto" }}
      popupMatchSelectWidth={false}
      labelInValue
      value={selectedValue}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={(selectedUser, option) => {
        let accessType = option["data-access-type"];
        if (
          accessType === ACCESS_TYPE.owner ||
          accessType === ACCESS_TYPE.trucoAdmin
        ) {
          accessType = ACCESS_TYPE.reviewer; // Use your enum or constant for 'Reviewer'
        }
        setSelectedValue(selectedUser.value); // Update local state
        handleUser(
          {
            name: selectedUser.label,
            email: selectedUser.value,
            conversationRole: accessType,
            photoURL: option["data-photoURL"],
          },
          defaultUser,
        );
      }}
      onDropdownVisibleChange={(visible) => {
        setOpen(visible);
      }}
      open={open}
      disabled={disabled}
      dropdownRender={(menu) => (
        <>
          {isAddingUser ? (
            <Spin spinning={loadingNewAgent}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <StyledInput
                  placeholder="Agent Email"
                  value={newUserEmail}
                  type="email"
                  style={{
                    margin: elementSize.xs,
                    width: "90%",
                    borderColor: emailError ? color.red : undefined,
                  }}
                  onChange={(e) => {
                    setNewUserEmail(e.target.value);
                    setEmailError("");
                  }}
                />
                {emailError && (
                  <div
                    style={{
                      margin: `0px 0px 0px ${elementSize.xs}`,
                      color: color.red,
                      fontSize: "0.75rem",
                    }}
                  >
                    {emailError}
                  </div>
                )}

                <StyledInput
                  placeholder="Agent Name"
                  value={newUserName}
                  style={{
                    margin: elementSize.xs,
                    width: "90%",
                    borderColor: nameError ? color.red : undefined,
                  }}
                  onChange={(e) => {
                    setNewUserName(e.target.value);
                    setNameError("");
                  }}
                />

                {nameError && (
                  <div
                    style={{
                      margin: `0px 0px ${elementSize.xs} ${elementSize.xs}`,
                      color: color.red,
                      fontSize: "0.75rem",
                    }}
                  >
                    {nameError}
                  </div>
                )}
                <Select
                  style={{
                    margin: elementSize.xs,
                    width: "90%",
                  }}
                  defaultValue={newUserAccessType}
                  options={[
                    ACCESS_TYPE.agent,
                    ACCESS_TYPE.lead,
                    ACCESS_TYPE.reviewer,
                  ].map((type) => ({ value: type, label: humanize(type) }))}
                  onChange={(value) => {
                    setNewUserAccessType(value);
                  }}
                />

                <Button
                  style={{
                    width: "90%",
                    margin: `${elementSize.sm} ${elementSize.xs} ${elementSize.ml} ${elementSize.xs}`,
                  }}
                  onClick={() => handleAddNewUser()}
                >
                  Add
                </Button>
              </div>
            </Spin>
          ) : (
            <> {menu}</>
          )}
          <div
            style={{
              borderTop: `1px solid ${color.grayMedium}`,
            }}
          >
            <Button
              style={{
                border: "none",
                boxShadow: "none",
                width: "100%",
              }}
              icon={isAddingUser ? <ArrowLeftOutlined /> : <PlusOutlined />}
              onClick={() => {
                resetAddAgentValues();
                setIsAddingUser(!isAddingUser);
              }}
            >
              {isAddingUser ? "Back" : "Add new user"}
            </Button>
          </div>
        </>
      )}
    >
      {[...(collaborators ?? []), ...(pendingCollaborators ?? [])].map(
        (user, index) => UserOption(user),
      )}
      {mergedUsers.map((user) => UserOption(user))}
    </UserSelectComponent>
  );
};
