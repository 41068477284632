import { useCallback, useEffect, useState } from "react";
import {
  addDocumentWithId,
  getRecordFromFireStore,
  updateDocInFireStore,
  db,
} from "src/firebaseAuth";
import { useOrganizationState } from "src/state/OrganizationState";
import { playlistConverter } from "src/utils/converter";
import {
  COLLECTION_DATA,
  PLAYLIST_ACCESS,
  PLAYLIST_TYPE,
  ACCESS_TYPE,
} from "src/utils/enums";
import { PlayList } from "src/utils/types";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import {
  LockOutlined,
  ApartmentOutlined,
  GlobalOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useUserState } from "src/state/UserState";
import { fetchFileUrl } from "../Dashboard/Dashboard.utils";
import {
  and,
  arrayUnion,
  collection,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { encryptId } from "src/utils/utils";

export const getVisibilityIcon = (visibility, style = {}) => {
  const icons = {
    [PLAYLIST_ACCESS.PRIVATE]: <LockOutlined style={style} />,
    [PLAYLIST_ACCESS.ORGANIZATION]: <ApartmentOutlined style={style} />,
    [PLAYLIST_ACCESS.PUBLIC]: <GlobalOutlined style={style} />,
    [PLAYLIST_ACCESS.CUSTOM]: <UserAddOutlined style={style} />,
  };

  return icons[visibility];
};

export const usePlaylists = () => {
  const { organization, playlists, setPlaylists } = useOrganizationState();
  const { user } = useUserState();
  const [loading, setLoading] = useState(false);

  const fetchAndSetPlaylists = useCallback(
    async (lastDoc) => {
      if (!organization) return;
      setLoading(true);

      try {
        const results = await getUserPlaylistsFromFireStore(
          organization,
          user,
          30,
          lastDoc,
        );
        const newPlaylists = results?.data || [];

        setPlaylists((prevState) => ({
          ...prevState,
          data: lastDoc ? [...prevState.data, ...newPlaylists] : newPlaylists,
          hasMore: newPlaylists.length >= 30,
          lastDoc: results?.lastDoc || null,
        }));
      } catch (error) {
        console.error("Error fetching playlists: ", error);
      } finally {
        setLoading(false);
      }
    },
    [organization, setPlaylists],
  );

  const loadMore = () => {
    if (playlists.lastDoc && playlists.hasMore) {
      fetchAndSetPlaylists(playlists.lastDoc);
    }
  };

  useEffect(() => {
    if (!playlists?.data?.length) {
      fetchAndSetPlaylists(null);
    }
  }, [organization, playlists?.data?.length, fetchAndSetPlaylists]);

  return { playlists, loading, loadMore };
};

export const addPlaylistToTranscript = async (
  organization: string,
  conversationId: string,
  newPlaylist: { id: string; title: string },
) => {
  const queryPath = `/organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${conversationId}`;
  try {
    await updateDocInFireStore(queryPath, {
      playlists: arrayUnion(newPlaylist),
    });
  } catch (error) {
    console.error("Error updating playlist in Firestore: ", error);
  }
};

export const addClipToPlaylist = (
  organization,
  playlistId: string,
  clip: any,
  playlists,
  setPlaylists,
) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const playlistData = playlists?.data.find(
        (playlist) => playlist.id === playlistId,
      );
      const queryPath = `/organization/${organization}/playlists/${playlistId}`;

      const newOrder = playlistData.clips ? playlistData.clips.length + 1 : 1;
      const uniqueId = uuidv4();
      const newClip = { ...clip, order: newOrder, id: uniqueId };

      const updatedClips = [...(playlistData.clips || []), newClip];

      // Update the document with the new clips array
      await updateDocInFireStore(queryPath, {
        clips: updatedClips,
      });
      // add the playlistId to the transcript as well
      await addPlaylistToTranscript(organization, clip.conversationId, {
        id: playlistId,
        title: playlistData.title,
      });

      setPlaylists((prevPlaylists) => {
        const updatedPlaylists = prevPlaylists.data?.map((playlist) => {
          if (playlist.id === playlistId) {
            return { ...playlist, clips: updatedClips };
          }
          return playlist;
        });

        return { ...prevPlaylists, data: updatedPlaylists };
      });

      message.success("Clip added successfully to the playlist");
      resolve();
    } catch (error) {
      console.error("Error adding clip to playlist: ", error);
      message.error("Failed to add clip to playlist. Please try again.");
      reject(error);
    }
  });
};

export const handleCreatePlaylist = (
  title,
  image,
  organization,
  user,
  setPlaylists,
  description?,
  type = PLAYLIST_TYPE.STANDARD,
  visibility = PLAYLIST_ACCESS.PRIVATE,
) => {
  return new Promise<string | void>(async (resolve, reject) => {
    try {
      const newPlaylist: PlayList = {
        createdBy: user,
        deleted: false,
        img: image,
        title: title,
        updatedAt: new Date().toISOString(),
        clips: [],
        description: description || null,
        visibility: visibility,
        editability: PLAYLIST_ACCESS.PRIVATE,
        customList: [],
        type: type,
      };

      const newPlaylistId = await addDocumentWithId(
        `/organization/${organization}/${COLLECTION_DATA.PLAYLISTS}`,
        newPlaylist,
      );

      if (newPlaylistId) {
        setPlaylists((prevPlaylists) => {
          const isSignalType = newPlaylist.type === PLAYLIST_TYPE.SIGNAL;
          const hasPlaylists = (prevPlaylists?.data || []).length > 0;

          // Only set the playlist if it's not of type 'signal' or there are existing playlists
          if (!isSignalType || hasPlaylists) {
            return {
              ...prevPlaylists,
              data: [
                { ...newPlaylist, id: newPlaylistId },
                ...(prevPlaylists?.data || []),
              ],
            };
          }

          // Return previous state if conditions are not met
          return prevPlaylists;
        });

        type !== PLAYLIST_TYPE.SIGNAL &&
          message.success("Playlist added successfully");
        resolve(newPlaylistId); // Return the ID on resolve
      } else {
        throw new Error("Failed to create playlist");
      }
    } catch (error) {
      console.error("Error creating playlist: ", error);
      type !== PLAYLIST_TYPE.SIGNAL &&
        message.error("Failed to create playlist. Please try again.");
      reject(error);
    }
  });
};

export const handleDeletePlaylist = async (
  playlistId,
  organization,
  setPlaylists,
  setLoading?,
  navigate?,
) => {
  setLoading && setLoading(true);
  try {
    await updateDocInFireStore(
      `/organization/${organization}/${COLLECTION_DATA.PLAYLISTS}/${playlistId}`,
      { deleted: true },
    );
    setPlaylists((prevPlaylists) => {
      const newData = (prevPlaylists.data || []).filter(
        (playlist) => playlist.id !== playlistId,
      );
      return {
        ...prevPlaylists,
        data: newData,
      };
    });

    message.success("Playlist deleted successfully");
    if (navigate) {
      navigate(`/playlist`);
    }
  } catch (error) {
    message.error("Failed to delete playlist. Please try again.");
    console.error("Error deleting playlist: ", error);
  } finally {
    setLoading && setLoading(false);
  }
};

export const getThumbnailSource = async (playlist) => {
  if (playlist.img) {
    return { type: "image", src: playlist.img };
  }
  const videoClip = playlist.clips?.find((clip) =>
    clip.mimeType.includes("video"),
  );
  if (videoClip) {
    if (videoClip.url) {
      return {
        type: videoClip.mimeType,
        src: `${videoClip.url}#t=${Math.floor(videoClip.start + 2)}`,
        // src: `${videoClip.url}#t=${Math.floor(
        //   (videoClip.end - videoClip.start) / 2,
        // )}`,
      };
    }
    try {
      const url = await fetchFileUrl(videoClip.conversationId);
      videoClip.url = url;
      return {
        type: videoClip.mimeType,
        src: `${url}#t=${Math.floor(videoClip.start + 2)}`,
        // src: `${url}#t=${Math.floor((videoClip.end - videoClip.start) / 2)}`,
      };
    } catch (error) {
      console.error("Error fetching file URL: ", error);
      return { type: "image", src: "/playlist.jpeg" };
    }
  }
  return { type: "image", src: "/playlist.jpeg" };
};

export const fetchClipUrl = async (clip) => {
  if (clip.url) {
    return clip;
  }
  try {
    const url = await fetchFileUrl(clip.conversationId);
    return { ...clip, url };
  } catch (error) {
    console.error(`Error fetching URL for clip ${clip.conversationId}:`, error);
    return clip;
  }
};

export const fetchAllClipUrls = async (clips) => {
  const batchSize = 5;
  const batches = [];

  for (let i = 0; i < clips.length; i += batchSize) {
    const batch = clips.slice(i, i + batchSize).map(fetchClipUrl);
    batches.push(Promise.all(batch));
  }

  const results = await Promise.all(batches);
  return results.flat();
};
export const getUserPlaylistsFromFireStore = async (
  organizationId,
  user,
  limitDocs = null,
  startAfterDoc = null, // Add this parameter to handle pagination
) => {
  try {
    const collectionPath = `organization/${organizationId}/${COLLECTION_DATA.PLAYLISTS}`;
    let collectionRef = collection(db, collectionPath).withConverter(
      playlistConverter,
    );
    const baseConditions = and(
      where("deleted", "==", false),
      or(
        where("createdBy", "==", user.email),
        where("visibility", "in", [
          PLAYLIST_ACCESS.PUBLIC,
          PLAYLIST_ACCESS.ORGANIZATION,
        ]),
      ),
    );
    let docQuery = query(
      collectionRef,
      baseConditions,
      orderBy("updatedAt", "desc"),
    );

    if (limitDocs) {
      docQuery = query(docQuery, limit(limitDocs));
    }
    if (startAfterDoc) {
      docQuery = query(docQuery, startAfter(startAfterDoc));
    }
    const documentSnapshots = await getDocs(docQuery);
    const data = documentSnapshots.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    return {
      data,
      lastDoc: documentSnapshots.docs[documentSnapshots.docs.length - 1],
    };
  } catch (error) {
    console.error("Error fetching playlists:", error);
    return {
      data: [],
      lastDoc: null,
    };
  }
};

export const generatePlaylistShareableLink = (organizationId, playlistId) => {
  const encryptedOrganizationId = encryptId(organizationId);
  const encryptedPlaylistId = encryptId(playlistId);
  return `/playlist/${encryptedOrganizationId}/${encryptedPlaylistId}`;
};

export const getClipInfoFromPlaylist = async (
  playlistId,
  clipId,
  organization,
) => {
  try {
    let foundClip = null;
    let playlistUser;

    const playlist = await getRecordFromFireStore(
      `organization/${organization}/playlists/${playlistId}`,
      null,
      playlistConverter,
    );
    const clip = playlist?.clips.find((c) => c.id === clipId);
    if (clip) {
      playlistUser = playlist?.createdBy;
      foundClip = { ...clip, playlistId };
    }
    return { foundClip, playlistUser };
  } catch (error) {
    console.error("Error fetching playlists:", error);
    return null;
  }
};

export const hasEditAccess = (playlist, user) => {
  const isCreator = playlist?.createdBy === user?.email;
  const isOrgAccessible = playlist.editability === PLAYLIST_ACCESS.ORGANIZATION;
  const isPrivateAccessible =
    playlist.editability === PLAYLIST_ACCESS.PRIVATE && isCreator;
  const isCustomAccessible =
    playlist.editability === PLAYLIST_ACCESS.CUSTOM &&
    playlist.customList?.includes(user.email);

  return (
    isCreator || isOrgAccessible || isPrivateAccessible || isCustomAccessible
  );
};

export const filterPlaylists = (data, user) => {
  if (!data) return [];

  return data.filter(
    (playlist) =>
      hasEditAccess(playlist, user) && playlist.type !== PLAYLIST_TYPE.SIGNAL,
  );
};

export const generateAccessOptions = (organizationName) => {
  const generalAccessOptions = [
    {
      value: PLAYLIST_ACCESS.PRIVATE,
      label: "Only You",
      icon: PLAYLIST_ACCESS.PRIVATE,
    },
    {
      value: PLAYLIST_ACCESS.ORGANIZATION,
      label: organizationName,
      icon: PLAYLIST_ACCESS.ORGANIZATION,
    },
    {
      value: PLAYLIST_ACCESS.PUBLIC,
      label: "Public",
      icon: PLAYLIST_ACCESS.PUBLIC,
    },
  ];

  const editAccessOptions = [
    {
      value: PLAYLIST_ACCESS.PRIVATE,
      label: "Only You",
      icon: PLAYLIST_ACCESS.PRIVATE,
    },
    {
      value: PLAYLIST_ACCESS.ORGANIZATION,
      label: organizationName,
      icon: PLAYLIST_ACCESS.ORGANIZATION,
    },
    {
      value: PLAYLIST_ACCESS.CUSTOM,
      label: "Custom",
      icon: PLAYLIST_ACCESS.CUSTOM,
    },
  ];

  return { generalAccessOptions, editAccessOptions };
};

export const hasPlaylistAccess = (user, organization, playlist) => {
  const hasAccess =
    playlist?.createdBy === user?.email ||
    playlist?.visibility === PLAYLIST_ACCESS.PUBLIC ||
    (playlist?.visibility === PLAYLIST_ACCESS.ORGANIZATION &&
      user?.organization === organization) ||
    user?.accessType === ACCESS_TYPE.trucoAdmin;

  return hasAccess;
};
