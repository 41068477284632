import { Card, List, Popover, Switch } from "antd";
import { AddRemoveTags } from "src/components";

export const SettingComponent = ({
  title,
  description,
  hasAccessToEdit,
  onChange,
  tags = null,
  tagType,
  switchValue = null,
}) => {
  return (
    <Card.Grid
      style={{
        width: "100%",
      }}
      hoverable={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <List.Item style={{ width: "65%", textAlign: "start" }}>
          <List.Item.Meta title={title} description={description} />
        </List.Item>
        <Popover
          content={
            !hasAccessToEdit
              ? "Only owners/admins have access to change setting"
              : ""
          }
        >
          {tags !== null && (
            <AddRemoveTags
              tagType={tagType}
              tags={tags}
              onChange={(newValue) => onChange(newValue)}
              style={{ justifyContent: "flex-end" }}
            />
          )}
          {switchValue !== null && (
            <Switch
              checked={switchValue}
              onChange={() => onChange(!switchValue)}
              disabled={!hasAccessToEdit}
            />
          )}
        </Popover>
      </div>
    </Card.Grid>
  );
};
