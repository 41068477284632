import React, { useEffect, useState } from "react";
import { useOrganizationState } from "src/state/OrganizationState";
import {
  Typography,
  Spin,
  Space,
  Tooltip,
  notification,
  Popover,
  Menu,
  Dropdown,
  Input,
} from "antd";
import {
  LeftOutlined,
  AimOutlined,
  MoreOutlined,
  ExpandAltOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { CollaboratorAvatar, ErrorBack } from "src/components";
import { getRecordFromFireStore } from "src/firebaseAuth";
import { updateCalibrationToDb } from "./Calibrations.utils";
import { color, elementSize, hexToRgba } from "src/styles/variables";
import {
  generateDataSourceWithCalibration,
  getCurrentEvaluationForm,
  handleAnalysisBulkEdit,
  handleAnalysisEdit,
  updateEvaluation,
  updateScoresRemoveReason,
  updateTranscriptToDb,
  validateNewAnalysis,
} from "../Dashboard/Dashboard.utils";
import {
  ANALYSIS_LABEL,
  ANALYSIS_TYPE,
  COLLECTION_DATA,
} from "src/utils/enums";
import {
  AnalysisTableComponent,
  EditableIcon,
  EditableText,
  SaveIcon,
} from "../Dashboard/Dashboard.styles";
import { RatingComponent, RatingOptions } from "../Dashboard";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { useUserState } from "src/state/UserState";

export const CalibrationTracking = ({
  activeConversation,
  setActiveConversation,
  calibration,
  allUsers,
}) => {
  const { user } = useUserState();
  const {
    organization,
    organizationSettings,
    evaluationForms,
    setEvaluationForms,
  } = useOrganizationState();
  const [loading, setLoading] = useState(false);
  // const [expand, setExpand] = useState(false);
  const [conversationDetails, setConversationDetails] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [expandedDetails, setExpandedDetails] = useState({});
  const [isEditBaseline, setIsEditBaseline] = useState(false);
  const [editing, setEditing] = useState(null);

  const handleBaseline = async (user, item) => {
    setEditing(null);
    setIsEditBaseline(false);
    await updateTranscriptToDb(organization, activeConversation.id, {
      analysis: item.analysis,
      evaluation: item.evaluation,
      "metadata.reviewer": user,
    });
    const conversationIndex = calibration.conversations.findIndex(
      (conv) => conv.id === activeConversation?.id,
    );
    if (conversationIndex !== -1) {
      calibration.conversations[conversationIndex]["baseline"] = {
        analysis: item.analysis,
        evaluation: item.evaluation,
        reviewer: user,
      };
      console.log(calibration.conversations[conversationIndex]["baseline"]);

      await updateCalibrationToDb(organization, calibration, api, {
        conversations: calibration.conversations,
      });

      setActiveConversation((prev) => ({
        ...prev,
        baseline: {
          analysis: item.analysis,
          evaluation: item.evaluation,
          reviewer: user,
        },
      }));
    }
  };
  const handleExpandToggle = (email) => {
    setExpandedDetails((prevState) => ({
      ...prevState,
      [email]: !prevState[email],
    }));
  };

  useEffect(() => {
    setLoading(true);
    if (calibration?.isDone) {
      setConversationDetails(activeConversation.details);
    } else {
      const fetchConversationDetails = async () => {
        const transcript = await getRecordFromFireStore(
          `organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${activeConversation.id}`,
        );
        setConversationDetails(transcript.calibration.details);
        setIsEditBaseline(false);
        setEditing(null);
      };
      fetchConversationDetails();
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [organization, activeConversation, calibration]);

  const handleSave = async () => {
    setLoading(true);
    const validationErrors = validateNewAnalysis(editing);
    if (validationErrors?.length === 0) {
      let currentEvaluationForm = await getCurrentEvaluationForm(
        organization,
        activeConversation.evaluation_form?.id,
        activeConversation.evaluation_form,
        evaluationForms,
        setEvaluationForms,
      );

      const conversationIndex = calibration.conversations.findIndex(
        (conv) => conv.id === activeConversation?.id,
      );
      if (conversationIndex !== -1) {
        const updatesToSave = {
          evaluation: updateEvaluation(
            calibration.conversations[conversationIndex]["baseline"].evaluation,
            editing,
            calibration.conversations[conversationIndex]["baseline"].analysis,
            user,
          ),
          analysis: updateScoresRemoveReason(editing, currentEvaluationForm),
          user: user,
        };
        await handleBaseline(user, updatesToSave);
      }
    } else {
      alert(
        "Rating invalid for the following\n" +
          validationErrors.map((tuple) => tuple.join(": ")).join("\n"),
      );
    }
    setEditing(null);
    setIsEditBaseline(false);
    setLoading(false);
  };

  const data =
    conversationDetails &&
    generateDataSourceWithCalibration(
      activeConversation?.baseline?.analysis ||
        (conversationDetails &&
          (Object.values(conversationDetails)[0] as any)?.analysis) ||
        [],
      organizationSettings,
      activeConversation?.baseline,
      conversationDetails,
    );

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      fixed: "left" as "left",
      width: 75,
      render: (text, record) => {
        if (record.isGroupHeader) {
          return <b>{text}</b>;
        }
        return text;
      },
    },
    ...Object.keys(conversationDetails || {}).map((email) => {
      const user = allUsers.find((user) => user.email === email);
      const menu = (
        <Menu>
          <Menu.Item
            key="1"
            onClick={() => handleExpandToggle(email)}
            icon={<ExpandAltOutlined style={{ color: color.orange }} />}
          >
            {expandedDetails[email] ? "Collapse" : "Expand"}
          </Menu.Item>
          {!calibration?.isDone && (
            <Menu.Item
              key="2"
              onClick={async () => {
                setLoading(true);
                await handleBaseline(user.email, conversationDetails[email]);
                setTimeout(() => {
                  setLoading(false);
                }, 2000);
              }}
              icon={<AimOutlined style={{ color: color.orange }} />}
            >
              Set as Baseline
            </Menu.Item>
          )}
        </Menu>
      );
      const displayName =
        user?.name.length > 20 ? user.name.slice(0, 20) + "..." : user?.name;

      return {
        title: (
          <Space direction="horizontal" align="center">
            <CollaboratorAvatar collaborator={user} />
            <Tooltip title={user?.name}>
              <Typography.Text style={{ textAlign: "center" }}>
                {displayName}
              </Typography.Text>
            </Tooltip>
            <Dropdown overlay={menu} trigger={["click"]}>
              <MoreOutlined style={{ cursor: "pointer", marginLeft: 8 }} />
            </Dropdown>
          </Space>
        ),
        dataIndex: email,
        key: email,
        width: expandedDetails[email] ? 150 : 100,
        render: (record) => {
          const popOverContent = <div>{record.value}</div>;
          const isExpanded = expandedDetails[email];

          return (
            <div
              style={{
                padding: "8px",
                background: activeConversation?.baseline
                  ? record.isSameAsBaseline
                    ? hexToRgba(color.green, 0.1)
                    : hexToRgba(color.red, 0.1)
                  : undefined,
              }}
            >
              {isExpanded && (
                <>
                  <Popover content={popOverContent}>
                    <EditableText>
                      <div>{record.value}</div>
                    </EditableText>
                  </Popover>
                </>
              )}
              <div>
                <RatingComponent rating={record.rating} />
              </div>
            </div>
          );
        },
      };
    }),
  ];
  if (activeConversation.baseline) {
    columns.splice(1, 0, {
      title: (
        <Space direction="horizontal" align="center">
          Baseline
          {calibration?.isDone ? (
            <></>
          ) : isEditBaseline ? (
            <div
              style={{
                display: "flex",
                gap: elementSize.sm,
                paddingLeft: elementSize.xs,
              }}
            >
              <CloseOutlined
                onClick={() => setIsEditBaseline(!isEditBaseline)}
              />
              {!isEqual(editing, activeConversation.baseline.analysis) && (
                <SaveIcon onClick={async () => await handleSave()} />
              )}
            </div>
          ) : (
            <EditableIcon
              style={{ paddingLeft: elementSize.xs }}
              onClick={() => {
                setIsEditBaseline(!isEditBaseline);
                setEditing(cloneDeep(activeConversation.baseline.analysis));
              }}
            />
          )}
        </Space>
      ),
      dataIndex: "baseline",
      key: `baseline`,
      fixed: "left" as "left",
      width: 100,
      render: (record) => {
        const popOverContent = <div>{record.value}</div>;
        const { analysisIndex, metricIndex } = record;
        let editValue = editing?.[analysisIndex]?.value?.[metricIndex]?.value;
        const valueEdited =
          editValue !== undefined && !isEqual(editValue, record.value);
        return (
          <>
            {record.id && isEditBaseline ? (
              <>
                <Input.TextArea
                  value={editValue}
                  autoSize
                  style={{ color: valueEdited ? color.orange : undefined }}
                  placeholder="Data"
                  onChange={(e) =>
                    handleAnalysisEdit(
                      e.target.value,
                      analysisIndex,
                      metricIndex,
                      ANALYSIS_TYPE.VALUE,
                      editing,
                      setEditing,
                    )
                  }
                  onPaste={(e) => {
                    e.preventDefault();
                    handleAnalysisBulkEdit(
                      e.clipboardData.getData("text/plain"),
                      analysisIndex,
                      metricIndex,
                      "value",
                      setEditing,
                    );
                  }}
                />
                {record.analysisType !== ANALYSIS_LABEL.OVERALL ? (
                  <RatingOptions
                    selectedRating={
                      editing[analysisIndex].value[metricIndex].rating
                    }
                    onClick={(newValue) =>
                      handleAnalysisEdit(
                        newValue,
                        analysisIndex,
                        metricIndex,
                        ANALYSIS_TYPE.RATING,
                        editing,
                        setEditing,
                      )
                    }
                  />
                ) : (
                  <RatingComponent rating={record.rating} />
                )}
              </>
            ) : (
              <div>
                <Popover content={popOverContent}>
                  <EditableText>
                    <div>{record.value}</div>
                  </EditableText>
                </Popover>
                <RatingComponent rating={record.rating} />
              </div>
            )}
          </>
        );
      },
    });
  }

  return (
    <Spin spinning={loading}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span
          onClick={() => setActiveConversation(null)}
          style={{ cursor: "pointer" }}
        >
          <LeftOutlined style={{ marginRight: elementSize.sm }} />
          Back
        </span>
      </div>
      {conversationDetails && Object.keys(conversationDetails).length ? (
        <AnalysisTableComponent
          style={{ paddingTop: elementSize.md }}
          dataSource={data}
          columns={columns}
          pagination={false}
          scroll={{ x: columns.length * 200 }}
          sticky
          size="small"
          rowClassName={(record) =>
            record.isGroupHeader ? "groupHeaderRow" : "normalRow"
          }
        />
      ) : (
        <ErrorBack
          title={"Conversation's calibrations"}
          routingName={"calibration"}
          onClick={() => setActiveConversation(null)}
        />
      )}

      {contextHolder}
    </Spin>
  );
};
