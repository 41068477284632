import { useOrganizationState } from "src/state/OrganizationState";
import { useFiltersState } from "src/state/FiltersState";
import { Loading } from "../Loading";
import Actions from "./DashboardTableAction";
import { DashboardTable } from "./DashboardTable";

export enum SHARE_SCOPE {
  SHOW_TOPICS = "topics",
  SHOW_TRANSCRIPTS = "transcripts",
  SHOW_HIGHLIGHTS = "highlights",
  SHOW_SENTIMENTS = "sentiments",
  SHOW_ORGANIZATION_PROPERTIES = "organizationProperties",
  SHOW_EVALUATION_ANALYSIS = "evaluationAnalysis",
  SHOW_TEAMS = "teams",
  SHOW_FEEDBACK = "feedback",
  SHOW_ASKTRUCO = "askTruco",
  SHOW_INTEGRATION_DETAILS = "integrationDetails",
}

export const Dashboard = () => {
  const { loading } = useFiltersState();
  const { transcripts, organizationSettings } = useOrganizationState();
  return !loading && transcripts ? (
    <>
      <Actions />
      <DashboardTable
        data={transcripts}
        download={true}
        showRisk={organizationSettings?.showRisk}
      />
    </>
  ) : (
    <Loading />
  );
};
