import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { TextField, Button } from "@mui/material";
import { Steps, Typography } from "antd";
import { addForm } from "src/firebaseAuth";
import { useUserState } from "src/state/UserState";
import {
  FormContainer,
  GrayCard,
  IframeDiv,
  CloseButtonIcon,
} from "../Home.styles";
import { color, fontSize, spacing } from "src/styles/variables";

import { CALENDER_BOOKING_STATUS, LOCALSTORAGE_TYPES } from "src/utils/enums";
import {
  BorderlessCard,
  CenteredDiv,
  GradientOrangeSpan,
} from "../../LandingPage/LandingPage.styles";

import { TRUCO_CAL_LINK } from "src/utils/variables";
import { Clear } from "@mui/icons-material";
import { orangeTheme } from "src/utils/utils";

const HomeForm = ({ user, setUser }) => {
  const [company, setCompany] = useState("");
  const [details, setDetails] = useState("");
  const [role, setRole] = useState("");
  const [showClose, setShowClose] = useState(false);
  const { setNeedsToBookDemo } = useUserState();

  const [isModalOpen, setIsModalOpen] = useState(
    !!localStorage.getItem(LOCALSTORAGE_TYPES.NEEDS_DEMO),
  );

  useEffect(() => {
    const handleSuccessfulBooking = async (event) => {
      if (
        event.origin === "https://cal.com" &&
        event.data.type === CALENDER_BOOKING_STATUS.BOOKING_SUCCESSFUL
      ) {
        localStorage.removeItem(LOCALSTORAGE_TYPES.NEEDS_DEMO);
        setShowClose(true);
      }
    };

    // Add event listener for 'message'
    window.addEventListener("message", handleSuccessfulBooking);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("message", handleSuccessfulBooking);
    };
  }, []); //

  const addFormData = async (formData) => {
    try {
      await addForm(user, formData);
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsModalOpen(true);
    localStorage.setItem(LOCALSTORAGE_TYPES.NEEDS_DEMO, "true");
    setNeedsToBookDemo(true);

    // Booking Done now send to firebase
    const formData = {
      company: company,
      details: details,
      role: role,
      date: new Date(),
      trialsLeft: 10,
    };

    addFormData(formData);
    setUser({ ...user, ...formData });
  };

  const items = [
    {
      label: "Company Name",
      setFunction: setCompany,
      value: company,
      required: true,
    },
    {
      label: "Job Title/Role",
      setFunction: setRole,
      value: role,
      required: true,
    },
    {
      label: "Is there anything else you'd like us to know?",
      setFunction: setDetails,
      value: details,
    },
  ];
  return (
    <>
      <CenteredDiv style={{ padding: `0px ${spacing.xl}` }}>
        <BorderlessCard center={"true"}>
          <Typography.Title
            level={1}
            style={{
              fontSize: fontSize.displayXXXXXL,
            }}
          >
            Book your demo <br /> with{" "}
            <GradientOrangeSpan>Truco</GradientOrangeSpan>
          </Typography.Title>

          <Typography.Paragraph
            style={{
              fontSize: fontSize.displayXXL,
            }}
          >
            Hi there, it looks like your business is not yet powered by{" "}
            <GradientOrangeSpan>Truco.ai</GradientOrangeSpan>
            <br />
            Please fill out the form below to play with Truco.
          </Typography.Paragraph>
          <br />
          <Steps
            current={0}
            items={[
              {
                title: "Fill the form",
              },
              {
                title: "Book a Demo",
              },
              {
                title: "Truco's Playground",
              },
            ]}
          />
        </BorderlessCard>
        <FormContainer>
          <GrayCard>
            <ThemeProvider theme={orangeTheme}>
              <form autoComplete="off" onSubmit={handleSubmit}>
                {items.map((item, idx) => {
                  const { setFunction, ...textFieldProps } = item;
                  return (
                    <TextField
                      key={idx}
                      onChange={(e) => setFunction(e.target.value)}
                      variant="outlined"
                      style={{
                        backgroundColor: `${color.white}`,
                        borderRadius: `4px`,
                      }}
                      sx={{ mb: 3 }}
                      fullWidth
                      {...textFieldProps}
                    />
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: spacing.sm,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    sx={{
                      color: color.orange,
                      borderColor: color.orange,
                    }}
                  >
                    Book a Demo
                  </Button>
                </div>
              </form>
            </ThemeProvider>
          </GrayCard>
        </FormContainer>
      </CenteredDiv>

      {isModalOpen && (
        <IframeDiv>
          <div
            style={{
              width: "90%",
              height: "90%",
              backgroundColor: "#fff",
              position: "relative", // Position relative to allow absolute positioning inside it
            }}
          >
            <iframe
              src={TRUCO_CAL_LINK}
              title="Booking Demo"
              width="100%"
              height="100%"
              style={{ border: "none" }}
            ></iframe>

            {showClose && (
              <CloseButtonIcon
                onClick={() => {
                  setNeedsToBookDemo(false);
                  setIsModalOpen(false);
                }}
              >
                <Clear />
              </CloseButtonIcon>
            )}
          </div>
        </IframeDiv>
      )}
    </>
  );
};

export default HomeForm;
