import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Space, Typography } from "antd";
import {
  createUserWithEmailAndPassword,
  isSignInWithEmailLink,
  updateProfile,
} from "firebase/auth";
import { auth, getRecordFromFireStore } from "src/firebaseAuth";
import { addUser } from "src/firebaseAuth";
import {
  Button,
  SignContainer,
  FormContainer,
  Form,
  PageWrapper,
  LogoTitle,
  SubTitleSignIn,
  CustomizedInput,
  CustomizedText,
} from "./User.styles";
import { GradientOrangeSpan } from "../LandingPage/LandingPage.styles";
import { color, spacing, fontSize } from "src/styles/variables";
import { useLocation } from "react-router-dom";
import { COLLECTION_DATA, QUERY_PARAMS } from "src/utils/enums";
import { modifyQueryParams } from "src/utils/utils";
import { updateUserDataBasedOnInvitation } from ".";
import { InvitedUser } from "src/utils/types";

export const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const invitationCode = searchParams.get(QUERY_PARAMS.INVITATION_CODE);
  const signWithEmailLink = isSignInWithEmailLink(auth, window.location.href);
  const [invitationData, setInvitationData] = useState(null as InvitedUser); // Initialize with 60 seconds for the countdown
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const registerWithEmailAndPassword = async (email, name, password) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(res.user, {
        displayName: name,
      });
      await updateUserDataBasedOnInvitation(
        invitationData,
        res,
        invitationCode,
      );
      await addUser(res.user, "local");
      navigate("/");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  const register = () => {
    registerWithEmailAndPassword(email, name, password);
  };
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    var charCode = typeof e.which == "number" ? e.which : e.keyCode;
    if (charCode === 13) {
      register();
    }
  };

  const fetchInvitationData = async () => {
    const data = await getRecordFromFireStore(
      `${COLLECTION_DATA.INVITATIONS}/${invitationCode}`,
    );
    setInvitationData(data);
    setEmail(data.email);
  };

  useEffect(() => {
    // Function to fetch invitation data
    if (invitationCode && signWithEmailLink) {
      fetchInvitationData();
    }
  }, [invitationCode]);

  return (
    <PageWrapper>
      <SignContainer>
        <LogoTitle>
          <GradientOrangeSpan>Truco</GradientOrangeSpan>
        </LogoTitle>
        <SubTitleSignIn>Create account</SubTitleSignIn>
        <FormContainer>
          <Form>
            <CustomizedInput
              id="email-address"
              name="email"
              type="email"
              placeholder="Email"
              required
              onChange={(e) => setEmail(e.target.value)}
              disabled={invitationData}
              value={email}
            />
            <CustomizedInput
              id="user-name"
              name="name"
              type="text"
              placeholder="Name"
              maxLength="15" // This limits the input to a maximum of 15 characters
              required
              onChange={(e) => setName(e.target.value)}
            />
            <CustomizedInput
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              required
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeypress}
            />
            {invitationData && (
              <CustomizedInput
                id="organization"
                name="organization"
                type="text"
                placeholder="organization"
                value={invitationData.organization}
                disabled
              />
            )}
            <Space
              direction="horizontal"
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: spacing.xs,
              }}
            >
              <CustomizedInput
                id="terms-and-conditions"
                name="checkbox"
                type="checkbox"
                placeholder="Password"
                required
                onChange={(e) => setAgreedToTerms(e.target.checked)}
              />
              <Typography.Text
                style={{ fontSize: fontSize.body, verticalAlign: "top" }}
              >
                By signing in, I agree to Truco's{" "}
                <Typography.Link
                  href="/terms"
                  style={{ fontSize: fontSize.body }}
                >
                  Terms of Service
                </Typography.Link>{" "}
                and acknowledge I have read the{" "}
                <Typography.Link
                  href="/privacy"
                  style={{ fontSize: fontSize.body }}
                >
                  Privacy Policy
                </Typography.Link>
                .
              </Typography.Text>
            </Space>
          </Form>

          <Button
            size={"fullWidth"}
            type="submit"
            onClick={register}
            disabled={!agreedToTerms}
          >
            Sign Up
          </Button>

          <CustomizedText>
            Already have an account?{" "}
            <NavLink
              style={{ color: color.orange }}
              to={`/signin${modifyQueryParams(location.search)}`}
            >
              Sign in
            </NavLink>
          </CustomizedText>
        </FormContainer>
      </SignContainer>
    </PageWrapper>
  );
};
