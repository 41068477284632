import React, { useEffect, useState } from "react";
import {
  StyledSelectInTable,
  StyledTextArea,
  StyledTextAreaInTable,
} from "src/components/Profile/Profile.styles";
import { borderRadius, color, elementSize } from "src/styles/variables";
import { COLLECTION_DATA, CONDITION_TYPES, RATINGS } from "src/utils/enums";
import { EvaluationFormRulesValue } from "src/utils/types";
import {
  PlusCircleFilled,
  PlusSquareOutlined,
  HomeOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  AddText,
  EvaluationRulesTable,
  TranscriptBox,
} from "../../Evaluation.styles";
import { DeleteWithPopConfirm } from "src/components";
import { RatingComponent, RatingOptions } from "src/components/Dashboard";
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Modal,
  Row,
  Tag,
  Tooltip,
  Space,
} from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import { ConversationTable } from "src/components/Dashboard/ConversationTable";
import { getRecordFromFireStore } from "src/firebaseAuth";
import { transcriptConverter } from "src/utils/converter";
import { useUserState } from "src/state/UserState";
import { getUsersEmailDictionary } from "src/utils/utils";
import MediaPlaylist from "src/components/Dashboard/ConversationModal/Playlist/MediaPlaylist";
import { Link } from "react-router-dom";
import Thumbnail from "src/components/Playlist/PlaylistDetails/Thumbnail/Thumbnail";
import {
  addItemToExamples,
  removeItemFromExamples,
} from "../../Evaluation.utils";
import { fetchFileUrl } from "src/components/Dashboard/Dashboard.utils";
const { Panel } = Collapse;

type EvaluationRulesProps = {
  EvaluationRules: EvaluationFormRulesValue[];
  handleAddConditionalCondition: any;
  handleChange: any;
  categoryIndex: number;
  subCategoryIndex: number;
  errors: any;
  disabled: boolean;
  handleDelete: any;
  setCurrentSelectedEvaluation: any;
  setErrors: any;
};

const EvaluationRules: React.FC<EvaluationRulesProps> = ({
  EvaluationRules,
  handleAddConditionalCondition,
  handleChange,
  categoryIndex,
  subCategoryIndex,
  setErrors,
  errors,
  disabled,
  handleDelete,
  setCurrentSelectedEvaluation,
}) => {
  let firstExtractConditionIndex = -1;
  const firstExtractCondition = EvaluationRules.find((rule, index) => {
    if (rule.type === CONDITION_TYPES.EXTRACT) {
      firstExtractConditionIndex = index;
      return true;
    }
    return false;
  });

  const extractConditionExists = Boolean(firstExtractCondition);

  const columns = [
    {
      title: <span style={{ color: color.grayMedium }}>If Condition</span>,
      dataIndex: "condition",
      key: "condition",
      render: (_, record, conditionIndex) => (
        <>
          {disabled ? (
            <div style={{ color: color.black, width: "11rem" }}>
              {record.condition}
            </div>
          ) : (
            <StyledTextAreaInTable
              value={record.condition}
              onChange={(e) =>
                handleChange(
                  `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.conditions[${conditionIndex}].condition`,
                  e.target.value,
                  setCurrentSelectedEvaluation,
                  `conditionStructure-${categoryIndex}-${subCategoryIndex}-${conditionIndex}`,
                  errors,
                  setErrors,
                )
              }
              placeholder="Condition"
              error={
                errors[
                  `conditionStructure-${categoryIndex}-${subCategoryIndex}-${conditionIndex}`
                ]
              }
              autoSize={{ minRows: 1, maxRows: 3 }}
            />
          )}
        </>
      ),
    },
    {
      title: <span style={{ color: color.orange }}>Extract/Return</span>,
      dataIndex: "extractReturn",
      key: "extractReturn",
      render: (_, record, conditionIndex) => (
        <>
          {disabled ? (
            <Tag
              color={
                record.type === CONDITION_TYPES.RETURN
                  ? color.orange
                  : color.olive
              }
            >
              {record.type}
            </Tag>
          ) : (
            <StyledSelectInTable
              popupMatchSelectWidth={false}
              style={{ backgroundColor: "inherit" }}
              value={record.type}
              onChange={(conditionType) => {
                handleChange(
                  `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.conditions[${conditionIndex}].type`,
                  conditionType,
                  setCurrentSelectedEvaluation,
                );
              }}
              options={Object.values(CONDITION_TYPES).map((value) => ({
                label: value,
                value,
              }))}
              disabled={disabled}
            />
          )}
        </>
      ),
    },
    {
      title: <span style={{ color: color.grayMedium }}>Value</span>,
      dataIndex: "value",
      key: "value",
      render: (_, record, conditionIndex) => (
        <>
          {disabled ? (
            <div style={{ color: color.black, maxWidth: "10rem" }}>
              {record.value}
            </div>
          ) : (
            <StyledTextAreaInTable
              value={record.value}
              onChange={(e) =>
                handleChange(
                  `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.conditions[${conditionIndex}].value`,
                  e.target.value,
                  setCurrentSelectedEvaluation,
                  `conditionStructureValue-${categoryIndex}-${subCategoryIndex}-${conditionIndex}`,
                  errors,
                  setErrors,
                )
              }
              placeholder="Value"
              error={
                errors[
                  `conditionStructureValue-${categoryIndex}-${subCategoryIndex}-${conditionIndex}`
                ]
              }
              disabled={disabled}
              autoSize={{ minRows: 1, maxRows: 3 }}
            />
          )}
        </>
      ),
    },
    {
      title: <span style={{ color: color.orange }}>Rating</span>,
      dataIndex: "rating",
      key: "rating",
      render: (_, record, conditionIndex) => (
        <>
          {disabled ||
          (extractConditionExists &&
            record.type === CONDITION_TYPES.EXTRACT &&
            firstExtractConditionIndex !== conditionIndex) ? (
            <RatingComponent rating={record.rating} />
          ) : (
            <RatingOptions
              selectedRating={record.rating}
              onClick={(ratingValue) => {
                handleChange(
                  `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.conditions[${conditionIndex}].rating`,
                  ratingValue,
                  setCurrentSelectedEvaluation,
                );
              }}
            />
          )}
        </>
      ),
    },
    {
      title: <span style={{ color: color.grayMedium }}>Examples</span>,
      dataIndex: "Examples",
      key: "Examples",
      render: (_, record, conditionIndex) => (
        <>
          {record.examples && record.examples.length > 0 && (
            <EyeOutlined
              onClick={() => {
                setClickedIndex(conditionIndex);
                setShowModalExample(true);
              }}
            />
          )}
        </>
      ),
    },
    {
      title: <span style={{ color: color.grayMedium }}>Actions</span>,
      key: "action",
      render: (_, record, conditionIndex) => (
        <>
          {EvaluationRules.length > 1 && !disabled && (
            <Space direction="horizontal">
              <Tooltip title={"Delete Rule"}>
                {DeleteWithPopConfirm(
                  "Delete Condition?",
                  "Are you sure you want to delete this condition?",
                  (e) => {
                    handleDelete(
                      `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.conditions`,
                      conditionIndex,
                      setCurrentSelectedEvaluation,
                    );
                  },
                  disabled,
                )}
              </Tooltip>
              <Tooltip title={"Add examples"}>
                <PlusSquareOutlined
                  onClick={() => {
                    setClickedIndex(conditionIndex);
                    setShowModal(true);
                  }}
                  style={{ color: color.orange }}
                />
              </Tooltip>
            </Space>
          )}
        </>
      ),
    },
  ];
  const {
    transcripts,
    organization,
    evaluationForms,
    collaborators,
    pendingCollaborators,
  } = useOrganizationState();

  const { user } = useUserState();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalExample, setShowModalExample] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [modalData, setModalData] = useState<any>(null);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [dataForm, setDataForm] = useState(null);
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);
  const [exampleExtractValue, setSampleExtractValue] = useState<string>("");
  const [exampleExtractValueError, setExampleExtractValueError] =
    useState<boolean>(false);

  const fetchTranscripts = async (selectedId) => {
    const fetchedTranscript = await getRecordFromFireStore(
      `organization/${organization}/${COLLECTION_DATA.CONVERSATIONS}/${selectedId}`,
      transcriptConverter(
        user,
        evaluationForms,
        getUsersEmailDictionary(collaborators, pendingCollaborators),
      ),
    );

    setModalData({
      ...fetchedTranscript,
      id: selectedId,
    });
  };
  const handleRowClick = (transcript) => {
    setSelectedId(transcript?.id);
  };
  const addExample = () => {
    if (
      exampleExtractValue?.trim() === "" &&
      EvaluationRules[clickedIndex]?.type === CONDITION_TYPES.EXTRACT
    ) {
      setExampleExtractValueError(true);
      return;
    }

    addItemToExamples(
      `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.conditions[${clickedIndex}].examples`,
      {
        transcripts: modalData.utterances
          .filter(
            (utterance) =>
              utterance.end >= startTime && utterance.start <= endTime,
          )
          .map(
            (utterance) =>
              `${typeof utterance["speaker"] === "string" ? utterance["speaker"] : `Speaker ${utterance["speaker"]}`}: ${utterance.transcript}`,
          ),
        startTime,
        endTime,
        conversationId: selectedId,
        mimeType: modalData.mimeType,
        value: CONDITION_TYPES.EXTRACT
          ? exampleExtractValue
          : EvaluationRules[clickedIndex]?.value,
      },
      setCurrentSelectedEvaluation,
    );

    setSelectedId(null);
    setExampleExtractValueError(false);
    setSampleExtractValue("");

    setShowModal(false);
  };

  useEffect(() => {
    if (selectedId) {
      fetchTranscripts(selectedId);
    }
  }, [selectedId]);

  const fetchUrlsAndUpdateData = async (examples) => {
    const updatedExamples = await Promise.all(
      examples.map(async (data) => {
        if (!data.url && data.conversationId) {
          try {
            const url = await fetchFileUrl(data.conversationId);
            return { ...data, url };
          } catch (error) {
            console.error(
              `Failed to fetch URL for conversationId ${data.conversationId}:`,
              error,
            );
            return data;
          }
        }
        return data;
      }),
    );

    setDataForm(updatedExamples);
  };

  useEffect(() => {
    if (EvaluationRules && clickedIndex !== null) {
      const selectedExamples = EvaluationRules[clickedIndex]?.examples || [];
      if (selectedExamples.length > 0) {
        fetchUrlsAndUpdateData(selectedExamples);
      }
    }
  }, [EvaluationRules, clickedIndex]);

  useEffect(() => {
    if (
      EvaluationRules !== undefined &&
      EvaluationRules?.[clickedIndex]?.examples?.length === 0
    ) {
      setShowModalExample(false);
    }
  }, [EvaluationRules, clickedIndex]);

  return (
    <>
      <EvaluationRulesTable
        style={{
          border: `1px solid ${color.grayLight}`,
          borderRadius: `${borderRadius.xs} ${borderRadius.xs} 0px 0px`,
          margin: `${elementSize.sm} 0px ${elementSize.md} 0px`,
        }}
        columns={columns}
        dataSource={EvaluationRules.map((rule, index) => ({
          ...rule,
          key: `${categoryIndex}-${subCategoryIndex}-${index}`,
        }))}
        scroll={{ x: 300 }}
        pagination={false}
      />
      {!disabled && (
        <AddText
          onClick={() => {
            if (!disabled)
              handleAddConditionalCondition(
                categoryIndex,
                subCategoryIndex,
                setCurrentSelectedEvaluation,
                RATINGS.NONE,
              );
          }}
        >
          <PlusCircleFilled /> Add Rule
        </AddText>
      )}

      <Modal
        open={showModal}
        title={
          <Breadcrumb style={{ cursor: "pointer" }}>
            <Breadcrumb.Item>
              <Link
                to="#"
                onClick={() => {
                  setExampleExtractValueError(false);
                  setSampleExtractValue("");
                  setShowModal(false);
                }}
              >
                <HomeOutlined />
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => {
                setSelectedId(null);
              }}
            >
              Choose from conversation
            </Breadcrumb.Item>
            {selectedId && (
              <Breadcrumb.Item>
                <span> {selectedId}</span>
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        }
        onCancel={() => {
          setSelectedId(null);
          setExampleExtractValueError(false);
          setSampleExtractValue("");
          setShowModal(false);
        }}
        footer={null}
        width={"97vw"}
      >
        {!selectedId && transcripts.length > 0 && (
          <ConversationTable
            data={transcripts}
            showRisk={true}
            handleRowClick={handleRowClick}
          />
        )}

        {selectedId && (
          <>
            <MediaPlaylist
              data={modalData}
              startTime={startTime}
              endTime={endTime}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setDisable={setDisableButton}
            />
            {EvaluationRules[clickedIndex]?.type === "extract" && (
              <>
                <StyledTextArea
                  value={exampleExtractValue}
                  onChange={(e) => setSampleExtractValue(e.target.value)}
                  placeholder="What would you like to extract?"
                  autoSize={{ minRows: 1 }}
                  error={exampleExtractValueError}
                />
                {exampleExtractValueError && (
                  <p
                    style={{
                      color: color.red,
                    }}
                  >
                    Please enter a extract value for the example.
                  </p>
                )}
              </>
            )}

            <Row
              justify="end"
              style={{
                marginTop: "10px",
              }}
            >
              <Button
                disabled={disableButton}
                onClick={() => {
                  addExample();
                }}
              >
                Add example
              </Button>
            </Row>
          </>
        )}
      </Modal>
      <Modal
        open={showModalExample}
        onCancel={() => {
          setShowModalExample(false);
          setClickedIndex(null);
        }}
        footer={null}
        width={"97vw"}
      >
        <div
          style={{
            padding: "20px",
          }}
        >
          <Collapse accordion>
            {EvaluationRules?.[clickedIndex]?.examples?.map((data, index) => (
              <Panel
                header={
                  <Row align="middle" justify="space-between">
                    {`Example ${index + 1}`}
                    {!disabled && (
                      <Tooltip title={"Delete Example"}>
                        {DeleteWithPopConfirm(
                          "Delete Example?",
                          "Are you sure you want to delete this Example?",
                          (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            removeItemFromExamples(
                              `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.conditions[${clickedIndex}].examples`,
                              index,
                              setCurrentSelectedEvaluation,
                            );
                          },
                          disabled,
                        )}
                      </Tooltip>
                    )}
                  </Row>
                }
                key={index}
              >
                <Row align="middle">
                  <Col span={8}>
                    {data?.conversationId && (
                      <Thumbnail
                        url={dataForm?.[index]?.url}
                        start={data.startTime}
                        end={data.endTime}
                        type={data.mimeType}
                        clipId={`${index}-${data.conversationId}`}
                      />
                    )}
                  </Col>
                  <Col span={14}>
                    {data?.transcripts?.map((transcript, indexTranscript) => (
                      <TranscriptBox key={indexTranscript}>
                        {transcript}
                      </TranscriptBox>
                    ))}

                    <TranscriptBox
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Tag
                        color={
                          EvaluationRules[clickedIndex]?.type === "extract"
                            ? color.olive
                            : color.orange
                        }
                      >
                        {EvaluationRules[clickedIndex]?.type}
                      </Tag>
                      {EvaluationRules[clickedIndex]?.type !== "extract" ||
                      disabled ? (
                        data.value
                      ) : (
                        <StyledTextAreaInTable
                          value={data?.value}
                          onChange={(e) => {
                            handleChange(
                              `analysisStructures[${categoryIndex}].value[${subCategoryIndex}].value.conditions[${clickedIndex}].examples[${index}].value`,
                              e.target.value,
                              setCurrentSelectedEvaluation,
                              `conditionExampleStructure-${categoryIndex}-${subCategoryIndex}-${clickedIndex}-${index}`,
                              errors,
                              setErrors,
                            );
                          }}
                          error={
                            errors[
                              `conditionExampleStructure-${categoryIndex}-${subCategoryIndex}-${clickedIndex}-${index}`
                            ]
                          }
                          placeholder="What would you like to extract?"
                          autoSize={{ minRows: 1 }}
                        />
                      )}
                    </TranscriptBox>
                  </Col>
                </Row>
              </Panel>
            ))}
          </Collapse>
        </div>
      </Modal>
    </>
  );
};

export default EvaluationRules;
