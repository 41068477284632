import styled, { keyframes } from "styled-components";
import {
  spacing,
  color,
  fontSize,
  borderRadius,
  fontWeight,
  deviceSize,
  grayBoxShadow,
  lineHeight,
  elementSize,
} from "src/styles/variables";
import { Avatar, Popover, Typography } from "antd";

export const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 85vh;
  padding: 25px;
`;

export const SignContainer = styled.div`
  padding: 30px 20px 50px;
  box-shadow: ${grayBoxShadow};
  width: 529px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  @media (max-width: ${deviceSize.large_phone}) {
    box-shadow: none;
    padding: 0;
    padding: 30px 20px;
  }
`;

export const DesktopSignOutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: relative;
  cursor: pointer;
  @media (max-width: ${deviceSize.large_phone}) {
    display: none;
  }
`;

export const UserNavBarAvatar = styled(Avatar)`
  cursor: pointer;
  width: ${elementSize.xll};
  height: ${elementSize.xll};
  border-radius: 50%; /* Makes the border rounded */
  border: 1px solid white;
  box-shadow: ${grayBoxShadow};
  line-height: 31px;
  font-size: ${fontSize.displayXXL};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${deviceSize.large_phone}) {
    overflow: hidden;
    width: 30px;
    height: 30px;
    line-height: 34px;
  }
`;

export const ArrowCircle = styled.div`
  position: absolute;
  bottom: ${borderRadius.xxss};
  right: ${borderRadius.xxss};
  width: ${borderRadius.sm}; /* Size of the arrow circle */
  height: ${borderRadius.sm}; /* Size of the arrow circle */

  ${(props) => {
    if (props.headercolor == null) {
      return `
  background-color: ${color.gray}; /* Color of the arrow circle */
  border: ${color.gray} 2px solid;
      `;
    } else {
      return `
          background-color: ${color.white}; /* Color of the arrow circle */
          border: ${color.white} 2px solid;
      `;
    }
  }}

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(
    50%,
    50%
  ); /* This will partially overlap the arrow circle with the avatar */
  /* Add any additional styling for the arrow circle here */
`;

export const DesktopPopOver = styled(Popover)`
  cursor: pointer;
  @media (max-width: ${deviceSize.large_phone}) {
    display: none;
  }
`;

export const CustomizedInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid ${(props) => (props.error ? color.red : color.gray)};
  border-radius: ${borderRadius.xxs};
  &:focus {
    border-color: ${(props) => (props.error ? color.red : color.orange)};
    outline: none;
  }
  @media (max-width: ${deviceSize.large_phone}) {
    font-size: ${fontSize.displayXLL};
  }
  &:disabled {
    opacity: 0.6;
  }
`;

export const LogoTitle = styled(Typography.Title)`
  font-size: ${fontSize.displayXXXXL} !important;
  margin-bottom: ${lineHeight.xs} !important;
  letter-spacing: ${lineHeight.xxs};
`;

export const SubTitleSignIn = styled(Typography.Paragraph)`
  font-size: ${fontSize.displayXXL};
  color: ${color.grayDark};
`;

export const ForgotPasswordLink = styled.span`
  align-self: flex-end;
  cursor: pointer;
  color: ${color.grayDark};
  font-size: ${fontSize.bodyLarge};
  display: flex;
  &:hover {
    text-decoration: underline;
  }
`;

export const CustomizedText = styled.span`
  margin-top: ${spacing.ml};
  color: ${color.grayDark};
  font-size: ${fontSize.bodyLarge};
  text-decoration: none;
  @media (max-width: ${deviceSize.large_phone}) {
    font-size: ${fontSize.displayXL};
  }
`;

export const CustomizedTextWithPadding = styled(CustomizedText)`
  margin-top: 0px;
  padding: 0px ${spacing.xl} ${spacing.md};
  @media (max-width: ${deviceSize.large_phone}) {
    padding-bottom: ${spacing.md};
  }
`;
export const FormContainer = styled.div`
  width: min(70vw, 350px);
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
export const OrDividor = styled.span`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${color.grayDark};

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid ${color.gray};
    font-size: 0.9em;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }

  span {
    padding: ${lineHeight.ml};
    font-size: ${fontSize.displayXLL};
  }
`;

export const Form = styled.form`
  text-align: left;
  font-size: ${fontSize.displayXL};
  margin-bottom: ${spacing.xxl};
  display: flex;
  flex-direction: column;
  position: relative;

  > input {
    display: flex;
    width: 100%;
    box-sizing: border-box;

    margin: ${spacing.xs} 0px;
    padding: ${spacing.md};
  }
`;

export const MarginTop = styled.div`
  margin-top: ${spacing.md};
`;

export const Button = styled.button`
  font-weight: ${fontWeight.semiBold};
  font-size: ${fontSize.displayXL};
  line-height: ${spacing.sm};
  color: ${color.white};
  background-color: ${color.orange};
  border: none;
  cursor: pointer;
  white-space: nowrap;
  margin: auto;
  height: 40px;


  display: block;
  transition: background-color 0.1s ease-in-out;
  &:hover {
    background: ${color.lightOrange};
  }
  &:hover + div {
    display: initial;
  }
  > a {
    text-decoration: none;
    color: ${color.white};
  }
  &:disabled {
    opacity:0.7;
  }
  @media (max-width: ${deviceSize.large_phone}) {
    font-size: ${fontSize.displayXXL};
    font-weight: ${fontWeight.medium};
  }

  ${(props) => {
    if (props.size === "large") {
      return `
            padding: ${spacing.xl} ${spacing.xl};
            border-radius: ${borderRadius.lg};
            font-size: ${fontSize.displayXXXL};
            /* Add other properties here */
        `;
    } else {
      if (props.size === "fullWidth") {
        return `
        width: 100%;
        padding: ${spacing.md} ${spacing.lg}; 
        border-radius: ${borderRadius.xxs};
        /* Add other properties here */
      
    `;
      } else {
        return `
            padding: ${spacing.md} ${spacing.lg};
            border-radius: ${borderRadius.xs};
            /* Add other properties here */
        `;
      }
    }
  }}
  }}
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: ${elementSize.xxs};
  height: ${elementSize.xxs};
  border-radius: 50%;
  border-left-color: transparent;
  animation: ${spin} 1s linear infinite;
  margin: auto;
`;

export const DesktopOnlyButton = styled(Button)`
  @media (max-width: ${deviceSize.large_phone}) {
    display: none;
  }
`;
