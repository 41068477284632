// Firestore data converter

import {
  METRIC_SCORE_ITEMS,
  getTrucoMetricsFromTrucoScore,
} from "src/components";
import { Customer, PlayList, Transcript } from "./types";
import {
  formatTime,
  humanize,
  formatDateToHumanReadable,
  unformatTime,
  extractAndFormatTrackingDate,
  isReviewerInCalibration,
  capitalizeFirstLetter,
} from "./utils";
import { ACCESS_TYPE, CONVERSATION_ACCESS, SENTIMENT } from "src/utils/enums";
import { ORGANIZATIONAL_INTEGRATIONS } from "src/components";

function mapAndSortQAMetric(qaMetrics, usersDictionary) {
  const calculateQAScore = (evaluatedCount, totalCount) => {
    return parseFloat(((evaluatedCount / totalCount) * 100).toFixed(0));
  };
  const sortedQAMetric = qaMetrics
    ?.map((metric) => ({
      ...metric,
      [METRIC_SCORE_ITEMS.USER]: formatUser(
        usersDictionary,
        metric[METRIC_SCORE_ITEMS.USER],
      ),
      [METRIC_SCORE_ITEMS.SCORE]: calculateQAScore(
        metric[METRIC_SCORE_ITEMS.SCORE],
        metric[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS],
      ),
      [METRIC_SCORE_ITEMS.TOTAL_DURATION]: formatTime(
        metric[METRIC_SCORE_ITEMS.TOTAL_DURATION],
      ),
    }))
    ?.sort((a, b) => {
      if (
        a[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS] !==
        b[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]
      ) {
        return (
          b[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS] -
          a[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]
        );
      } else if (a[METRIC_SCORE_ITEMS.SCORE] !== b[METRIC_SCORE_ITEMS.SCORE]) {
        return b[METRIC_SCORE_ITEMS.SCORE] - a[METRIC_SCORE_ITEMS.SCORE];
      } else {
        return (
          unformatTime(b[METRIC_SCORE_ITEMS.TOTAL_DURATION]) -
          unformatTime(a[METRIC_SCORE_ITEMS.TOTAL_DURATION])
        );
      }
    });
  return sortedQAMetric;
}
export function mapAndSortUsersMetric(userMetrics, usersDictionary, showRisk) {
  const calculateScore = (score, totalCount, showRisk) => {
    return showRisk
      ? parseFloat((100 - (score * 100) / totalCount).toFixed(0))
      : parseFloat(((score * 100) / totalCount).toFixed(0));
  };
  const sortedQAMetric = userMetrics
    ?.map((metric) => ({
      ...metric,
      [METRIC_SCORE_ITEMS.USER]: formatUser(
        usersDictionary,
        metric[METRIC_SCORE_ITEMS.USER],
      ),
      [METRIC_SCORE_ITEMS.SCORE]: calculateScore(
        metric[METRIC_SCORE_ITEMS.SCORE],
        metric[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS],
        showRisk,
      ),
      [METRIC_SCORE_ITEMS.AVERAGE_DURATION]: formatTime(
        metric[METRIC_SCORE_ITEMS.TOTAL_DURATION] /
          metric[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS],
      ),
      [METRIC_SCORE_ITEMS.TOTAL_DURATION]: formatTime(
        metric[METRIC_SCORE_ITEMS.TOTAL_DURATION],
      ),
    }))
    ?.sort((a, b) => {
      if (a[METRIC_SCORE_ITEMS.SCORE] !== b[METRIC_SCORE_ITEMS.SCORE]) {
        return showRisk
          ? a[METRIC_SCORE_ITEMS.SCORE] - b[METRIC_SCORE_ITEMS.SCORE]
          : b[METRIC_SCORE_ITEMS.SCORE] - a[METRIC_SCORE_ITEMS.SCORE];
      } else if (
        a[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS] !==
        b[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]
      ) {
        return (
          b[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS] -
          a[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS]
        );
      } else {
        return (
          unformatTime(a[METRIC_SCORE_ITEMS.AVERAGE_DURATION]) -
          unformatTime(b[METRIC_SCORE_ITEMS.AVERAGE_DURATION])
        );
      }
    });
  return sortedQAMetric;
}

function formatUser(usersDictionary, user) {
  return usersDictionary?.[user]
    ? usersDictionary?.[user]
    : {
        email: user || null,
        name: user || null,
      };
}

const getReviewer = (metadata, currentUser, calibration) => {
  const defaultReviewer = metadata?.reviewer || metadata?.user;

  const isReviewer = isReviewerInCalibration(calibration, currentUser);

  if (isReviewer) {
    return currentUser.email;
  }

  return defaultReviewer;
};

export function transformToTranscript(
  data,
  evaluationForms?,
  usersDictionary?,
  currentUser?,
  organizationId?,
) {
  // make metadata backwards compatible till we migrate
  const metadata = {
    ...(data?.metadata ?? {}),
    ...data?.transcript_data?.metadata,
  };

  const agent = metadata?.agent;
  const lead = metadata?.lead;
  const reviewer = getReviewer(metadata, currentUser, data?.calibration);
  const calibrationDetails = data?.calibration?.details?.[reviewer];
  const currentEvaluationForm = {
    id: metadata?.evaluation_id,
    ...(metadata?.evaluation_form ?? {}),
  };
  currentEvaluationForm["title"] = evaluationForms?.[currentEvaluationForm?.id]
    ?.title
    ? evaluationForms?.[currentEvaluationForm?.id]?.title
    : data?.analysis?.length > 0
      ? "Default"
      : "Not Assigned Yet";

  const transcript: Transcript = {
    id: data.id,
    evaluation_form: currentEvaluationForm,
    summary: data.transcript_data?.summary?.short,
    analysis: calibrationDetails?.analysis ?? data?.analysis,
    evaluation: calibrationDetails?.evaluation ??
      data?.evaluation ?? {
        verified: false,
        updates: {},
        unreadBy: [],
        participants: [],
        feedback: [],
      },
    utterances: data?.transcript_data?.utterances,
    holds: data?.transcript_data?.holds,
    sentiments: data?.transcript_data?.sentiments,
    created_at: formatDateToHumanReadable(metadata?.transcriber?.created),
    agent: formatUser(usersDictionary, agent),
    lead: formatUser(usersDictionary, lead),
    reviewer: formatUser(usersDictionary, reviewer),
    metadata: metadata?.metadata,
    mimeType: metadata?.mime_type,
    customer: metadata?.customer,
    participants: metadata?.participants ?? [],
    file_name: metadata?.file_name,
    file_source: metadata?.file_source,
    integration_data: metadata?.file_source
      ? metadata[metadata?.file_source]
      : undefined, // in the future this should be in firstore as integrationData
    duration: formatTime(metadata?.transcriber?.duration),
    calibration: data?.calibration,
    playlists: data?.playlists,
    visibility: data?.visibility ?? CONVERSATION_ACCESS.ORGANIZATION, // inorder to avoid nasty migrations
    organizationId: organizationId,
    team: [
      {
        conversationRole: ACCESS_TYPE.agent,
        user: formatUser(usersDictionary, agent),
      },
      {
        conversationRole: ACCESS_TYPE.reviewer,
        user: formatUser(usersDictionary, reviewer),
      },
      {
        conversationRole: ACCESS_TYPE.lead,
        user: formatUser(usersDictionary, lead),
      },
    ].filter((user) => user.user.email !== null),
  };
  return transcript;
}

export const transcriptConverterWithPermissions = (
  user,
  evaluationForms?,
  usersDictionary?,
) => ({
  toFirestore: (transcript) => {
    return transcript;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const organizationId =
      (snapshot.ref.path.match(/organization\/([^/]+)/) || [])[1] || null; // Extracting organization ID

    const metadata = {
      ...(data?.metadata ?? {}),
      ...data?.transcript_data?.metadata,
    };

    if (metadata?.deleted) return null;
    if (
      user?.accessType === ACCESS_TYPE.agent &&
      ![metadata?.agent, metadata?.reviewer, metadata?.lead].includes(
        user?.email,
      )
    )
      return null;

    const transcript = transformToTranscript(
      data,
      evaluationForms,
      usersDictionary,
      user,
      organizationId,
    );

    return transcript;
  },
});

export const transcriptConverter = (
  evaluationForms?,
  usersDictionary?,
  currentUser?,
) => ({
  toFirestore: (transcript) => {
    return transcript;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const organizationId =
      (snapshot.ref.path.match(/organization\/([^/]+)/) || [])[1] || null; // Extracting organization ID
    // Now transformToTranscript can accept evaluationForms
    return transformToTranscript(
      data,
      evaluationForms,
      usersDictionary,
      currentUser,
      organizationId,
    );
  },
});

export const chatConverter = (currentUser?) => ({
  toFirestore: (transcript) => {
    // If you don't need custom logic for writing data, you can return the city object as is
    // Or you can omit this method entirely if you're not using the converter for writing
    return transcript;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const metadata = {
      ...(data?.metadata ?? {}),
      ...data?.transcript_data?.metadata,
    };
    const reviewer = getReviewer(metadata, currentUser, data?.calibration);
    const calibrationDetails =
      data?.calibration?.details?.[reviewer]?.evaluation;

    const userChat = {
      participants:
        calibrationDetails?.participants ??
        data?.evaluation?.participants ??
        [],
      unreadBy:
        calibrationDetails?.unreadBy ?? data?.evaluation?.unreadBy ?? [],
      feedback:
        calibrationDetails?.feedback ?? data?.evaluation?.feedback ?? [],
      file_name: metadata?.file_name,
      calibration: data?.calibration,
    };

    return userChat;
  },
});

export const settingsConverter = (organization?) => ({
  toFirestore: (settings) => {
    return settings;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    let organizationSettings = {
      showRisk: data?.showRisk ?? false,
      showSentiment: data?.showSentiment ?? true,
      teams: data?.teams ?? [],
      customerStages: data?.customerStages ?? [
        "Qualified",
        "Disqualified",
        "Follow up",
        "Decision Maker Bought In",
        "Contract Sent",
        "Won",
        "Lost",
      ],
      conversationProperties: data?.conversationProperties ?? [],
      name: data?.name
        ? data?.name
        : organization
          ? capitalizeFirstLetter(organization)
          : capitalizeFirstLetter(snapshot.id),
      stripe: data?.stripe ?? {},
    };

    let orgIntegrations = ORGANIZATIONAL_INTEGRATIONS.reduce(
      (acc, integration) => {
        acc[integration] = data?.[integration] ?? null;
        return acc;
      },
      {},
    );
    return { ...orgIntegrations, ...organizationSettings };
  },
});

export const assigmentsTrackingConverter = {
  toFirestore: (tracking) => {
    return tracking;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    let assigmentsTracking = {
      title: extractAndFormatTrackingDate(snapshot.id),
      revieweeAssignments: data?.revieweeAssignments,
      reviewerAssignments: data?.reviewerAssignments,
    };

    return assigmentsTracking;
  },
};

export function evaluationFormsTransform(evaluationForms) {
  const transformedEvaluationForms = evaluationForms.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  return transformedEvaluationForms;
}

export const customerConverter = {
  toFirestore: (customer) => {
    return customer;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    let customerData: Customer = {
      id: snapshot.id,
      ...data,
      updatedAt: formatDateToHumanReadable(data.updatedAt),
      customer: data?.email || data?.phone || data?.customer,
    };
    return customerData;
  },
};

export const playlistConverter = {
  toFirestore: (playlist) => {
    const { id, ...rest } = playlist;
    return {
      ...rest,
      clips: rest.clips.map(({ url, ...clipRest }) => clipRest),
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const playListData: PlayList = {
      ...data,
      // hena law el type private then start w end of timeLine here
      // i guess the user of a signal playlist is the owner and he is the only one that can delete ?
      id: snapshot.id,
    };
    // we should convert all of our playlists like this ? maybe
    playListData.clips = playListData.clips.map((clip) => {
      // Default to clip.timeline[0] values if clip.start or clip.end are not set
      const defaultTimeline = clip.timeline?.[0];
      return {
        ...clip,
        start: clip.start || defaultTimeline?.start,
        end: clip.end || defaultTimeline?.end,
      };
    });

    return playListData;
  },
};

// TODO : Remove after discussing with Swetha

export function transformAnalysisStructures(analysisStructuresList) {
  const topicOptions = analysisStructuresList[
    analysisStructuresList.length - 1
  ]?.analysisStructures
    .flatMap((item) => item.value) // Flatten the structure to access each item directly
    .filter((item) => item?.id) // Ensure we're only processing items with an `id`
    .map((item) => ({
      label: humanize(item.id), // Use the label if available, otherwise use the id
      value: item.id,
    }));
  return topicOptions;
}

export function transformInsights(insightsArray, usersDictionary?, showRisk?) {
  function aggregateUniqueUsers(metrics) {
    const userMap = new Map();
    metrics.forEach((metric) => {
      const userId = metric[METRIC_SCORE_ITEMS.USER];
      if (userMap.has(userId)) {
        const existingMetric = userMap.get(userId);
        existingMetric[METRIC_SCORE_ITEMS.TOTAL_DURATION] +=
          metric[METRIC_SCORE_ITEMS.TOTAL_DURATION];
        existingMetric[METRIC_SCORE_ITEMS.SCORE] +=
          metric[METRIC_SCORE_ITEMS.SCORE];
        existingMetric[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS] +=
          metric[METRIC_SCORE_ITEMS.TOTAL_TRANSCRIPTS];
      } else {
        userMap.set(userId, { ...metric });
      }
    });
    return Array.from(userMap.values());
  }
  function combineTrucoScores(
    trucoScores1: { [key: string]: number },
    trucoScores2: { [key: string]: number },
  ) {
    const combinedTrucoScores: { [key: string]: number } = { ...trucoScores1 };

    Object.keys(trucoScores2).forEach((key) => {
      if (!combinedTrucoScores[key]) {
        combinedTrucoScores[key] = 0;
      }
      combinedTrucoScores[key] += trucoScores2[key];
    });

    return combinedTrucoScores;
  }

  function mergeAnalysisScores(existingScores: any, newScores: any) {
    for (const key in newScores) {
      if (!existingScores[key]) {
        existingScores[key] = newScores[key];
      } else {
        existingScores[key].score.count += newScores[key].score.count;
        existingScores[key].score.total += newScores[key].score.total;
        existingScores[key].score.verified += newScores[key].score.verified;

        for (const metric in newScores[key]) {
          if (metric !== "score") {
            if (!existingScores[key][metric]) {
              existingScores[key][metric] = newScores[key][metric];
            } else {
              existingScores[key][metric].count += newScores[key][metric].count;
              existingScores[key][metric].total += newScores[key][metric].total;
              existingScores[key][metric].verified +=
                newScores[key][metric].verified;
            }
          }
        }
      }
    }

    return existingScores;
  }
  function calculateAnalysisScores(analysisScores: any) {
    Object.keys(analysisScores).forEach((analysisType: any) => {
      const scoreEntry = analysisScores[analysisType];
      Object.keys(scoreEntry).forEach((metricType) => {
        const metric = scoreEntry[metricType];
        metric.score = Number(((metric.total * 100) / metric.count).toFixed(0));
      });
    });
  }

  const aggregatedInsight = {
    totalDuration: 0,
    totalTranscripts: 0,
    totalVerifiedCount: 0,
    sentiments: {
      [SENTIMENT.NEUTRAL]: 0,
      [SENTIMENT.POSITIVE]: 0,
      [SENTIMENT.NEGATIVE]: 0,
    },
    agentMetrics: [],
    leadMetrics: [],
    qaMetrics: [],
    trucoMetrics: [],
    analysisScores: {},
  };
  let combinedAnalysisScores = {};
  let combinedTrucoScores = {};

  insightsArray.forEach((insight) => {
    aggregatedInsight.totalDuration += insight.totalDuration;
    aggregatedInsight.totalTranscripts += insight.totalTranscripts;
    aggregatedInsight.totalVerifiedCount += insight.verifiedCount;
    aggregatedInsight.sentiments[SENTIMENT.NEUTRAL] +=
      insight.sentiments[SENTIMENT.NEUTRAL];
    aggregatedInsight.sentiments[SENTIMENT.POSITIVE] +=
      insight.sentiments[SENTIMENT.POSITIVE];
    aggregatedInsight.sentiments[SENTIMENT.NEGATIVE] +=
      insight.sentiments[SENTIMENT.NEGATIVE];
    // Aggregate metrics
    aggregatedInsight.agentMetrics.push(...insight.agentMetrics);
    aggregatedInsight.leadMetrics.push(...insight.leadMetrics);
    aggregatedInsight.qaMetrics.push(...insight.qaMetrics);
    // Aggregate truco metrics
    // aggregatedInsight.trucoMetrics.push(...insight.trucoMetrics);
    combinedTrucoScores = combineTrucoScores(
      insight.trucoScores,
      combinedTrucoScores,
    );

    combinedAnalysisScores = mergeAnalysisScores(
      combinedAnalysisScores,
      insight.analysisScores,
    );
  });

  // Remove duplicate users and aggregate their metrics
  aggregatedInsight.agentMetrics = mapAndSortUsersMetric(
    aggregateUniqueUsers(aggregatedInsight.agentMetrics),
    usersDictionary,
    showRisk,
  );
  aggregatedInsight.leadMetrics = mapAndSortUsersMetric(
    aggregateUniqueUsers(aggregatedInsight.leadMetrics),
    usersDictionary,
    showRisk,
  );
  aggregatedInsight.qaMetrics = mapAndSortQAMetric(
    aggregateUniqueUsers(aggregatedInsight.qaMetrics),
    usersDictionary,
  );
  //  Aggregate analysis scores
  calculateAnalysisScores(combinedAnalysisScores);
  aggregatedInsight.analysisScores = combinedAnalysisScores;

  // Aggregate truco metrics by unique metric names
  aggregatedInsight.trucoMetrics = getTrucoMetricsFromTrucoScore(
    combinedTrucoScores,
    combinedAnalysisScores,
  );

  return aggregatedInsight;
}
