import React, { useState } from "react";
import { Button, Form, Modal } from "antd";
import { EvaluationFormType } from "src/utils/types";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { ErrorMsg, ScrollableDiv } from "../Global";
import { spacing } from "src/styles/variables";
import {
  FloatingLabelInput,
  FloatingLabelTextArea,
} from "../FloatingInputText/FloatingInputText";
import {
  handleChangeInEvaluation,
  validateEvaluationFields,
} from "../Evaluation/Evaluation.utils";
import { EvaluationSubTitle } from "../Evaluation/Evaluation.styles";
import SettingsSection from "../Evaluation/EvaluationSettings/EvaluationSettings";
import { SpaceBetweenDiv } from "../Profile/Profile.styles";
import EvaluationCategories from "../Evaluation/EvaluationCategories/EvaluationCategories";
import { sampleDefaultEvaluations } from "./ManageOrganization.utils";

export const SampleEvaluationModal = ({
  selectedEvaluation,
  setSelectedEvalution,
  open,
  selectedEvaluations,
  setSelectedEvaluations,
}) => {
  const [evaluation, setEvaluation] = useState<EvaluationFormType>(
    cloneDeep(selectedEvaluation),
  );

  let analysisStructure = evaluation?.analysisStructures;
  const setAnalysisStructures = (newAnalysisStructure) => {
    setEvaluation((prevEvaluation) => ({
      ...prevEvaluation,
      analysisStructures: newAnalysisStructure,
    }));
  };
  const handleCancel = () => {
    setSelectedEvalution(null);
    setEvaluation(cloneDeep(selectedEvaluation));
    setErrors({});
  };

  const handleSaveChanges = () => {
    if (validateEvaluationFields(evaluation, analysisStructure, setErrors)) {
      const updatedEvaluations = [...selectedEvaluations];
      const indexInSelectedEvalution = updatedEvaluations.findIndex(
        (evalItem) => evalItem.id === selectedEvaluation.id,
      );
      updatedEvaluations[indexInSelectedEvalution] = evaluation;
      setSelectedEvaluations(updatedEvaluations);

      const sampleIndex = sampleDefaultEvaluations.findIndex(
        (evalItem) => evalItem.id === selectedEvaluation.id,
      );

      if (sampleIndex !== -1) {
        const { id } = evaluation; // Extract id from evaluation
        sampleDefaultEvaluations[sampleIndex] = { ...evaluation, id: id };
      }

      setSelectedEvalution(null);
    }
  };
  const [errors, setErrors] = useState({});

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      onClose={handleCancel}
      width={"90vw"}
      footer={
        !isEqual(selectedEvaluation, evaluation) && [
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="done" type="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>,
        ]
      }
      style={{ position: "relative", overflowY: "auto" }}
      title={"Adjust Evalution"}
    >
      <ScrollableDiv>
        <Form.Item
          name="evaluation-title"
          style={{ marginTop: spacing.lg, marginBottom: "0px" }}
        >
          <FloatingLabelInput
            id="title"
            label={`Title`}
            style={{
              marginBottom: spacing.md,
            }}
            inputValue={evaluation?.title}
            required
            disabled={false}
            showLabelOnDisable={true}
            error={errors["evaluation-title"]}
            onChangeInput={(e) =>
              handleChangeInEvaluation(
                "title",
                e.target.value,
                setEvaluation,
                "evaluation-title",
                errors,
                setErrors,
              )
            }
          />
        </Form.Item>
        <Form.Item name="evaluation-description">
          <FloatingLabelTextArea
            style={{ marginBottom: spacing.md }}
            id="knowledge"
            label="Knowledge"
            inputValue={evaluation?.description}
            onChangeInput={(e) =>
              handleChangeInEvaluation(
                "description",
                e.target.value,
                setEvaluation,
                "evaluation-description",
                errors,
                setErrors,
              )
            }
            required
            autoSize={{ minRows: 2, maxRows: 6 }}
            disabled={false}
            showLabelOnDisable={true}
            error={errors[`evaluation-description`]}
          />
        </Form.Item>
        <EvaluationSubTitle>Settings</EvaluationSubTitle>
        <SettingsSection
          currentSelectedEvaluation={evaluation}
          setCurrentSelectedEvaluation={setEvaluation}
          handleChange={handleChangeInEvaluation}
          disabled={false}
        />

        <SpaceBetweenDiv>
          <EvaluationSubTitle>Categories</EvaluationSubTitle>
        </SpaceBetweenDiv>

        <EvaluationCategories
          analysisStructure={analysisStructure}
          setAnalysisStructures={setAnalysisStructures}
          setCurrentSelectedEvaluation={setEvaluation}
          disableInputs={false}
          errors={errors}
          setErrors={setErrors}
        />
      </ScrollableDiv>

      {Object.keys(errors)?.length > 0 && (
        <ErrorMsg>* Required fields are missing</ErrorMsg>
      )}
    </Modal>
  );
};
