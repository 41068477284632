import React, { useEffect, useState } from "react";
import { Typography, Input, Select, Spin, Button, Form } from "antd";
import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import MediaPlaylist from "./MediaPlaylist";
import {
  addClipToPlaylist,
  filterPlaylists,
  usePlaylists,
} from "src/components/Playlist/Playlist.utils";
import { UtterancesList } from "../ConversationModalBody/UtterancesList";

const { Option } = Select;

interface AddToPlaylistContentProps {
  modalData: any;
  onClose: () => void;
}

const AddToPlaylistContent: React.FC<AddToPlaylistContentProps> = ({
  modalData,
  onClose,
}) => {
  const { organization, setPlaylists } = useOrganizationState();
  const { user } = useUserState();
  const { playlists, loading: playlistLoading, loadMore } = usePlaylists();
  const formattedTime = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(new Date());
  const inputDefaultValue = `${modalData.file_name}-${formattedTime}`;

  const [inputValue, setInputValue] = useState<string>("");
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | null>(
    null,
  );
  const [searchText, setSearchText] = useState<string>("");

  const [playlistOptions, setPlaylistOptions] = useState([]);

  const [displayedPlaylists, setDisplayedPlaylists] =
    useState<any[]>(playlistOptions);

  const [disableButton, setDisableButton] = useState<boolean>(
    modalData.mimeType.includes("audio") || modalData.mimeType.includes("video")
      ? true
      : false,
  );
  const [startTime, setStartTime] = useState<number>(0);
  const [endTime, setEndTime] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [selectedUtterances, setSelectedUtterances] = useState<number[]>([]);
  const [summaryValue, setSummaryValue] = useState<string>("");

  useEffect(() => {
    if (!searchText) {
      setDisplayedPlaylists(playlistOptions);
    } else {
      const filteredPlaylists = playlistOptions?.filter((playlist) =>
        playlist.title.toLowerCase().includes(searchText.toLowerCase()),
      );
      setDisplayedPlaylists(filteredPlaylists);
    }
  }, [playlistOptions, searchText]);

  useEffect(() => {
    if (playlists?.data && user) {
      setPlaylistOptions(filterPlaylists(playlists?.data, user));
    }
  }, [playlists?.data, user]);

  const handlePopupScroll = (e) => {
    const { target } = e;
    if (
      target.scrollTop + target.clientHeight >= target.scrollHeight - 10 &&
      !playlistLoading
    ) {
      loadMore();
    }
  };

  const handleChange = (value: string) => {
    setSelectedPlaylistId(value);
    setSearchText("");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSummaryChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setSummaryValue(event.target.value);
  };

  const handleSubmit = (values: { title: string; playlistId: string }) => {
    const { title, playlistId } = values;

    const defaultTitle =
      !title || title.trim() === "" ? inputDefaultValue : title.trim();

    const participants = [
      modalData?.agent?.email,
      modalData?.lead?.email,
      modalData?.reviewer?.email,
    ]
      .filter((email) => !!email) // Filter out undefined or null values
      .filter((email, index, self) => self.indexOf(email) === index); // Ensure uniqueness

    const data: any = {
      title: defaultTitle,
      start: startTime,
      end: endTime,
      conversationId: modalData.id,
      user: user.email,
      mimeType: modalData.mimeType,
      updatedAt: new Date().toISOString(),
      participants: participants,
      summary: summaryValue,
    };
    if (modalData?.mimeType.includes("text")) {
      const utterances = modalData?.utterances?.filter((_, index) =>
        selectedUtterances.includes(index),
      );
      data.utterances = utterances;
    }

    setLoading(true);
    addClipToPlaylist(
      organization,
      playlistId,
      data,
      playlists,
      setPlaylists,
    ).then(() => {
      setLoading(false);
      setInputValue("");
      setStartTime(0);
      setEndTime(0);
      setSelectedPlaylistId(null);
      onClose();
    });
  };
  const handleSelectChange = (index: number, checked: boolean) => {
    setSelectedUtterances((prev) => {
      let updatedSelection;
      if (checked) {
        updatedSelection = [...prev, index].sort((a, b) => a - b);
      } else {
        updatedSelection = prev.filter((i) => i !== index);
      }
      return updatedSelection;
    });
  };

  const handleUtteranceClick = (index: number) => {
    setSelectedUtterances((prev) => {
      const isAlreadySelected = prev.includes(index);
      let updatedSelection;
      if (isAlreadySelected) {
        updatedSelection = prev.filter((i) => i !== index);
      } else {
        updatedSelection = [...prev, index].sort((a, b) => a - b);
      }
      return updatedSelection;
    });
  };

  return (
    <Spin spinning={loading}>
      <Typography.Title level={4}>Add to Playlist</Typography.Title>

      {(modalData.mimeType.includes("audio") ||
        modalData.mimeType.includes("video")) && (
        <MediaPlaylist
          data={modalData}
          startTime={startTime}
          endTime={endTime}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          setDisable={setDisableButton}
        />
      )}
      <Form
        layout="vertical"
        initialValues={{
          title: inputDefaultValue,
          playlistId: selectedPlaylistId,
        }}
        onFinish={handleSubmit}
      >
        {modalData.mimeType.includes("text") && (
          <Form.Item
            name="utterances"
            rules={[
              {
                validator: () =>
                  selectedUtterances.length > 0
                    ? Promise.resolve()
                    : Promise.reject("Please select at least one utterance"),
              },
            ]}
          >
            <UtterancesList
              utterances={modalData?.utterances}
              action={false}
              checkAction={true}
              setSelectedItems={setSelectedUtterances}
              handleSelectChange={handleSelectChange}
              selectedItems={selectedUtterances}
              handleUtteranceClick={handleUtteranceClick}
            />
          </Form.Item>
        )}

        <Form.Item name="title" label="Title">
          <Input value={inputValue} onChange={handleInputChange} />
        </Form.Item>

        <Form.Item
          name="playlistId"
          label="Choose Playlist"
          rules={[{ required: true, message: "Playlist is required!" }]}
        >
          <Select
            showSearch
            value={selectedPlaylistId}
            placeholder="Select a playlist"
            onPopupScroll={handlePopupScroll}
            style={{ width: "100%" }}
            filterOption={false}
            onSearch={(value) => setSearchText(value)}
            onChange={handleChange}
            dropdownRender={(menu) => (
              <>
                {menu}
                {playlistLoading && (
                  <div style={{ textAlign: "center", padding: 10 }}>
                    <Spin size="small" />
                  </div>
                )}
              </>
            )}
          >
            {displayedPlaylists?.map((playlist) => (
              <Option key={playlist.id} value={playlist.id}>
                {playlist.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="summary" label="Summary">
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={summaryValue}
            onChange={handleSummaryChange}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!playlists?.data || disableButton}
          >
            Add to Playlist
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default AddToPlaylistContent;
