import { useEffect } from "react";
import {
  onSnapshot,
  collection,
  DocumentData,
  query,
  where,
  orderBy,
  FirestoreDataConverter,
  QueryConstraint,
} from "firebase/firestore";
import { db } from "src/firebaseAuth";
import { useFiltersState } from "src/state/FiltersState";
import { useOrganizationState } from "src/state/OrganizationState";
import { QueryConditions } from "src/utils/types";
import UnsubscribeManager from "src/utils/UnsubscribeManager";

export const useCollection = (
  queryPath: string,
  setFunction: (data: any) => void,
  converterFunction?: FirestoreDataConverter<DocumentData>,
  queryConditions: QueryConditions[] = [],
  setLoading?: (loading: boolean) => void,
  transformFunction?: (data: DocumentData[]) => any,
  returnCountOnly: boolean = false,
): void => {
  const { filterDates } = useFiltersState();
  const { organization } = useOrganizationState();

  useEffect(() => {
    if (!queryPath) return;

    const collectionRef = query(collection(db, queryPath));
    let queryConstraints: QueryConstraint[] = [];

    queryConditions.forEach((queryCondition) => {
      if (queryCondition?.comparisonField) {
        if (queryCondition?.value !== undefined) {
          queryConstraints.push(
            where(
              queryCondition.comparisonField,
              queryCondition.comparisonOperator,
              queryCondition.value,
            ),
          );
        }
        if (queryCondition?.order) {
          queryConstraints.push(
            orderBy(queryCondition.comparisonField, queryCondition.order),
          );
        }
      }
    });

    const finalQuery = converterFunction
      ? query(collectionRef, ...queryConstraints).withConverter(
          converterFunction,
        )
      : query(collectionRef, ...queryConstraints);

    if (setLoading) setLoading(true);

    const unsub = onSnapshot(
      finalQuery,
      (snapshot) => {
        if (snapshot.empty) {
          if (setLoading) setLoading(false);
          setFunction(returnCountOnly ? 0 : []);
          return;
        }
        if (returnCountOnly) {
          setFunction(snapshot.docs.length);
        } else {
          const newData = snapshot.docs.map((doc) => ({
            ...(doc.data() as DocumentData),
            id: doc.id,
          }));

          setFunction(transformFunction ? transformFunction(newData) : newData);
        }
        if (setLoading) setLoading(false);
      },
      (err) => {
        console.error("Error fetching data:", err);
        if (setLoading) setLoading(false);
      },
    );

    UnsubscribeManager.getInstance().addUnsub(unsub); // Register the unsubscribe function

    return () => {
      unsub();
      UnsubscribeManager.getInstance().clearUnsubs();
    };
  }, [queryPath, filterDates, organization]);
};
