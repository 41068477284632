import React from "react";
import { NavigationBar } from "src/components";
import { useOrganizationState } from "src/state/OrganizationState";
import { useUserState } from "src/state/UserState";
import { HomeContainer } from "./Home.styles";
import { StyledHeader } from "../LandingPage/LandingPage.styles";

import { TrucoSandBox } from "../TrucoSandbox/TrucoSandbox";
import TabbedContainer from "./TabbedContainer/TabbedContainer";
import HomeForm from "./HomeForm/HomeForm";
import { useLocation, useParams } from "react-router-dom";
import PlaylistDetails from "../Playlist/PlaylistDetails/PlaylistDetails";

export const StandardPage = ({ content }) => {
  return (
    <HomeContainer>
      <StyledHeader>
        <NavigationBar />
      </StyledHeader>
      {content}
    </HomeContainer>
  );
};

export const Home = () => {
  const { organization } = useOrganizationState();
  const { user, setUser, needsToBookDemo } = useUserState();
  const location = useLocation();
  const { encryptedOrganizationId, encryptedPlaylistId } = useParams();

  let content;
  if (organization) {
    content = <TabbedContainer />;
  } else if (
    location.pathname.startsWith("/playlist") &&
    encryptedOrganizationId &&
    encryptedPlaylistId
  ) {
    content = <PlaylistDetails />;
  } else if (user?.company && !needsToBookDemo) {
    content = <TrucoSandBox />;
  } else {
    content = <HomeForm user={user} setUser={setUser} />;
  }

  return <StandardPage content={content} />;
};
