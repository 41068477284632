import React, { useEffect, useMemo, useRef, useState } from "react";
import { Card, Row, Col, Input, Button, Tag, Tooltip, Switch } from "antd";
import {
  BankOutlined,
  LinkOutlined,
  SearchOutlined,
  PhoneOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { Loading } from "src/components";
import { useOrganizationState } from "src/state/OrganizationState";
import { elementSize } from "src/styles/variables";
import MaterialReactTable from "material-react-table";
import { COLLECTION_DATA } from "src/utils/enums";
import { getRecordsFromFireStore } from "src/firebaseAuth";
import { customerConverter } from "src/utils/converter";
import { Customer } from "src/utils/types";
import { useNavigate } from "react-router-dom";
import { getColorForValue } from "src/utils/utils";
import { getCustomerIdentity } from "./Customers.utils";
import { Container, ItemContainer } from "../Dashboard/Dashboard.styles";
import { TruncateCell } from "../Dashboard/Dashboard.utils";

export const CustomersDashboard = () => {
  const { organization, customers, setCustomers } = useOrganizationState();

  const navigate = useNavigate();
  const [searchCustomer, setSearchCustomer] = useState("");
  const [loading, setLoading] = useState(!customers?.data);
  const stageRefs = useRef({});
  const [viewMode, setViewMode] = useState("table");
  const [stageData, setStageData] = useState({});

  const columns = [
    {
      header: "Customer",
      accessorFn: (row) => getCustomerIdentity(row),
      size: 100, // specify column width
      Cell: ({ cell }) => {
        const customer = cell.getValue();
        return customer ? customer : "N/A";
      },
    },
    {
      header: "Account",
      accessorFn: (row) => ({
        company: row.company,
        companyDomain: row.companyDomain,
      }),
      size: 100, // specify column width
      Cell: ({ cell }) => (
        <Container>
          {cell.getValue().company && (
            <Tooltip title="Created At">
              <ItemContainer>
                <BankOutlined
                  style={{ marginRight: "8px", color: "#52c41a" }}
                />
                <TruncateCell
                  cell={{ getValue: () => cell.getValue().company }}
                />
              </ItemContainer>
            </Tooltip>
          )}

          {cell.getValue().companyDomain && (
            <Tooltip title="Company Domain">
              <ItemContainer>
                <LinkOutlined
                  style={{ marginRight: "8px", color: "#1890ff" }}
                />
                <TruncateCell
                  cell={{ getValue: () => cell.getValue().companyDomain }}
                />
              </ItemContainer>
            </Tooltip>
          )}
        </Container>
      ),
    },
    {
      header: "Stage",
      accessorKey: "currentStage",
      Cell: ({ cell }) => {
        const stage = cell.getValue();
        return stage ? <Tag color={getColorForValue(stage)}>{stage}</Tag> : "";
      },
    },
    {
      header: "# of Conversations",
      accessorKey: "conversations", // accessor is the "key" in the data
      Cell: ({ cell }) => {
        const conversations = cell.getValue();
        return conversations?.length;
      },
      size: 20, // specify column width
    },
    {
      header: "Update At",
      accessorKey: "updatedAt", // accessor is the "key" in the data
      size: 100, // specify column width
      Cell: ({ cell }) => {
        return cell.getValue();
      },
      enableColumnFilter: false,
    },
  ];

  const fetchCustomers = async () => {
    setLoading(true);
    const docs = await getRecordsFromFireStore(
      `/organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
      [
        {
          comparisonField: "updatedAt",
          order: "desc",
        },
      ],
      customerConverter,
      undefined,
      50,
      customers?.lastDoc,
    );
    setCustomers((prevState) => {
      const newData = docs?.data
        ? [...(prevState.data || []), ...docs.data]
        : prevState.data;

      return {
        ...prevState,
        data: newData.length > 0 ? newData : [],
        hasMore: docs.data.length >= 50,
        lastDoc: docs.lastDoc || null,
      };
    });

    setStageData((prevState) => {
      const newStages = docs?.data?.reduce((acc, customer: any) => {
        const stage = customer.currentStage || "Unassigned Stage";
        if (!acc[stage]) {
          acc[stage] = [];
        }
        acc[stage].push(customer);
        return acc;
      }, {});

      return {
        ...prevState,
        ...newStages,
      };
    });

    setLoading(false);
  };

  useEffect(() => {
    !customers?.data && fetchCustomers();
  }, [organization]);

  const loadMoreCustomers = async () => {
    if (!customers.lastDoc) return;
    setLoading(true);
    try {
      fetchCustomers();
      setTimeout(() => {
        const lastBeforeNew = document.getElementById(
          `Customer-${customers.data[customers.data.length - 2]?.id}`,
        );
        if (lastBeforeNew) {
          lastBeforeNew.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    } catch (error) {
      console.error("Error fetching more transcripts: ", error);
    } finally {
      setLoading(false);
    }
  };

  const filteredCustomers = useMemo(() => {
    return customers?.data?.filter((customer) =>
      customer.customer.toLowerCase().includes(searchCustomer.toLowerCase()),
    );
  }, [customers?.data, searchCustomer]);

  const groupedFilteredCustomers = useMemo(() => {
    return Object.entries(stageData).reduce((acc, [stage, customers]: any) => {
      const filteredCustomers = customers.filter((customer) =>
        customer.customer.toLowerCase().includes(searchCustomer.toLowerCase()),
      );
      if (filteredCustomers.length > 0) {
        acc[stage] = filteredCustomers;
      }
      return acc;
    }, {});
  }, [searchCustomer, stageData]);

  const handleCustomerClick = (customer) => {
    navigate(`?customer=${encodeURIComponent(customer.id)}`);
  };

  return (
    <>
      {!customers?.data ? (
        <Loading />
      ) : (
        <Card style={{ padding: `0px ${elementSize.sm}` }}>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 16 }}
          >
            <Col>
              <Input
                placeholder="Find a Customer by Id ..."
                prefix={<SearchOutlined />}
                value={searchCustomer}
                onChange={(e) => setSearchCustomer(e.target.value)}
                style={{ width: 300, marginBottom: 8 }}
              />
            </Col>
            <Col>
              <Switch
                checked={viewMode === "table"}
                onChange={(checked) => setViewMode(checked ? "table" : "board")}
                checkedChildren="Table View"
                unCheckedChildren="Board View"
              />
            </Col>
          </Row>

          <h3>
            {filteredCustomers?.length > 0 &&
              `Last ${filteredCustomers?.length} Customers`}
          </h3>
          {viewMode === "table" ? (
            <>
              <MaterialReactTable
                columns={columns}
                data={filteredCustomers}
                enableStickyHeader={true} // This will make the header sticky
                enableRowSelection={false}
                rowCount={filteredCustomers?.length}
                enablePagination={false}
                renderTopToolbar={false}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    boxShadow: "0",
                    height: "100%",
                  },
                }}
                muiTableBodyProps={{
                  sx: {
                    height: "100%",
                  },
                }}
                muiTableBodyRowProps={({ row }) => ({
                  id: `Customer-${(row.original as Customer).id}`,
                  onClick: (event) => {
                    handleCustomerClick(row.original);
                  },
                  sx: {
                    cursor: "pointer",
                    boxShadow: "0 0 0 0!important",
                  },
                })}
              />
              {customers.hasMore && (
                <div style={{ textAlign: "center", marginTop: 16 }}>
                  <Button onClick={loadMoreCustomers} loading={loading}>
                    Load More
                  </Button>
                </div>
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  overflow: "auto",
                  gap: "25px",
                }}
              >
                {Object.entries(groupedFilteredCustomers).map(
                  ([stage, customers]: any) => (
                    <div key={stage}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          padding: "16px",
                          border: "1px solid #f0f0f0",
                          borderRadius: "15px",
                          height: "500px",
                          minHeight: "100px",
                          overflow: "auto",
                        }}
                        ref={(el) => (stageRefs.current[stage] = el)}
                        // onScroll={() => handleScroll(stage)}
                      >
                        <div>
                          <Tag color={getColorForValue(stage)}>{stage}</Tag>
                          <Tag color={getColorForValue(stage)}>
                            {customers.length}
                          </Tag>
                        </div>

                        {customers.map((customer) => (
                          <Card
                            key={customer.id}
                            hoverable
                            onClick={() => handleCustomerClick(customer)}
                            style={{ marginBottom: 16, width: 300 }}
                            title={getCustomerIdentity(customer)}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Tooltip title="# of Conversations">
                                <PhoneOutlined
                                  style={{
                                    marginRight: "8px",
                                    color: "#52c41a",
                                  }}
                                />
                                {customer.conversations?.length || 0}
                              </Tooltip>
                              <Tooltip title="Updated At">
                                <CalendarOutlined
                                  style={{
                                    marginRight: "8px",
                                    color: "#52c41a",
                                  }}
                                />
                                {customer.updatedAt}
                              </Tooltip>
                              {customer.company && (
                                <Tooltip title="Company">
                                  <BankOutlined
                                    style={{
                                      marginRight: "8px",
                                      color: "#52c41a",
                                    }}
                                  />
                                  {customer.company}
                                </Tooltip>
                              )}
                              {customer.companyDomain && (
                                <Tooltip title="company Domain">
                                  <LinkOutlined
                                    style={{
                                      marginRight: "8px",
                                      color: "#1890ff",
                                    }}
                                  />
                                  {customer.companyDomain}
                                </Tooltip>
                              )}
                            </div>
                          </Card>
                        ))}
                      </div>
                    </div>
                  ),
                )}
              </div>
            </>
          )}
        </Card>
      )}
    </>
  );
};
