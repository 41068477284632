import { getRecordFromFireStoreWithQuery } from "src/firebaseAuth";
import { customerConverter } from "src/utils/converter";
import { COLLECTION_DATA } from "src/utils/enums";

export const getCustomerInfo = async (customerValue, organization) => {
  const customerData = await getRecordFromFireStoreWithQuery(
    `organization/${organization}/${COLLECTION_DATA.CUSTOMERS}`,
    [
      {
        fieldPath: "customer",
        opStr: "==",
        value: customerValue,
      },
      {
        fieldPath: "phone",
        opStr: "==",
        value: customerValue,
      },
      {
        fieldPath: "email",
        opStr: "==",
        value: customerValue,
      },
    ],
    customerConverter,
    null,
  );

  return customerData;
};

export const getCustomerIdentity = (customer) => {
  return customer?.firstName
    ? customer?.firstName + " " + customer?.lastName || ""
    : customer?.email || customer?.phone || customer?.customer || "N/A";
};
