import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import { color } from "src/styles/variables";

export const DeleteWithPopConfirm = (
  title: string,
  description: string,
  handleConfirmDelete,
  disabled,
) => {
  return (
    <Popconfirm
      title={title}
      description={description}
      onConfirm={handleConfirmDelete}
      onCancel={(e) => {
        e.stopPropagation();
      }}
      okText="Yes"
      cancelText="No"
      disabled={disabled}
    >
      <DeleteOutlined
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
        style={{ color: color.orange, opacity: disabled && "0.5" }}
      />
    </Popconfirm>
  );
};
