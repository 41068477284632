import { Carousel } from "antd";
import {
  color,
  deviceSize,
  elementSize,
  hexToRgba,
} from "src/styles/variables";
import styled from "styled-components";

export const PlaylistTitle = styled.h2`
  margin: 0;
  font-size: 1.9em;
  text-align: center;
  ::first-letter {
    text-transform: capitalize;
  }
`;

export const PlayListTitleHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${elementSize.lg};
  @media (max-width: ${deviceSize.large_phone}) {
    flex-direction: column;
  }
`;

export const PlayListDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${elementSize.xs};
  @media (max-width: ${deviceSize.large_phone}) {
    align-items: center;
  }
`;
export const StyledCarousel = styled(Carousel)`
  @media (max-width: ${deviceSize.regular_phone}) {
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }

  .slick-prev,
  .slick-next {
    color: ${color.orange};
  }

  .slick-prev:hover,
  .slick-next:hover {
    color: darkred; /* Change arrow color on hover */
  }
  .slick-dots-bottom {
    bottom: 3px;
  }
  .slick-dots li button {
    background-color: ${hexToRgba(color.orange, 0.5)};
  }

  .slick-dots li.slick-active button {
    background-color: ${color.orange};
  }
`;

export const ClipList = styled.div`
  margin-top: ${elementSize.md};
`;

export const ClipItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${elementSize.sm};
  margin-bottom: ${elementSize.xs};
  border: 1px solid ${color.grayLight};
  border-radius: ${elementSize.xs};
`;

export const ClipPreview = styled.div`
  width: 150px;
  // height: 84px; /* Aspect ratio 16:9 for rectangular look */
  overflow: hidden;
  flex-shrink: 0;
  video,
  audio {
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${deviceSize.large_phone}) {
    width: 30%;
    height: 20%;
  }
`;

export const ClipActions = styled.div`
  display: flex;
  align-items: center;
`;

export const DragHandle = styled.span`
  margin-right: ${elementSize.md};
  cursor: grab;
`;
