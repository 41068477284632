import React, { useState } from "react";
import { Typography, Button, Form, Input, Modal, Steps } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { spacing } from "src/styles/variables";
import { updateDocInFireStore } from "src/firebaseAuth";

export function buildGoogleCalendarOAuthUrl(userEmail, organization) {
  const params = {
    client_id: process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID,
    redirect_uri:
      process.env.REACT_APP_TRUCO_BACKEND + "/google_calendar/oauth_redirect",
    response_type: "code",
    scope: [
      "https://www.googleapis.com/auth/calendar.events.readonly",
      "https://www.googleapis.com/auth/userinfo.email",
    ].join(" "),
    access_type: "offline",
    prompt: "consent",
    state: JSON.stringify({ organization, userEmail }),
  };

  const url = new URL("https://accounts.google.com/o/oauth2/v2/auth");
  url.search = new URLSearchParams(params).toString();

  return url.toString();
}

export function buildZoomOAuthUrl(userEmail, organization) {
  const params = {
    response_type: "code",
    redirect_uri: process.env.REACT_APP_TRUCO_BACKEND + "/zoom/oauth_redirect",
    client_id: process.env.REACT_APP_ZOOM_CLIENT_ID,
    state: JSON.stringify({ organization, userEmail }),
  };

  const url = new URL("https://zoom.us/oauth/authorize");
  url.search = new URLSearchParams(params).toString();

  return url.toString();
}

export function buildSlackOAuthUrl(organization) {
  return `https://slack.com/oauth/v2/authorize?state=${encodeURIComponent(
    organization,
  )}&scope=users%3Aread.email%2Cusers%3Aread%2Cim%3Awrite%2Cchat%3Awrite%2Cincoming-webhook&user_scope=&redirect_uri=${encodeURIComponent(
    process.env.REACT_APP_TRUCO_BACKEND + "/slack/oauth_redirect",
  )}&client_id=${encodeURIComponent(process.env.REACT_APP_SLACK_CLIENT_ID)}`;
}

export function buildHubspotUrl(userEmail, organization) {
  const state = { organization, userEmail };
  const baseUrl = "https://app.hubspot.com/oauth/authorize";

  // Define query parameters
  const queryParams = {
    client_id: process.env.REACT_APP_HUBSPOT_CLIENT_ID,
    scope:
      "oauth crm.objects.contacts.read crm.objects.contacts.write crm.schemas.contacts.read crm.schemas.contacts.write sales-email-read crm.objects.owners.read",
    redirect_uri: `${process.env.REACT_APP_TRUCO_BACKEND}/hubspot/oauth_redirect`,
    state: JSON.stringify(state),
  };

  const authUrl = new URL(baseUrl);
  authUrl.search = new URLSearchParams(queryParams).toString();
  return authUrl.toString();
}

export function buildOutlookCalendarOAuthUrl(userEmail, organization) {
  const state = { organization, userEmail };
  const params = {
    client_id: process.env.REACT_APP_OUTLOOK_CLIENT_ID,
    redirect_uri:
      process.env.REACT_APP_TRUCO_BACKEND + "/outlook_calendar/oauth_redirect",
    response_type: "code",
    scope: "offline_access openid email Calendars.Read",
    state: JSON.stringify(state),
  };

  const url = new URL(
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
  );
  url.search = new URLSearchParams(params).toString();
  return url.toString();
}
function generateCodeVerifier() {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ("0" + dec.toString(16)).substr(-2)).join(
    "",
  );
}

// Encode the code verifier in base64
function base64URLEncode(str) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

// SHA-256 hash function
async function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

// Generate a code challenge
async function generateCodeChallenge(codeVerifier) {
  const hashed = await sha256(codeVerifier);
  return base64URLEncode(hashed);
}

export async function buildSalesforceUrl(userEmail, organization) {
  const state = { organization, userEmail };
  const baseUrl = "https://login.salesforce.com/services/oauth2/authorize";
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  // save code verifier in request cookies
  // document.cookie = `code_verifier=${codeVerifier}; path=/`;

  // Define query parameters
  const queryParams = {
    client_id: process.env.REACT_APP_SALESFORCE_CLIENT_ID,
    response_type: "code",
    redirect_uri: `${process.env.REACT_APP_TRUCO_BACKEND}/salesforce/oauth_redirect`,
    state: JSON.stringify(state),
    // code_challenge: codeChallenge,
    // code_challenge_method: "S256",
  };

  const authUrl = new URL(baseUrl);
  console.log(authUrl);
  authUrl.search = new URLSearchParams(queryParams).toString();
  return authUrl.toString();
}

export const ConnectOpenPhone = ({ queryPath, visible, onClose }) => {
  const [signingSecret, setSigningSecret] = useState("");
  const [current, setCurrent] = useState(0);

  const onChange = (value: number) => {
    setCurrent(value);
  };

  const register = () => {
    updateDocInFireStore(queryPath, {
      openphone: {
        signing_key: signingSecret,
      },
    });
    onClose();
  };
  return (
    <Modal
      title={"Connect OpenPhone"}
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <Steps
        current={current}
        onChange={onChange}
        direction="vertical"
        style={{ margin: `${spacing.lg} auto` }}
        items={[
          {
            title: "Step 1",
            description: (
              <Typography.Text>
                Navigate to{" "}
                <Typography.Link
                  href={"https://my.openphone.com/settings/webhooks"}
                >
                  OpenPhone Settings
                </Typography.Link>
              </Typography.Text>
            ),
          },
          {
            title: "Step 2",
            description: (
              <>
                <Typography.Text>
                  Under Webhooks, create a new webhook with this URL!
                </Typography.Text>
                <br />
                <Input
                  value={`${process.env.REACT_APP_TRUCO_BACKEND}/openphone/meeting_event`}
                  readOnly
                  addonAfter={
                    <CopyOutlined
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `${process.env.REACT_APP_TRUCO_BACKEND}/openphone/meeting_event`,
                        )
                      }
                    />
                  }
                />
              </>
            ),
          },
          {
            title: "Step 3",
            description: (
              <Typography.Text>
                Select the following event types:
                <ul>
                  {[
                    "call.recording.completed",
                    "message.received",
                    "message.delivered",
                    "contact.updated",
                  ].map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </Typography.Text>
            ),
          },
          {
            title: "Step 4",
            description: (
              <Form onFinish={register}>
                <Form.Item
                  name="signingSecret"
                  rules={[
                    {
                      required: true,
                      message: "Please input your signing secret!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Signing Secret"
                    value={signingSecret}
                    onChange={(e) => setSigningSecret(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    disabled={!signingSecret}
                  >
                    Connect
                  </Button>
                </Form.Item>
              </Form>
            ),
          },
        ]}
      />
    </Modal>
  );
};
