import { color } from "src/styles/variables";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import axios from "axios";
import { useOrganizationState } from "src/state/OrganizationState";
import { ContainerCard, StyledButton } from "./Subscription.styles";
import { useMemo } from "react";

export const SubscriptionDetailsCard = ({ seats }) => {
  const { organization, organizationSettings } = useOrganizationState();

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_TRUCO_BACKEND}/stripe/create-payment-link`,
        {
          organization: organization,
          seats: seats,
        },
      );
      const paymentLink = response.data.url;
      window.location.href = paymentLink;
    } catch (error) {
      console.error("Error creating payment link:", error);
    }
  };

  const handleCancel = async () => {
    window.location.href = `mailto:support@truco.ai?subject=Cancel Truco Subscription&body=Cancel Truco Subscription for ${organization}`;
    // try{
    // const response = await axios.post(
    //     `${process.env.REACT_APP_TRUCO_BACKEND}/stripe/cancel-subscription`,
    //     {
    //       organization: organization,
    //     },
    //   );
    //   // TODO:update and show the new status in BETTER way
    //   window.location.reload();
    // } catch (error) {
    //   console.error("Error cancelling subscription:", error);
    // }
  };

  // calculate price based on number of seats
  const price = useMemo(() => {
    return 50 * seats;
  }, [seats]);

  return (
    <ContainerCard title={"Current Plan"}>
      <Typography.Title level={4}></Typography.Title>
      {organizationSettings?.stripe?.subscription_status === "active" ? (
        <div>
          <Typography.Title level={5}>Truco Pro</Typography.Title>
          <Typography.Text>{price}$/month</Typography.Text>
          <StyledButton onClick={handleCancel} icon={<CloseCircleOutlined />}>
            Cancel Subscription
          </StyledButton>
        </div>
      ) : (
        <div>
          <Typography.Title level={5}>Free</Typography.Title>
          <StyledButton
            onClick={handleSubmit}
            style={{ color: color.white, backgroundColor: color.orange }}
          >
            Subscribe
          </StyledButton>
        </div>
      )}
    </ContainerCard>
  );
};
